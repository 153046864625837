import styled from 'styled-components'

export default styled.section`
  width: 100%;
  height: auto;
  background-color: ${ ({ theme }) => theme.colors.white };
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  > header {
    padding: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    h4 {
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    button {
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    transition: ease-in box-shadow 0.1s;

    .details {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0 30px 30px;
      box-sizing: border-box;

      header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 8px;

        .date {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 15px;
          color: ${ ({ theme }) => theme.colors.grey };
        }

        button.favorite {
          border: none;
          background: transparent;
          cursor: pointer;
        }
      }
    }
  }

  footer {
    width: 100%;
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
  }
`
