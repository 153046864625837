import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Page, Title, ValidatedInput, Button, Spinner } from '../../components'
import { urls } from '../../routing'
import { LiteralsService } from '../../services'
import {
  clearSignInErrorAction,
  clearSignInFieldsAction,
  clearSignUpFieldsAction,
  resetRecoverPasswordFieldsAction,
  setSignInPasswordAction,
  setSignInUsernameAction,
  signInAction
} from '../../store/actions'
import { IStoreState } from '../../store/states'
import { isDniValid, isEmailValid, isPasswordValid } from '../../helpers'
import { images } from '../../images'
import StyledArticle from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  username: string
  password: string
  isSigningIn: boolean
  error: boolean
  isNewPasswordRequired: boolean
}

class SignIn extends PureComponent<IProps> {
  private get isUsernameValid(): boolean {
    return isEmailValid(this.props.username) || isDniValid(this.props.username)
  }

  private get signInAlert(): ReactNode {
    const error = this.props.error
    if (error) {
      return (
        <div className="error-container">
          <span className="error">
            { LiteralsService.get('userOrPasswordWrong', true) }
          </span>
        </div>
      )
    }
    return null
  }

  private get buttonIsDisabled(): boolean {
    const { password, isSigningIn } = this.props

    return !this.isUsernameValid || !isPasswordValid(password) || isSigningIn
  }

  private get signInButton(): ReactNode {
    const { isSigningIn } = this.props
    if (isSigningIn) return <Spinner />
    return (
      <Button
        title={ LiteralsService.get('continue') }
        onPress={ this.signIn }
        filled
        upper
        isDisabled={ this.buttonIsDisabled }
      />
    )
  }

  public componentDidUpdate(): void {
    const { history } = this.props
    if (this.props.isNewPasswordRequired) history.push(urls.newPasswordRequired)
  }

  public render(): ReactNode {
    const { username, password, error } = this.props

    return (
      <Page>
        <StyledArticle>
          <header>
            <img src={ images['logo'] } alt="AMB:Bus Metropolità"/>
          </header>
          <form onSubmit={ e => e.preventDefault() }>
            <Title text={ LiteralsService.get('signIn', true) }/>
            <section>
              <ValidatedInput
                name="username"
                label={ LiteralsService.get('emailOrUsername', true) }
                value={ username }
                onChange={ this.onUsernameChange }
                error={ !this.isUsernameValid || error }
              />
              <ValidatedInput
                name="password"
                label={ LiteralsService.get('password', true) }
                value={ password }
                onChange={ this.onPasswordChange }
                isPassword
                error={ !isPasswordValid(password) || error }
              />
              <div className="link">
                <span onClick={ this.goToRecoverPassword }>
                  { LiteralsService.get('forgottenPassword', true) }
                </span>
              </div>
              { this.signInAlert }
            </section>
            <section className="actions">
              { this.signInButton }
              <div className="link">
                <span onClick={ this.goToSignUp }>
                  { LiteralsService.get('notUserCreateOne', true) }
                </span>
              </div>
            </section>
          </form>
        </StyledArticle>
      </Page>
    )
  }

  private onUsernameChange = (username: string) => {
    const { error, dispatch } = this.props

    if (error) dispatch(clearSignInErrorAction())
    dispatch(setSignInUsernameAction(username))
  }

  private onPasswordChange = (password: string) => {
    const { error, dispatch } = this.props

    if (error) dispatch(clearSignInErrorAction())
    dispatch(setSignInPasswordAction(password))
  }

  private goToRecoverPassword = () => {
    const { dispatch, history } = this.props

    dispatch(resetRecoverPasswordFieldsAction())
    history.push(urls.recoverPasswordRequestCodeStep)
  }

  private signIn = () => {
    this.props.dispatch(signInAction())
  }

  private goToSignUp = () => {
    const { dispatch, history  } = this.props

    dispatch(clearSignUpFieldsAction())
    dispatch(clearSignInFieldsAction())
    history.push(urls.signUpStep1)
  }
}

const mapStateToProps = ({ signIn }: IStoreState) => ({
  username: signIn.username,
  password: signIn.password,
  isSigningIn: signIn.isSigningIn,
  error: signIn.error,
  isNewPasswordRequired: signIn.isNewPasswordRequired
})

export default withRouter(connect(mapStateToProps)(SignIn))
