import React, { ReactNode, MouseEvent } from 'react'
import { StopsCard } from '../../../../components'
import { MapContainer } from '../../../../containers'
import { dateAsCalendar } from '../../../../helpers'
import { images } from '../../../../images'
import { IReservation } from '../../../../models'
import { LiteralsService } from '../../../../services'
import StyledSection from './styles'

interface IProps {
  reservation: IReservation
  onClick: () => void
  onFavoriteClick: () => void
}

export const Card: React.FC<IProps> = ({ reservation, onClick, onFavoriteClick }) => {
  const cancelledLabel = reservation.cancelled
    ? (
      <div className="cancelled-label">
        <span>
          { LiteralsService.get('cancelled', true) }
        </span>
      </div>
      )
    : null

    const favoriteButton = (): ReactNode => {
      if (!reservation.favorite) {
        return (
          <button className="favorite" onClick={ handleFavoriteClick }>
            <img
              src={ images['star']}
              alt="Star"
              style={{ zIndex: 10, width: '20px', height: '20px' }}
            />
          </button>
        )
      } else {
        return (
          <img
            src={ images['starSelected']}
            alt="Star Selected"
            style={{ zIndex: 10, width: '20px', height: '20px' }}
          />
        )
      }
    }

    const handleFavoriteClick = (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      onFavoriteClick()
    }

  return (
    <StyledSection onClick={ onClick }>
      <div className="map">
        <MapContainer
          path={ reservation.path }
          inStop={ reservation.beginningStop }
          outStop={ reservation.seats[0].exitStop }
          services={ [] }
          zoomLevel={ 13 }
          correction={ 0.0005 }
        />
      </div>
      { cancelledLabel }
      <div className="details">
        <header>
          <span className="date">
            { dateAsCalendar(reservation.beginningStop.dateTime) }
          </span>
          { favoriteButton() }
        </header>
        <div>
          <StopsCard
            inStopName={ reservation.beginningStop.name }
            outStopName={ reservation.seats[0].exitStop.name }
          />
        </div>
      </div>
    </StyledSection>
  )
}
