import React from 'react'
import StyledDiv from './styles'

export const Spinner: React.FC = () => {
  return (
    <StyledDiv>
      <div className="double-bounce1"></div>
      <div className="double-bounce2"></div>
    </StyledDiv>
  )
}
