import styled from 'styled-components'

export default styled.section`
  border: 1px solid ${ ({ theme }) => theme.colors.greyLight };
  padding: 20px 38px;
  border-radius: 4px;

  .dashed {
    border-left: 2px dotted ${ ({ theme }) => theme.colors.greyDark };
    flex: 1;
    margin: -4px 0 -4px 9px;
  }

  .row-details {
    display: flex;
    flex-direction: row;
  }

  .column-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .stop-info {
    display: flex;
    margin-left: 5px;
    min-height: 65px;
    flex-direction: column;
    justify-content: center;
  }

  p {
    margin: unset;
  }

  .text-top {
    font-family: ${ ({ theme }) => theme.fontFamily };
    font-size: 14px; 
    color: ${ ({ theme }) => theme.colors.black };
  }

  .text-bottom {
    flex-wrap: wrap;
    font-family: ${ ({ theme }) => theme.fontFamily };
    font-size: 18px;
    color: ${ ({ theme }) => theme.colors.black };
  }

  .not-available {
    color: ${ ({ theme }) => theme.colors.grey };
  }

  .hour {
    min-height: 65px;

    > button {
      background-color: transparent;
      border: none;
    }

    .hour-text {
      font-family: ${ ({ theme }) => theme.fontFamily };
      font-size: 14px; 
      color: ${ ({ theme }) => theme.colors.black };
    }
  }
`
