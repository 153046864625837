import React, { useState } from 'react'
import { MenuItem } from '../../../../components'
import { IService, IStop } from '../../../../models'
import { StopLine } from '../StopLine'
import StyledSection from './styles'

interface IProps {
  service: IService
  onClickStop: (idService: number, stop: IStop) => void
  destinationBusStops: IStop[]
  pickedService: number | null
  pickedOriginBusStop?: IStop
  pickedDestinationBusStop?: IStop
}

export const ServiceLine: React.FC<IProps> =
  ({ service, onClickStop, destinationBusStops, pickedService, pickedOriginBusStop, pickedDestinationBusStop }) => {
    const [displayStops, setDisplayStops] = useState(false)
    const stops = displayStops
      ? service.stops
          .sort((a, b) => a.code - b.code)
          .map(stop => (
            <StopLine
              key={ `${service.id}-${stop.id}` }
              service={ service }
              stop={ stop }
              onPress={ (stopPressed: IStop) => onClickStop(service.id, stopPressed) }
              destinationBusStops={ destinationBusStops }
              pickedService={ pickedService }
              pickedOriginBusStop={ pickedOriginBusStop }
              pickedDestinationBusStop={ pickedDestinationBusStop }
            />
          ))
      : null

    return (
      <StyledSection>
        <MenuItem
          label={ service.name }
          onClick={ () => setDisplayStops(current => !current) }
          conatinerStyles={ { borderBottomWidth: 0 } }
          icon={ displayStops ? 'down' : 'arrowRight' }
        />
        { stops }
      </StyledSection>
    )
}
