import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { IService, IStop } from '../../../models'
import { setBusStopAction } from '../../../store/actions'
import { IStoreState } from '../../../store/states'
import { ServiceLine } from './ServiceLine'
import StyledArticle from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  services: IService[]
  pickedService: number | null
  pickedOriginBusStop?: IStop
  destinationBusStops: IStop[]
  pickedDestinationBusStop?: IStop
}

class ListStopsView extends PureComponent<IProps> {
  private get servicesSorted(): ReactNode {
    const { destinationBusStops, pickedService, pickedOriginBusStop, pickedDestinationBusStop, services } = this.props
    return services
      .sort((a, b) => ('' + a.name).localeCompare(b.name))
      .map(service => (
        <ServiceLine
          key={ `${service.id}` }
          onClickStop={ this.onClickStop }
          service={ service }
          destinationBusStops={ destinationBusStops }
          pickedService={ pickedService }
          pickedOriginBusStop={ pickedOriginBusStop }
          pickedDestinationBusStop={ pickedDestinationBusStop }
        />
      ))
  }
  public render(): ReactNode {
    return (
      <StyledArticle>
        { this.servicesSorted }
      </StyledArticle>
    )
  }

  private onClickStop = (idService: number, stop: IStop) => {
    const { dispatch } = this.props

    dispatch(setBusStopAction({ idService, stop }))
  }
}

const mapStateToProps = ({ newReservation }: IStoreState) => ({
  services: newReservation.services,
  pickedOriginBusStop: newReservation.pickedOriginBusStop,
  destinationBusStops: newReservation.destinationBusStops,
  pickedDestinationBusStop: newReservation.pickedDestinationBusStop,
  pickedService: newReservation.pickedService
})

export default withRouter(connect(mapStateToProps)(ListStopsView))
