import styled from 'styled-components'

export default styled.article`
  position: relative;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 0;

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;

    > button {
      width: 120px;
      margin-left: 10px;
      height: 34px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 17px;
      background-color: transparent;

      &:not(:last-of-type) {
        border-color: #4A4A4A;
        color: #4A4A4A;
      }

      &:last-of-type {
        border-color: ${ ({ theme }) => theme.colors.red };
        color: ${ ({ theme }) => theme.colors.red };
      }
    }
  }

  > .details-container {
    width: 100%;
    flex: 1;
    background-color: ${ ({ theme }) => theme.colors.white };
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
    overflow: hidden;

    > section {
      height: 100%;
    }

    .map-container {
      height: 336px;
      overflow: hidden;

      > .map {
        width: 100%;
        height: 100%;
      }
    }

    .details {
      flex: 1;
    }
  }
`
