import React, { PureComponent, ReactNode, CSSProperties } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { MenuItem } from '../../../components'
import { IBase, ISearchedType, IStop } from '../../../models'
import { LiteralsService } from '../../../services'
import { searchStopCoordinatesAction, searchStopsAction, setBusStopAction } from '../../../store/actions'
import { IStoreState } from '../../../store/states'
import StyledUl from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  stopsSearched: IBase[]
  pickedService: number | null
}

const itemStyle: CSSProperties = {
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center'
}

class SearchStopsView extends PureComponent<IProps> {
  public render(): ReactNode {
    const { stopsSearched } = this.props
    const stops = stopsSearched.map((s: any) => {
      if (s.location) {
        return (
          <MenuItem
            key={ `${s.serviceId}-${s.id}` }
            label={ s.name }
            code={ s.code }
            onClick={ () => this.onClickStopServiceSearched(s) }
            conatinerStyles={ itemStyle }
            frontIcon="stop"
          />
        )
      } else {
        return (
          <MenuItem
            key={ s.id }
            label={ s.name }
            onClick={ () => this.onClickStopSearched(s) }
            conatinerStyles={ itemStyle }
            frontIcon="pin"
          />
        )
      }
    })

    if (!stopsSearched.length) {
      return (
        <MenuItem
          label={ LiteralsService.get('myUbication', true) }
          onClick={ this.onClickMyLocation }
          frontIcon={ 'myLocation' }
          conatinerStyles={ { justifyContent: 'flex-start', width: '100%' } }
        />
      )
    } else {
      return (
        <StyledUl>
          { stops }
        </StyledUl>
      )
    }
  }

  private onClickMyLocation = () => {
    const { dispatch } = this.props

    dispatch(
      searchStopCoordinatesAction({ type: ISearchedType.location } as any)
    )
  }

  private onClickStopServiceSearched = (stop: IStop) => {
    const { dispatch, pickedService } = this.props

    dispatch(searchStopsAction(stop.name))
    dispatch(searchStopCoordinatesAction({ ...stop, type: ISearchedType.stop }))
    if (pickedService) dispatch(setBusStopAction({stop, idService: pickedService }))
  }

  private onClickStopSearched = (suggestion: IBase) => {
    const { dispatch } = this.props

    dispatch(searchStopsAction(suggestion.name))
    dispatch(
      searchStopCoordinatesAction({ ...suggestion, type: ISearchedType.google })
    )
  }
}

const mapStateToProps = ({ newReservation }: IStoreState) => ({
  stopsSearched: newReservation.stopsSearched,
  pickedService: newReservation.pickedService
})

export default withRouter(connect(mapStateToProps)(SearchStopsView))
