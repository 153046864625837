// import { Language } from '../../services/literals-service'
import {
  IChangeConfirmationFlagAction,
  IChangeFeedbackFlagAction,
  IChangeUserLanguageAction,
  ICheckSessionAction,
  ISaveUserInfoAction,
  ISaveUserLanguageAction,
  ISetIsLoadingUserInfoAction,
  ISetIsUserExperienced,
  ISetIsUserPenalizedAction,
  ISetUserInfoAction,
  ISetUserInfoDNIAction,
  ISetUserInfoEmailAction,
  ISetUserInfoLastNameAction,
  ISetUserInfoNameAction,
  UserInfoTypeKeys
} from '../types'

export function checkSessionAction(): ICheckSessionAction {
  return { type: UserInfoTypeKeys.CHECK_USER }
}

export function setUserInfoAction(userInfo: any): ISetUserInfoAction {
  return { type: UserInfoTypeKeys.SET_USER_INFO, payload: userInfo }
}

export function setUserInfoDNIAction(dni: string): ISetUserInfoDNIAction {
  return { type: UserInfoTypeKeys.SET_USER_INFO_DNI, payload: dni }
}

export function setUserInfoEmailAction(email: string): ISetUserInfoEmailAction {
  return { type: UserInfoTypeKeys.SET_USER_INFO_EMAIL, payload: email }
}

export function setUserInfoNameAction(name: string): ISetUserInfoNameAction {
  return { type: UserInfoTypeKeys.SET_USER_INFO_NAME, payload: name }
}

export function setUserInfoLastNameAction(lastName: string): ISetUserInfoLastNameAction {
  return { type: UserInfoTypeKeys.SET_USER_INFO_LAST_NAME, payload: lastName }
}

export function saveUserInfoAction(): ISaveUserInfoAction {
  return { type: UserInfoTypeKeys.SAVE_USER_INFO }
}
export function saveUserLanguageAction(locale: string): ISaveUserLanguageAction {
  return { type: UserInfoTypeKeys.SAVE_USER_LANGUAGE, payload: locale }
}

export function changeUserLanguageAction(lenguage: string): IChangeUserLanguageAction {
  return { type: UserInfoTypeKeys.CHANGE_USER_LENGUAGE, payload: lenguage }
}

export function changeConfirmationFlagAction(): IChangeConfirmationFlagAction {
  return { type: UserInfoTypeKeys.CHANGE_CONFIRMATION_FLAG }
}

export function changeFeedbackFlagAction(): IChangeFeedbackFlagAction {
  return { type: UserInfoTypeKeys.CHANGE_FEEDBACK_FLAG }
}

export function setIsUserPenalizedAction(): ISetIsUserPenalizedAction {
  return { type: UserInfoTypeKeys.SET_IS_USER_PENALIZED }
}

export function setIsLoadingUserInfoAction(loading: boolean): ISetIsLoadingUserInfoAction {
  return { type: UserInfoTypeKeys.IS_LOADING_USER_INFO, payload: loading }
}

export function setIsUserExperienced(payload: boolean): ISetIsUserExperienced {
  return { type: UserInfoTypeKeys.SET_IS_USER_EXPERIENCED, payload }
}
