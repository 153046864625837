import styled from 'styled-components'

export default styled.div`
  display: flex;
  position: relative;
  flex: 0 0 auto;
  width: 100px;
  height: 40px;
  border-radius: 20px;
  background: ${ ({ theme }) => theme.colors.white };
  font-family: ${ ({ theme }) => theme.fontFamily };
  color: ${ ({ theme }) => theme.colors.black };
  align-items: center;
  padding: 0px 8px 0px 12px;
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid ${ ({ theme }) => theme.colors.primary };
  &.filled {
    background: ${ ({ theme }) => theme.colors.white };
  }
  &.active {
    border-color: ${ ({ theme }) => theme.colors.primary };
    background: ${ ({ theme }) => theme.colors.white };
  }
  &.invalid {
    border-color: ${ ({ theme }) => theme.colors.red };
    color: ${ ({ theme }) => theme.colors.red };
    background: ${ ({ theme }) => theme.colors.white };
  }
  &:hover:not(.disabled):not(.active):not(.invalid) {
    background: ${ ({ theme }) => theme.colors.almostWhite };
    color: ${ ({ theme }) => theme.colors.black };
  }
  .value {
    display: flex;
    flex: 1 1 0%;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0 5px 0 0;
    color: ${ ({ theme }) => theme.colors.black };
    background: transparent;
    border: none;
    box-shadow: none;
    pointer-events: none;
    &::placeholder {
      color: ${ ({ theme }) => theme.colors.black };
      font-family: ${ ({ theme }) => theme.fontFamily };
    }
  }
`
