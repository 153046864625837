import { INotification } from '../../models'

export enum NotificationsTypeKeys {
  SET_NOTIFICATION = 'SET_NOTIFICATION',
  CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'
}

export interface ISetNotificationAction {
  type: typeof NotificationsTypeKeys.SET_NOTIFICATION
  payload: INotification
}

export interface IClearNotificationAction {
  type: typeof NotificationsTypeKeys.CLEAR_NOTIFICATION
}

export type NotificationsActionTypes =
 | ISetNotificationAction
 | IClearNotificationAction
