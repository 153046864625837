import axios, { AxiosError, AxiosInstance, AxiosResponse, Method } from 'axios'
import { AuthenticationService } from '..'
import { ICognitoUserSession } from '../../models'

const apiUrl = `${process.env.REACT_APP_API_URL}/`

interface IHeaders {
  [key: string]: string
}

export abstract class BaseHttpService {
  public axiosInstance: AxiosInstance
  private boundResponse = this.handleResponse.bind(this)
  private boundResponseError = this.handleResponseError.bind(this)

  constructor() {
    this.axiosInstance = axios.create({ baseURL: apiUrl })
    this.axiosInstance.interceptors.response.use(this.boundResponse, this.boundResponseError)
  }

  public async get(segment: string): Promise<any> {
    return this.buildRequest(segment, 'GET')
  }

  public async post(segment: string, body?: any): Promise<any> {
    return this.buildRequest(segment, 'POST', body)
  }

  public async patch(segment: string, body: any): Promise<any> {
    return this.buildRequest(segment, 'PATCH', body)
  }

  public async put(segment: string, body?: any): Promise<any> {
    return this.buildRequest(segment, 'PUT', body)
  }

  public async delete(segment: string, body?: any): Promise<any> {
    return this.buildRequest(segment, 'DELETE', body)
  }

  private async buildRequest(segment: string, method: Method = 'GET', body?: any): Promise<any> {
    const headers: IHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-BOD-APP-CODE': 'AMB'
    }

    const token = await this.getToken()
    if (token) headers.Authorization = `Bearer ${token}`

    switch (method) {
      case 'PUT':
        return this.axiosInstance.put(segment, body, { headers })
      case 'POST':
        return this.axiosInstance.post(segment, body, { headers })
      case 'DELETE':
        return this.axiosInstance.delete(segment, { headers })
      case 'PATCH':
        return this.axiosInstance.patch(segment, body, { headers })
      default:
        return this.axiosInstance.get(segment, { headers })
    }
  }

  private handleResponse(response: any): AxiosResponse<any> {
    return response.data
  }

  private handleResponseError(error: AxiosError): AxiosError | any {
    if (error.response?.status === 401) throw new Error('401')
    else throw new Error(JSON.stringify(error))
  }

  private async getToken(): Promise<string> {
    let tokens: ICognitoUserSession | null

    try {
      tokens = await AuthenticationService.getTokens()
    } catch (error) {
      tokens = null
    }

    if (!tokens || !tokens.idToken) return ''
    return tokens.idToken.jwtToken
  }
}
