import styled from 'styled-components'

export default styled.article`
  width: 100%;
  min-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${ ({ theme }) => theme.colors.almostWhite };

  button.floating-button {
    position: absolute;
    bottom: 70px;
    left: 0;
    right: 0;
    margin: auto;
    width: max-content;
    padding: 0 20px;
  }

  &.authenticated {
    min-height: calc(100vh - 202px);

    .spinner-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`
