import { IBase } from '../../models'
import { IUserInfoState } from '../../store/states'
import { Language } from '../literals-service'
import { BaseHttpService } from './base'

class UserService extends BaseHttpService {
  public getInfo = async (): Promise<any> => {
    return this.get('users/me')
  }

  public updateInfo = async (user: IUserInfoState): Promise<any> => {
    return this.put(`users/me/${user.id}`, user)
  }

  public updateLanguage = async (userId: number, language: Language): Promise<any> => {
    return this.patch('users/me/language', {
      userId,
      language
    })
  }

  public sendDeviceToken = async (
    userId: number,
    deviceToken: string
  ): Promise<any> => {
    return this.patch('users/me', {
      userId,
      deviceToken,
      deviceType: 'web'
    })
  }

  public signOutDevice = async (): Promise<void> => {
    return this.delete('users/me/token')
  }

  public getIsUserExperienced = async (): Promise<boolean> => {
    return this.get('users/me/experienced')
  }

  public verifyEmail = async (): Promise<void> => {
    return this.patch('users/me/verify', null)
  }

  public getReasons = async (): Promise<IBase[]> => {
    return this.get('users/leave-reasons')
  }

  public deleteUser = async (reasonId: number): Promise<void> => {
    return this.delete(`users/me?reason-id=${reasonId}`)
  }

  public async updateTown(id: number): Promise<void> {
    return this.put('users/me/town', { id })
  }
}

export default new UserService()
