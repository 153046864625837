import {
  IResetNewPasswordRequiredAction,
  ISetNewPasswordRequiredPasswordAction,
  ISetNewPasswordRequiredSavePasswordAction,
  ISetNewPasswordRequiredUserAction,
  NewPasswordRequiredTypeKeys
} from '../types'

export function resetNewPasswordRequiredFieldsAction(): IResetNewPasswordRequiredAction {
  return { type: NewPasswordRequiredTypeKeys.RESET_FIELDS }
}

export function setNewPasswordRequiredPasswordAction(payload: string): ISetNewPasswordRequiredPasswordAction {
  return { type: NewPasswordRequiredTypeKeys.SET_PASSWORD, payload }
}

export function setNewPasswordRequiredUserAction(payload: any): ISetNewPasswordRequiredUserAction {
  return { type: NewPasswordRequiredTypeKeys.SET_USER, payload }
}

export function setNewPasswordRequiredSavePasswordAction(): ISetNewPasswordRequiredSavePasswordAction {
  return { type: NewPasswordRequiredTypeKeys.SAVE_PASSWORD }
}
