import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { Button, Page, Spinner, Title, ValidatedInput } from '../../../components'
import { isEmailValid } from '../../../helpers'
import { images } from '../../../images'
import { LiteralsService } from '../../../services'
import { resendVerificationCodeAction, setVerificationEmailAction } from '../../../store/actions'
import { IStoreState } from '../../../store/states'
import StyledArticle from '../styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  dni: string
  email: string
  isSendingNewCode: boolean
}

class AccountVerificationResend extends PureComponent<IProps> {
  private get buttonIsDisabled(): boolean {
    const { email, isSendingNewCode } = this.props

    return !isEmailValid(email) || isSendingNewCode
  }

  private get verificationButton(): ReactNode {
    const { isSendingNewCode } = this.props
    if (isSendingNewCode) return <Spinner />
    return (
      <Button
        title={ LiteralsService.get('continue') }
        onPress={ this.handleValidatedButtonPress }
        filled
        upper
        isDisabled={ this.buttonIsDisabled }
      />
    )
  }

  public render(): ReactNode {
    const { email } = this.props

    return (
      <Page>
        <StyledArticle>
          <header>
            <img src={ images['logo'] } alt="AMB:Bus Metropolità"/>
          </header>
          <form onSubmit={ e => e.preventDefault() }>
            <Title text={ LiteralsService.get('requestNewSignUpVerificationCode', true) }/>
            <section>
              <ValidatedInput
                name="email"
                label={ LiteralsService.get('email', true) }
                value={ email }
                onChange={this.onEmailChange}
                error={ !isEmailValid(email) }
              />
            </section>
            <section className="actions">
              { this.verificationButton }
            </section>
          </form>
        </StyledArticle>
      </Page>
    )
  }

  private onEmailChange = (email: string) => {
    const { dispatch } = this.props
    dispatch(setVerificationEmailAction(email))
  }

  private handleValidatedButtonPress = () => {
    const { dispatch } = this.props
    dispatch(resendVerificationCodeAction())
  }
}

const mapStateToProps = ({ verification, signUp }: IStoreState) => ({
  dni: signUp.dni,
  email: verification.email ? verification.email : signUp.email,
  isSendingNewCode: verification.isSendingNewCode
})

export default withRouter(connect(mapStateToProps)(AccountVerificationResend))
