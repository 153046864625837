// import { Language } from '../../services/literals-service'

export enum UserInfoTypeKeys {
  CHECK_USER = 'CHECK_USER',
  SET_USER_INFO = 'SET_USER_INFO',
  SET_USER_INFO_DNI = 'SET_USER_INFO_DNI',
  SET_USER_INFO_EMAIL = 'SET_USER_INFO_EMAIL',
  SET_USER_INFO_NAME = 'SET_USER_INFO_NAME',
  SET_USER_INFO_LAST_NAME = 'SET_USER_INFO_LAST_NAME',
  SET_IS_USER_PENALIZED = 'SET_IS_USER_PENALIZED',
  SAVE_USER_INFO = 'SAVE_USER_INFO',
  SAVE_USER_LANGUAGE = 'SAVE_USER_LANGUAGE',
  CHANGE_USER_LENGUAGE = 'CHANGE_USER_LENGUAGE',
  CHANGE_FEEDBACK_FLAG = 'CHANGE_FEEDBACK_FLAG',
  CHANGE_CONFIRMATION_FLAG = 'CHANGE_CONFIRMATION_FLAG',
  IS_LOADING_USER_INFO = 'IS_LOADING_USER_INFO',
  SET_IS_USER_EXPERIENCED = 'SET_IS_USER_EXPERIENCED'
}

export interface ICheckSessionAction {
  type: UserInfoTypeKeys.CHECK_USER
}

export interface ISetUserInfoAction {
  type: UserInfoTypeKeys.SET_USER_INFO
  payload: any
}

export interface ISetUserInfoDNIAction {
  type: UserInfoTypeKeys.SET_USER_INFO_DNI
  payload: string
}

export interface ISetUserInfoEmailAction {
  type: UserInfoTypeKeys.SET_USER_INFO_EMAIL
  payload: string
}

export interface ISetUserInfoNameAction {
  type: UserInfoTypeKeys.SET_USER_INFO_NAME
  payload: string
}

export interface ISetUserInfoLastNameAction {
  type: UserInfoTypeKeys.SET_USER_INFO_LAST_NAME
  payload: string
}

export interface ISaveUserInfoAction {
  type: UserInfoTypeKeys.SAVE_USER_INFO
}

export interface ISaveUserLanguageAction {
  type: UserInfoTypeKeys.SAVE_USER_LANGUAGE
  payload: string
}

export interface IChangeUserLanguageAction {
  type: UserInfoTypeKeys.CHANGE_USER_LENGUAGE
  payload: string
}

export interface IChangeFeedbackFlagAction {
  type: UserInfoTypeKeys.CHANGE_FEEDBACK_FLAG
}

export interface IChangeConfirmationFlagAction {
  type: UserInfoTypeKeys.CHANGE_CONFIRMATION_FLAG
}

export interface ISetIsUserPenalizedAction {
  type: UserInfoTypeKeys.SET_IS_USER_PENALIZED
}

export interface ISetIsLoadingUserInfoAction {
  type: UserInfoTypeKeys.IS_LOADING_USER_INFO
  payload: boolean
}

export interface ISetIsUserExperienced {
  type: UserInfoTypeKeys.SET_IS_USER_EXPERIENCED
  payload: boolean
}

export type UserInfoActionTypes =
  | ICheckSessionAction
  | ISetUserInfoAction
  | ISetUserInfoDNIAction
  | ISetUserInfoEmailAction
  | ISetUserInfoNameAction
  | ISetUserInfoLastNameAction
  | ISaveUserInfoAction
  | IChangeUserLanguageAction
  | IChangeFeedbackFlagAction
  | IChangeConfirmationFlagAction
  | ISetIsUserPenalizedAction
  | ISetIsLoadingUserInfoAction
  | ISetIsUserExperienced
