import React from 'react'
import { images } from '../../../images'
import { IMenuOption } from '../../../models'
import StyledLi from './styles'

interface IProps {
  option: IMenuOption
  onClick?: () => void
}

export const Option: React.FC<IProps> = ({ option, onClick }) => {
  const handleClick = () => {
    if (option.action && typeof option.action === 'function' )option.action()
    if (onClick && typeof onClick === 'function') onClick()
  }

  return (
    <StyledLi className={ option.isDisabled ? 'disabled' : '' } onClick={ handleClick }>
      <img src={ images[option.icon] } alt={ option.label } />
      <span>{ option.label }</span>
    </StyledLi>
  )
}
