import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { images } from '../../../images'
import { ILocation, ISearchedType, IService, IStop } from '../../../models'
import { LiteralsService } from '../../../services'
import { setBusStopAction } from '../../../store/actions'
import { IStoreState } from '../../../store/states'
import MapContainer from '../../MapContainer'

interface IProps extends DispatchProp<AnyAction> {
  services: IService[]
  destinationBusStops: IStop[]
  pickedOriginBusStop?: IStop
  pickedDestinationBusStop?: IStop
  coordinates: ILocation | null
  searchedType: ISearchedType | null
}

class MapStopsView extends PureComponent<IProps> {
  private get stopsSummary(): ReactNode {
    const { pickedOriginBusStop, pickedDestinationBusStop } = this.props
    const origin = pickedOriginBusStop ? pickedOriginBusStop.name : LiteralsService.get('origin', true)
    const destination = pickedDestinationBusStop
      ? pickedDestinationBusStop.name
      : LiteralsService.get('destination', true)
    return (
      <section className="stops-summary">
        <div>
          <img src={ images['stopOrigin'] } alt={ LiteralsService.get('origin', true) }/>
          <span className={ !pickedOriginBusStop ? 'placeholder' : '' }>
            { origin }
          </span>
        </div>
        <div className="dashed" />
        <div>
          <img src={ images['stopDestination'] } alt={ LiteralsService.get('destination', true) }/>
          <span className={ !pickedDestinationBusStop ? 'placeholder' : '' }>
            { destination }
          </span>
        </div>
      </section>
    )
  }

  public render(): ReactNode {
    const {
      services,
      destinationBusStops,
      pickedOriginBusStop,
      pickedDestinationBusStop
    } = this.props

    if (!services || !services.length) return null

    return (
      <>
        <MapContainer
          services={ services }
          zoomLevel={ 14 }
          inStop={ pickedOriginBusStop }
          outStop={ pickedDestinationBusStop }
          outBusStops={ destinationBusStops }
          controlsEnabled
          isClusterMap
          onMarkerClick={ this.onStopClick }
        />
        { this.stopsSummary }
      </>
    )
  }

  private onStopClick = (idService: number, stop: IStop) => {
    const { dispatch } = this.props

    dispatch(setBusStopAction({ idService, stop }))
  }
}

const mapStateToProps = ({ newReservation }: IStoreState) => ({
  services: newReservation.services,
  coordinates: newReservation.coordinates,
  destinationBusStops: newReservation.destinationBusStops,
  pickedOriginBusStop: newReservation.pickedOriginBusStop,
  pickedDestinationBusStop: newReservation.pickedDestinationBusStop,
  searchedType: newReservation.searchedType
})

export default connect(mapStateToProps)(MapStopsView)
