import { IReservation, IReservationDetail } from '../../models'

export enum ReservationsTypeKeys {
  FETCH_RESERVATIONS = 'FETCH_RESERVATIONS',
  FETCHING_RESERVATIONS = 'FETCHING_RESERVATIONS',
  FETCH_RESERVATIONS_SUCCESSFUL = 'FETCH_RESERVATIONS_SUCCESSFUL',
  FETCH_MORE_RESERVATIONS = 'FETCH_MORE_RESERVATIONS',
  FETCHING_MORE_RESERVATIONS = 'FETCHING_MORE_RESERVATIONS',
  FETCH_MORE_RESERVATIONS_SUCCESSFUL = 'FETCH_MORE_RESERVATIONS_SUCCESSFUL',
  UPDATE_RESERVATION_FAVORITE_FLAG = 'UPDATE_RESERVATION_FAVORITE_FLAG',
  FETCH_PAST_RESERVATIONS = 'FETCH_PAST_RESERVATIONS',
  FETCHING_PAST_RESERVATIONS = 'FETCHING_PAST_RESERVATIONS',
  FETCH_PAST_RESERVATIONS_SUCCESSFUL = 'FETCH_PAST_RESERVATIONS_SUCCESSFUL',
  FETCH_RESERVATION_DETAILS = 'FETCH_RESERVATION_DETAILS',
  FETCHING_RESERVATION_DETAILS = 'FETCHING_RESERVATION_DETAILS',
  FETCH_RESERVATION_DETAILS_SUCCESSFUL = 'FETCH_RESERVATION_DETAILS_SUCCESSFUL',
  CLEAR_RESERVATION_DETAILS = 'CLEAR_RESERVATION_DETAILS',
  DELETE_RESERVATION = 'DELETE_RESERVATION',
  DELETING_RESERVATION = 'DELETING_RESERVATION',
  DELETE_RESERVATION_SUCCESSFUL = 'DELETE_RESERVATION_SUCCESSFUL',
  SET_EXPEDITION_STATUS = 'SET_EXPEDITION_STATUS',
  SET_FULLSCREEN_MAP = 'SET_FULLSCREEN_MAP',
  SHOW_NOTIFICATION_MODAL = 'SHOW_NOTIFICATION_MODAL',
  HIDE_NOTIFICATION_MODAL = 'HIDE_NOTIFICATION_MODAL'
}

export interface IFetchReservationsAction {
  type: typeof ReservationsTypeKeys.FETCH_RESERVATIONS
}

export interface ISetIsFetchingReservationsAction {
  type: typeof ReservationsTypeKeys.FETCHING_RESERVATIONS
}

export interface IFetchReservationsSuccessfulAction {
  type: typeof ReservationsTypeKeys.FETCH_RESERVATIONS_SUCCESSFUL
  payload: IReservation[]
}

export interface IFetchMoreReservationsAction {
  type: typeof ReservationsTypeKeys.FETCH_MORE_RESERVATIONS
  payload: number
}

export interface ISetIsFetchingMoreReservationsAction {
  type: typeof ReservationsTypeKeys.FETCHING_MORE_RESERVATIONS
}

export interface IFetchMoreReservationsSuccessfulAction {
  type: typeof ReservationsTypeKeys.FETCH_MORE_RESERVATIONS_SUCCESSFUL
  payload: IReservation[]
}

export interface IUpdateReservationFavoriteFlagAction {
  type: typeof ReservationsTypeKeys.UPDATE_RESERVATION_FAVORITE_FLAG
  payload: number
}

export interface IFetchPastReservationsAction {
  type: typeof ReservationsTypeKeys.FETCH_PAST_RESERVATIONS
}

export interface ISetIsFetchingPastReservationsAction {
  type: typeof ReservationsTypeKeys.FETCHING_PAST_RESERVATIONS
}

export interface IFetchPastReservationsSuccessfulAction {
  type: typeof ReservationsTypeKeys.FETCH_PAST_RESERVATIONS_SUCCESSFUL
  payload: IReservation[]
}

export interface IFetchReservationDetailsAction {
  type: typeof ReservationsTypeKeys.FETCH_RESERVATION_DETAILS
  payload: IReservationDetail
}

export interface ISetIsFetchingReservationDetailsAction {
  type: typeof ReservationsTypeKeys.FETCHING_RESERVATION_DETAILS
}

export interface IFetchReservationDetailsSuccessfulAction {
  type: typeof ReservationsTypeKeys.FETCH_RESERVATION_DETAILS_SUCCESSFUL
  payload: IReservation
}

export interface IClearReservationDetailsAction {
  type: typeof ReservationsTypeKeys.CLEAR_RESERVATION_DETAILS
}

export interface IDeleteReservationAction {
  type: typeof ReservationsTypeKeys.DELETE_RESERVATION
  payload: number
}

export interface ISetIsDeletingReservationAction {
  type: typeof ReservationsTypeKeys.DELETING_RESERVATION
}

export interface IDeleteReservationSuccessfulAction {
  type: typeof ReservationsTypeKeys.DELETE_RESERVATION_SUCCESSFUL
}

export interface ISetExpeditionStatusAction {
  type: typeof ReservationsTypeKeys.SET_EXPEDITION_STATUS
  payload: string
}

export interface ISetFullscreenMapAction {
  type: typeof ReservationsTypeKeys.SET_FULLSCREEN_MAP
  payload: boolean
}

export interface IShowNotificationModalAction {
  type: typeof ReservationsTypeKeys.SHOW_NOTIFICATION_MODAL
  payload: any
}

export interface IHideNotificationModalAction {
  type: typeof ReservationsTypeKeys.HIDE_NOTIFICATION_MODAL
}

export type ReservationsActionTypes =
  | IFetchReservationsAction
  | ISetIsFetchingReservationsAction
  | IFetchReservationsSuccessfulAction
  | IFetchMoreReservationsAction
  | ISetIsFetchingMoreReservationsAction
  | IFetchMoreReservationsSuccessfulAction
  | IUpdateReservationFavoriteFlagAction
  | IFetchPastReservationsAction
  | ISetIsFetchingPastReservationsAction
  | IFetchPastReservationsSuccessfulAction
  | IFetchReservationDetailsAction
  | ISetIsFetchingReservationDetailsAction
  | IFetchReservationDetailsSuccessfulAction
  | IClearReservationDetailsAction
  | IDeleteReservationAction
  | ISetIsDeletingReservationAction
  | IDeleteReservationSuccessfulAction
  | ISetExpeditionStatusAction
  | ISetFullscreenMapAction
  | IShowNotificationModalAction
  | IHideNotificationModalAction
