import React, { PureComponent, ReactNode } from 'react'
import { Page } from '../../components'

class NewPasswordRequired extends PureComponent {
  public render(): ReactNode {
    return (
      <Page>
        NewPasswordRequired
      </Page>
    )
  }
}

export default NewPasswordRequired
