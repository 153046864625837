import React, { PureComponent, ReactNode } from 'react'
import { Page } from '../../../components'

class ChangeLanguage extends PureComponent {
  public render(): ReactNode {
    return (
      <Page>
        ChangeLanguage
      </Page>
    )
  }
}

export default ChangeLanguage
