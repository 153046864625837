import styled from 'styled-components'

export default styled.article`
  display: flex;
  height: 100%;
  width: 100vw;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 25px;
  box-sizing: border-box;

  header {
    flex: 0;
    margin-bottom: 25px;
    align-items: center;

    img {
      max-width: 365px;
    }
  }

  form {
    width: 515px;
    flex: 1;
    border-radius: 20px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
    padding: 70px 90px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background-color: ${ ({ theme }) => theme.colors.white };

    .link {
      text-align: center;

      span {
        color: ${ ({ theme }) => theme.colors.primary };
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 15px;
        text-align: center;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .actions {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .link {
        margin-top: 30px;
      }
    }

    .error-container {
      flex-direction: row;
      justify-content: center;
      margin-top: 25px;
      width: 100%;
      text-align: center;
      align-self: center;

      .error {
        color: ${ ({ theme }) => theme.colors.red };
        text-align: center;
      }
    }

    .checkbox {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      img {
        cursor: pointer;
        margin-right: 8px;
      }

      .link {
        color: ${ ({ theme }) => theme.colors.primary };
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 15px;
        text-align: center;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
`
