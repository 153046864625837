import { IFavorite, IFavoriteResponse, IReservation } from '../../models'

export enum FavoriteTypeKeys {
  FETCH_FAVORITES = 'FETCH_FAVORITES',
  FETCHING_FAVORITES = 'FETCHING_FAVORITES',
  FETCH_FAVORITES_SUCCESSFUL = 'FETCH_FAVORITES_SUCCESSFUL',
  SET_SELECTED_RESERVATION_MODAL = 'SET_SELECTED_RESERVATION_MODAL',
  SET_SELECTED_FAVORITE_MODAL = 'SET_SELECTED_FAVORITE_MODAL',
  ADD_FAVORITE = 'ADD_FAVORITE',
  ADDING_FAVORITE = 'ADDING_FAVORITE',
  ADD_FAVORITE_SUCCESSFUL = 'ADD_FAVORITE_SUCCESSFUL',
  REMOVE_FAVORITE = 'REMOVE_FAVORITE',
  REMOVING_FAVORITE = 'REMOVING_FAVORITE',
  REMOVE_FAVORITE_SUCCESSFUL = 'REMOVE_FAVORITE_SUCCESSFUL'
}

export interface IFetchFavoritesAction {
  type: typeof FavoriteTypeKeys.FETCH_FAVORITES
}

export interface ISetIsFetchingFavoritesAction {
  type: typeof FavoriteTypeKeys.FETCHING_FAVORITES
}

export interface IFetchFavoritesSuccessfulAction {
  type: typeof FavoriteTypeKeys.FETCH_FAVORITES_SUCCESSFUL
  payload: IFavoriteResponse[]
}

export interface ISetSelectedReservationModalAction {
  type: typeof FavoriteTypeKeys.SET_SELECTED_RESERVATION_MODAL
  payload: IReservation | null
}

export interface ISetSelectedFavoriteModalAction {
  type: typeof FavoriteTypeKeys.SET_SELECTED_FAVORITE_MODAL
  payload: IFavoriteResponse | null
}
export interface IAddFavoriteAction {
  type: typeof FavoriteTypeKeys.ADD_FAVORITE
  payload: IFavorite
}

export interface ISetIsAddingFavoriteAction {
  type: typeof FavoriteTypeKeys.ADDING_FAVORITE
}

export interface IAddFavoritesSuccessfulAction {
  type: typeof FavoriteTypeKeys.ADD_FAVORITE_SUCCESSFUL
}

export interface IRemoveFavoriteAction {
  type: typeof FavoriteTypeKeys.REMOVE_FAVORITE
}

export interface ISetIsRemovingFavoriteAction {
  type: typeof FavoriteTypeKeys.REMOVING_FAVORITE
}

export interface IRemoveFavoriteSuccessfulAction {
  type: typeof FavoriteTypeKeys.REMOVE_FAVORITE_SUCCESSFUL
}

export type FavoritesActionTypes =
  | IFetchFavoritesAction
  | ISetIsFetchingFavoritesAction
  | IFetchFavoritesSuccessfulAction
  | ISetSelectedReservationModalAction
  | ISetSelectedFavoriteModalAction
  | IAddFavoriteAction
  | ISetIsAddingFavoriteAction
  | IAddFavoritesSuccessfulAction
  | IRemoveFavoriteAction
  | ISetIsRemovingFavoriteAction
  | IRemoveFavoriteSuccessfulAction
