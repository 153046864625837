import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import Calendar from 'react-calendar'
import { Button, Page, Spinner, TimePicker } from '../../../components'
import { IStop } from '../../../models'
import { urls } from '../../../routing'
import { LiteralsService } from '../../../services'
import {
  fetchRouteOptionsAction,
  setPickedDateAction,
  setPickedSeatsAction,
  setTypeSpecificDateFlag
} from '../../../store/actions'
import { IStoreState } from '../../../store/states'
import StyledArticle from '../styles'
import { SeatsModal } from './SeatsModal'
import { StyledCalendarContainer, StyledSeatsContainer, StyledTimeContainer } from './styles'
import 'react-calendar/dist/Calendar.css'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  pickedOriginBusStop?: IStop
  pickedDestinationBusStop?: IStop
  specificDate: boolean
  pickedDate: Date
  pickedSeats: number
  pickedPrmSeats: number
  isFetchingRouteOptions: boolean
}

interface IState {
  displaySeatsModal: boolean
  desiredDate?: Date
  desiredHour?: string
}

class DateStep extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      displaySeatsModal: false,
      desiredDate: undefined,
      desiredHour: undefined
    }
  }

  private get isEveryFieldValid(): boolean {
    const { pickedSeats, pickedPrmSeats } = this.props
    const { desiredDate, desiredHour } = this.state

    return (!!pickedSeats || !!pickedPrmSeats)
      && desiredDate !== undefined
      && desiredHour !== undefined
  }

  private get normalSeatsText(): ReactNode {
    const pickedSeats = this.props.pickedSeats
    if (pickedSeats <= 0) return null
    return (
      <p >
        { `${pickedSeats} ${LiteralsService.get(pickedSeats !== 1 ? 'seats' : 'seat')}` }
      </p>
    )
  }

  private get prioritySeatsText(): ReactNode {
    const pickedPrmSeats = this.props.pickedPrmSeats
    if (pickedPrmSeats <= 0) return null
    return (
      <p >
        { `${pickedPrmSeats} ${LiteralsService.get(pickedPrmSeats !== 1 ? 'prmSeats' : 'prmSeat')}` }
      </p>
    )
  }

  private get nextButton(): ReactNode {
    const { isFetchingRouteOptions, dispatch } = this.props

    if (isFetchingRouteOptions) return <Spinner />
    return (
      <Button
        title={ LiteralsService.get('following') }
        onPress={ () => dispatch(fetchRouteOptionsAction()) }
        isDisabled={ !this.isEveryFieldValid }
        filled
        upper
      />
    )
  }

  public render(): ReactNode {
    const { history, specificDate, pickedSeats, pickedPrmSeats } = this.props

    return (
      <Page className="authenticated">
        <StyledArticle className="list-mode">
          <header>
            <h3>
              { LiteralsService.get('desiredDate').toUpperCase() }
            </h3>
          </header>
          <section>
            <StyledCalendarContainer>
              <Calendar onChange={ this.handleDateChange }/>
            </StyledCalendarContainer>
            <StyledTimeContainer>
              <h3>
                { LiteralsService.get('desiredHour').toUpperCase() }
              </h3>
              <div className="time-flag">
                <Button
                  title={ LiteralsService.get('takeAfter', true) }
                  onPress={ () => this.handleFlagClick(false) }
                  filled={ !specificDate }
                  upper
                />
                <Button
                  title={ LiteralsService.get('arriveBefore', true) }
                  onPress={ () => this.handleFlagClick(true) }
                  filled={ specificDate }
                  upper
                />
              </div>
              <div className="hour">
                <TimePicker
                  placeholder={ LiteralsService.get('hour', true) }
                  onChange={ this.handleHourChange }
                />
              </div>
            </StyledTimeContainer>
            <StyledSeatsContainer>
              <div>
                { this.normalSeatsText }
                { this.prioritySeatsText }
              </div>
              <Button
                title={ LiteralsService.get('edit', true) }
                onPress={ this.toggleSeatsModal }
                upper
              />
            </StyledSeatsContainer>
          </section>
          <footer>
            { this.nextButton }
            <Button
              title={ LiteralsService.get('previous') }
              onPress={ () => history.push(urls.newReservationBusStopsStep) }
              upper
            />
          </footer>
        </StyledArticle>
        <SeatsModal
          visible={ this.state.displaySeatsModal }
          seats={ pickedSeats }
          prmSeats={ pickedPrmSeats }
          onSave={ this.handleSaveSeats }
          close={ this.toggleSeatsModal }/>
      </Page>
    )
  }


  private handleFlagClick = (flag: boolean) => {
    const { dispatch } = this.props

    dispatch(setTypeSpecificDateFlag(flag))
  }

  private toggleSeatsModal = () => {
    this.setState({
      displaySeatsModal: !this.state.displaySeatsModal
    })
  }

  private handleSaveSeats = (seats: number, prmSeats: number) => {
    const { dispatch } = this.props

    dispatch(setPickedSeatsAction(seats, prmSeats))
    this.setState({
      displaySeatsModal: !this.state.displaySeatsModal
    })
  }

  private handleDateChange = (date: Date | Date[]) => {
    const { dispatch } = this.props
    const { desiredHour } = this.state
    const desiredDate = date instanceof Date ? date : date[0]

    if (desiredHour) {
      const hour = parseInt(desiredHour.split(':')[0])
      const minute = parseInt(desiredHour.split(':')[1])
      desiredDate.setHours(hour, minute)
    }
    this.setState({ desiredDate })
    dispatch(setPickedDateAction(desiredDate))
  }

  private handleHourChange = (time: string) => {
    const { dispatch, pickedDate } = this.props
    const desiredDate = pickedDate ? pickedDate : new Date()
    const hour = parseInt(time.split(':')[0])
    const minute = parseInt(time.split(':')[1])
    desiredDate.setHours(hour, minute)

    this.setState({ desiredHour: time })
    dispatch(setPickedDateAction(desiredDate))
  }
}

const mapStateToProps = ({ newReservation }: IStoreState) => ({
  pickedOriginBusStop: newReservation.pickedOriginBusStop,
  pickedDestinationBusStop: newReservation.pickedDestinationBusStop,
  specificDate: newReservation.specificDate,
  pickedDate: newReservation.pickedDate,
  pickedSeats: newReservation.pickedSeats,
  pickedPrmSeats: newReservation.pickedPrmSeats,
  isFetchingRouteOptions: newReservation.isFetchingRouteOptions
})

export default withRouter(connect(mapStateToProps)(DateStep))
