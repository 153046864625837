import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { AuthenticationService } from '../../services'
import {
  checkSessionAction,
  setIsSigningInAction,
  signInFailedAction
} from '../actions'
import { IStoreState } from '../states'
import { NewPasswordRequiredTypeKeys } from '../types'

// Worker Saga
function* fetchSignInAsync() {
  try {
    yield put(setIsSigningInAction())
    const { user, password } = yield select(
      (state: IStoreState) => state.newPasswordRequired
    )
    yield call(AuthenticationService.completeNewPassword, user, password)
    yield put(checkSessionAction())
  } catch (error) {
    yield put(signInFailedAction(error))
  }
}

// Watcher Saga:
function* watchFetchSignInAsync() {
  yield takeLatest(NewPasswordRequiredTypeKeys.SAVE_PASSWORD, fetchSignInAsync)
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([watchFetchSignInAsync()])
}
