import {
  IClearVerificationFieldsAction,
  IConfirmVerificationCodeAction,
  IConfirmVerificationCodeFailedAction,
  IConfirmVerificationCodeSuccessfulAction,
  IResendVerificationCodeAction,
  IResendVerificationCodeFailedfulAction,
  IResendVerificationCodeSuccessfulAction,
  ISetIsConfirmingVerificationCodeAction,
  ISetIsResendingVerificationCodeAction,
  ISetVerificationCodeAction,
  ISetVerificationDNIAction,
  ISetVerificationEmailAction,
  VerificationTypeKeys
} from '../types'

export function clearVerificationFieldsAction(): IClearVerificationFieldsAction {
  return { type: VerificationTypeKeys.CLEAR_VERIFICATION_FIELDS }
}

export function setVerificationDNIAction(email: string): ISetVerificationDNIAction {
  return { type: VerificationTypeKeys.SET_VERIFICATION_DNI, payload: email }
}

export function setVerificationCodeAction(code: string): ISetVerificationCodeAction {
  return { type: VerificationTypeKeys.SET_VERIFICATION_CODE, payload: code }
}

export function confirmVerificationCodeAction(): IConfirmVerificationCodeAction {
  return { type: VerificationTypeKeys.CONFIRM_VERIFICATION_CODE }
}

export function setIsConfirmingVerificationCodeAction(): ISetIsConfirmingVerificationCodeAction {
  return { type: VerificationTypeKeys.CONFIRMING_VERIFICATION_CODE }
}

export function confirmVerificationCodeSuccessfulAction(): IConfirmVerificationCodeSuccessfulAction {
  return { type: VerificationTypeKeys.CONFIRM_VERIFICATION_CODE_SUCCESSFUL }
}

export function confirmVerificationCodeFailedAction(): IConfirmVerificationCodeFailedAction {
  return { type: VerificationTypeKeys.CONFIRM_VERIFICATION_CODE_FAILED }
}

export function setVerificationEmailAction(email: string): ISetVerificationEmailAction {
  return { type: VerificationTypeKeys.SET_VERIFICATION_EMAIL, payload: email }
}

export function resendVerificationCodeAction(): IResendVerificationCodeAction {
  return { type: VerificationTypeKeys.RESEND_VERIFICATION_CODE }
}

export function setIsResendingVerificationCodeAction(): ISetIsResendingVerificationCodeAction {
  return { type: VerificationTypeKeys.RESENDING_VERIFICATION_CODE }
}

export function resendVerificationCodeSuccessfulAction(): IResendVerificationCodeSuccessfulAction {
  return { type: VerificationTypeKeys.RESEND_VERIFICATION_CODE_SUCCESSFUL }
}

export function resendVerificationCodeFailedAction(): IResendVerificationCodeFailedfulAction {
  return { type: VerificationTypeKeys.RESEND_VERIFICATION_CODE_FAILED }
}
