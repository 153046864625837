import { IConfigurationState } from '../states'
import { ConfigurationActionTypes, ConfigurationTypeKeys } from '../types'

export const initialConfigurationState: IConfigurationState = {
  language: 'ca',
  areas: [],
  selectedAreaId: null,
  isFetchingAreas: false
}

const configuration = (
  state: IConfigurationState = initialConfigurationState,
  action: ConfigurationActionTypes
): IConfigurationState => {
  switch (action.type) {
    case ConfigurationTypeKeys.FETCHING_AREAS:
      return { ...state, isFetchingAreas: true }
    case ConfigurationTypeKeys.CHANGE_LANGUAGE:
      return { ...state, language: action.payload }
    case ConfigurationTypeKeys.FETCH_AREAS_SUCCESSFUL:
      return {
        ...state,
        isFetchingAreas: false,
        areas: action.payload
      }
    case ConfigurationTypeKeys.SET_SELECTED_AREA:
      return {
        ...state,
        selectedAreaId: action.payload
      }
    default:
      return state
  }
}

export default configuration
