import moment from 'moment'
import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { Button, Modal, Page, ReservationDetailsCard, Spinner } from '../../../components'
import { addDelayToReservation } from '../../../helpers'
import { ICoordinate, IReservation } from '../../../models'
import { urls } from '../../../routing'
import { LiteralsService, ReservationService } from '../../../services'
import {
  clearReservationDetailsAction,
  deleteReservationAction,
  fetchReservationDetailsAction,
  setExpeditionStatusAction,
  setNewReservationFieldsAction,
  setSelectedReservationModalAction
} from '../../../store/actions'
import { IStoreState } from '../../../store/states'
import StyledArticle from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  reservation: IReservation | null
  isDeletingReservation: boolean
}

interface IState {
  busLocation: ICoordinate | null
  expeditionStatus: string
  delay: number
  isDeleteModalVisible: boolean
}

class ReservationDetails extends PureComponent<IProps, IState> {
  private busInterval: any

  private get shouldShowInfo(): boolean {
    const { expeditionStatus } = this.state
    const { reservation } = this.props
    return !reservation?.cancelled && expeditionStatus.toLowerCase() === 'ongoing'
  }

  private get infoText(): string {
    const { delay } = this.state
    const { reservation } = this.props

    const now = moment()

    const beginningStopWithDelay = moment(
      reservation?.beginningStop.dateTime
    ).add(delay, 'minutes')

    const endStopWithDelay = moment(
      reservation?.seats[0].exitStop.dateTime
    ).add(delay, 'minutes')

    if (now.isSameOrBefore(beginningStopWithDelay)) {
      return `${LiteralsService.get(
        'pickUpSoon',
        true
      )} ${beginningStopWithDelay.diff(moment(), 'minutes')} min`
    } else if (now.isBetween(beginningStopWithDelay, endStopWithDelay)) {
      return LiteralsService.get('onGoing', true)
    } else return LiteralsService.get('past', true)
  }

  private get info(): ReactNode {
    return (
      <div className="info-bubble">
        <span className="info-text">{this.infoText}</span>
      </div>
    )
  }

  constructor(props: IProps) {
    super(props)

    this.state = {
      busLocation: null,
      expeditionStatus: '',
      delay: 0,
      isDeleteModalVisible: false
    }
  }

  public componentDidMount(): void {
    const { dispatch, location } = this.props
    const { id, cancelled } = location.state

    this.busIntervalFunction()
    this.busInterval = setInterval(this.busIntervalFunction, 3500)
    dispatch(fetchReservationDetailsAction(id, cancelled))
  }

  public componentWillUnmount(): void {
    clearInterval(this.busInterval)
    this.props.dispatch(clearReservationDetailsAction())
  }

  public render(): ReactNode {
    const { reservation, history } = this.props
    const { delay, busLocation, expeditionStatus, isDeleteModalVisible } = this.state

    if (!reservation) {
      return (
        <Page className="authenticated">
          <StyledArticle style={{ justifyContent: 'center' }}>
            <Spinner />
          </StyledArticle>
        </Page>
      )
    }
    return (
      <>
        <Page className="authenticated">
          <StyledArticle>
            <section className="actions">
              <Button
                title={ LiteralsService.get('back', true) }
                onPress={ () => history.push(urls.reservations) }
              />
              <Button
                title={ LiteralsService.get('edit', true) }
                onPress={ this.editReservation }
              />
              <Button
                title={ LiteralsService.get('cancel', true) }
                onPress={ this.toggleDeleteModalVisibility }
              />
            </section>
            <section className="details-container">
              <ReservationDetailsCard
                reservation={ addDelayToReservation(reservation, delay) }
                onFavoriteClick={ this.displayFavoriteModal }
                busLocation={ busLocation }
                aprox={ expeditionStatus.toLowerCase() === 'open' }
                extraInfoMessage={ expeditionStatus.toLowerCase() === 'open' }
                service={ null }
              />
              { this.shouldShowInfo && this.info }
            </section>
          </StyledArticle>
        </Page>
        <Modal className="small" isHidden={ !isDeleteModalVisible } onOverlayClick={ this.toggleDeleteModalVisibility }>
          <header>
            <h3>{ LiteralsService.get('deleteReservation', true) }</h3>
          </header>
          <section className="content">
            <p>
              { LiteralsService.get('sureToDelete', true) }
            </p>
          </section>
          <footer>
            <Button
              title={ LiteralsService.get('yes') }
              styles={{ width: '155px' }}
              onPress={ this.deleteReservation }
              upper
              filled
            />
            <Button
              title={ LiteralsService.get('no') }
              styles={{ width: '155px' }}
              onPress={ this.toggleDeleteModalVisibility }
              upper
            />
          </footer>
        </Modal>
      </>
    )
  }

  private toggleDeleteModalVisibility = () => {
    this.setState({ isDeleteModalVisible: !this.state.isDeleteModalVisible })
  }

  private busIntervalFunction = async (): Promise<void> => {
    const { reservation, dispatch } = this.props

    if (!reservation) return

    let expeditionStatus = ''
    try {
      expeditionStatus = await ReservationService.getReservationStatus(
        reservation.id
      )
    } catch (error) {
      expeditionStatus = 'closed'
    }
    if (reservation.cancelled) expeditionStatus = 'cancelled'
    dispatch(setExpeditionStatusAction(expeditionStatus))

    const oldDelay = this.state.delay
    let busLocation = null
    let delay = this.state.delay
    const now = moment()
    const endStopWithDelay = moment(reservation.seats[0].exitStop.dateTime).add(
      delay,
      'minutes'
    )

    if (
      expeditionStatus.toLowerCase() === 'ongoing' &&
      now.isSameOrBefore(endStopWithDelay)
    ) {
      let response
      try {
        response = await ReservationService.getBusPosition(reservation.id)

        busLocation = {
          latitude: response.latitude,
          longitude: response.longitude
        }
        delay = response.delay
        this.setState({ delay })
      } catch (error) {
        busLocation = null
      }
    }

    this.setState({
      expeditionStatus,
      busLocation,
      delay: delay !== oldDelay ? delay : oldDelay
    })
  }

  private displayFavoriteModal = () => {
    const { reservation, dispatch } = this.props

    if (!reservation?.favorite) {
      dispatch(setSelectedReservationModalAction(reservation))
    }
  }

  private editReservation = () => {
    const { dispatch, history, reservation } = this.props

    if (reservation) {
      dispatch(setNewReservationFieldsAction(reservation))
      history.push(urls.newReservationBusStopsStep)
    }
  }

  private deleteReservation = () => {
    const { reservation, dispatch } = this.props

    if (reservation) dispatch(deleteReservationAction(reservation.id))
  }
}

const mapStateToProps = ({ reservations }: IStoreState) => ({
  reservation: reservations.pickedReservation,
  isDeletingReservation: reservations.isDeletingReservation
})

export default withRouter(connect(mapStateToProps)(ReservationDetails))
