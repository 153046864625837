import styled from 'styled-components'

export default styled.div`
  position: relative;

  > button {
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .options {
    position: absolute;
    right: 0;
    top: 60px;
    z-index: 2000;
    border-radius: 4px;
    overflow: hidden;
    width: 174px;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.2);
    background-color: ${ ({ theme }) => theme.colors.white };
    padding: 0;
    border-radius: 6px;
  }
`
