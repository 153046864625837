import { DateStepTypeKeys } from '../../types'

export function setTypeSpecificDateFlag(flag: boolean) {
  return { type: DateStepTypeKeys.SET_TYPE_SPECIFIC_DATE_FLAG, payload: flag }
}

export function setPickedDateAction(date: Date) {
  return { type: DateStepTypeKeys.SET_PICKED_DATE, payload: date }
}

export function setPickedSeatsAction(seats: number, prmSeats: number) {
  return { type: DateStepTypeKeys.SET_PICKED_SEATS, payload: { seats, prmSeats } }
}
