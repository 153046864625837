import { IColors, ITheme } from './models'

const colors: IColors = {
  blue: '#280A54',
  primary: '#E42313',
  blueLight: '#8136DB',
  greenRadio: '#0DEDAB',
  black: '#232530',
  greyDark: '#58585A',
  grey: '#B7BDCC',
  greyLight: '#E3E6EB',
  greyMedium: '#848EA8',
  almostWhite: '#F3F5FB',
  white: '#FFFFFF',
  green: '#06CB60',
  orange: '#F5C600',
  orangeDark: '#E77230',
  red: '#D83556',
  purple: '#280A54',
  greenNotification: '#0DEDAB'
}

const theme: ITheme = {
  fontFamily: 'Titillium Web',
  colors,
  textStyles: {
    display: { fontFamily: 'Assistant-Bold', fontSize: 24, color: colors.black },
    title: { fontFamily: 'Assistant-Bold', fontSize: 18, color: colors.black },
    hightlight: {
      fontFamily: 'Assistant-SemiBold',
      fontSize: 18,
      color: colors.black
    },
    body: { fontFamily: 'Fira Sans', fontSize: 18 },
    subTitle: {
      fontFamily: 'Assistant-SemiBold',
      fontSize: 18,
      color: colors.black
    },
    caption: { fontFamily: 'Fira Sans', fontSize: 14, color: colors.black },
    button: { fontFamily: 'Assistant-Bold', fontSize: 16, color: colors.black },
    link: {
      fontFamily: 'Assistant-SemiBold',
      fontSize: 14,
      color: colors.primary,
      textDecorationLine: 'underline'
    },
    grey: {
      color: colors.grey
    },
    pickerText: {
      fontFamily: 'Fira Sans',
      fontSize: 18,
      color: colors.black,
      lineHeight: 23
    }
  }
}

export default theme
