import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { Button, Page, Title, ValidatedInput } from '../../../components'
import { isEmailValid, isPasswordValid } from '../../../helpers'
import { images } from '../../../images'
import { urls } from '../../../routing'
import { LiteralsService } from '../../../services'
import { setSignUpEmailAction, setSignUpPasswordAction } from '../../../store/actions'
import { IStoreState } from '../../../store/states'
import StyledArticle from '../styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  email: string
  password: string
}

interface IState {
  repeatEmail: string
  repeatPassword: string
}

class SignUpStep1 extends PureComponent<IProps, IState> {
  private get buttonIsDisabled(): boolean {
    const { email, password } = this.props
    const { repeatEmail, repeatPassword } =  this.state

    return (
      !isEmailValid(email) ||
      !isPasswordValid(password) ||
      !isPasswordValid(repeatPassword) ||
      email !== repeatEmail ||
      password !== repeatPassword
    )
  }

  private get passwordAlert(): ReactNode {
    const { password } = this.props

    return (
      password !== '' &&
      !isPasswordValid(password) && (
        <div className="error-container">
          <span className="error">
            { LiteralsService.get('validPassword', true) }
          </span>
        </div>
      )
    )
  }

  constructor(props: IProps) {
    super(props)
    this.state = { repeatEmail: '', repeatPassword: '' }
  }

  public render(): ReactNode {
    const { email, password } = this.props
    const { repeatEmail, repeatPassword } = this.state

    return (
      <Page>
        <StyledArticle>
          <header>
            <img src={ images['logo'] } alt="AMB:Bus Metropolità"/>
          </header>
          <form onSubmit={ e => e.preventDefault() }>
            <Title text={ LiteralsService.get('yourUserData', true) } />
            <section>
              <ValidatedInput
                name="email"
                label={ LiteralsService.get('email', true) }
                value={ email }
                onChange={ this.onEmailChange }
                error={ !isEmailValid(email) }
              />
              <ValidatedInput
                name="repeat-email"
                label={ LiteralsService.get('repeatEmail', true) }
                value={ repeatEmail }
                onChange={ this.onRepeatEmailChange }
                error={ !isEmailValid(repeatEmail) || email !== repeatEmail }
              />
              <ValidatedInput
                name="password"
                label={ LiteralsService.get('password', true) }
                value={ password }
                onChange={ this.onPasswordChange }
                error={ !isPasswordValid(password) }
                isPassword
              />
              { this.passwordAlert }
              <ValidatedInput
                name="repeat-password"
                label={ LiteralsService.get('repeatPassword', true) }
                value={ repeatPassword }
                onChange={ this.onRepeatPasswordChange }
                error={ !isPasswordValid(repeatPassword) || password !== repeatPassword }
                isPassword
              />
            </section>
            <section className="actions">
              <Button
                title={ LiteralsService.get('continue') }
                onPress={ this.goToStep2 }
                filled
                upper
                isDisabled={ this.buttonIsDisabled }
              />
              <div className="link">
                <span onClick={ this.goToSignIn }>
                  { LiteralsService.get('alreadyHaveAccount', true) }
                </span>
              </div>
            </section>
          </form>
        </StyledArticle>
      </Page>
    )
  }

  private onEmailChange = (email: string) => {
    const { dispatch } = this.props
    dispatch(setSignUpEmailAction(email))
  }

  private onPasswordChange = (password: string) => {
    const { dispatch } = this.props
    dispatch(setSignUpPasswordAction(password))
  }

  private onRepeatEmailChange = (repeatEmail: string) => {
    this.setState({ repeatEmail })
  }

  private onRepeatPasswordChange = (repeatPassword: string) => {
    this.setState({ repeatPassword })
  }

  private goToStep2 = () => {
    this.props.history.push(urls.signUpStep2)
  }

  private goToSignIn = () => {
    this.props.history.push(urls.signIn)
  }
}

const mapStateToProps = ({ signUp }: IStoreState) => ({
  email: signUp.email,
  password: signUp.password
})

export default withRouter(connect(mapStateToProps)(SignUpStep1))
