import styled from 'styled-components'

export default styled.section`
  padding: 30px 0;
  box-sizing: border-box;

  .options {
    margin-bottom: 20px;

    h3, .card {
      padding: 0 50px;
      font-size: initial !important;
      margin-bottom: 10px;
    }
  }

  .no-options {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    h3, p {
      margin: 0;
    }

    img {
      margin-bottom: 20px;
    }

    button {
      margin-top: auto;
      width: 180px;
    }
  }
`
