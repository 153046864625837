import React, { ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { Button, Page, Spinner } from '../../../components'
import { images } from '../../../images'
import { AuthenticatedRoutable, IReservation } from '../../../models'
import { urls } from '../../../routing'
import { LiteralsService } from '../../../services'
import {
  fetchReservationsAction,
  resetNewReservationFieldsAction,
  setSelectedReservationModalAction
} from '../../../store/actions'
import { IStoreState } from '../../../store/states'
import { Card } from './Card'
import StyledSection from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  upcomingReservations: IReservation[]
  isFetchingReservations: boolean
  isFetchingMoreReservations: boolean
  isLoadMoreExist: boolean
  page: number
}

class Reservations extends AuthenticatedRoutable<IProps> {
  private get emptyReservations(): ReactNode {
    return (
      <div className="empty-reservations">
        <img src={ images['noReservations'] } alt={ LiteralsService.get('noReservations') }/>
        <h3>{ LiteralsService.get('noReservations', true) }</h3>
        <p>{ LiteralsService.get('noReservationsExplanation', true) }</p>
      </div>
    )
  }

  private get reservationsContent(): ReactNode {
    const { upcomingReservations, isFetchingReservations, isFetchingMoreReservations } = this.props

    if (isFetchingReservations || isFetchingMoreReservations) {
      return (
        <article className="spinner-container">
          <Spinner />
        </article>
      )
    } else {
      if (!upcomingReservations.length) return this.emptyReservations
      return upcomingReservations.map((reservation, i) => (
        <Card
          key={ i }
          reservation={ reservation }
          onClick={ () => this.goToReservationDetails(reservation) }
          onFavoriteClick={ () => this.handleOpenFavoriteModal(reservation) }
        />
      ))
    }
  }

  public componentDidMount(): void {
    const { dispatch } = this.props
    super.componentDidMount()
    dispatch(resetNewReservationFieldsAction())
    dispatch(fetchReservationsAction())
  }

  public render(): ReactNode {
    const { history } = this.props

    return (
      <Page className="authenticated">
        <StyledSection>
          <nav className="tabs">
            <div className="active">
              <span>{ LiteralsService.get('nextReservations', true) }</span>
            </div>
            <div className="divider" />
            <div onClick={ () => history.push(urls.pastReservations) }>
              <span>{ LiteralsService.get('pastReservations', true) }</span>
            </div>
          </nav>
          <div className="reservations">
            { this.reservationsContent }
          </div>
        </StyledSection>
        <Button
          title={ LiteralsService.get('newReservation') }
          icon="plusWhite"
          onPress={ this.goToNewReservation }
          className="floating-button"
          filled
          upper
        />
      </Page>
    )
  }

  private goToNewReservation = () => {
    this.props.history.replace(urls.newReservationBusStopsStep)
  }

  private goToReservationDetails = (reservation: IReservation) => {
    this.props.history.push(
      `${ urls.reservationDetails }/${ reservation.id }`,
      { id: reservation.id, cancelled: reservation.cancelled }
    )
  }

  private handleOpenFavoriteModal = (reservation?: IReservation) => {
    this.props.dispatch(setSelectedReservationModalAction(reservation ?? null))
  }
}

const mapStateToProps = ({ reservations }: IStoreState) => ({
  upcomingReservations: reservations.upComing,
  isFetchingReservations: reservations.isFetchingReservations,
  isFetchingMoreReservations: reservations.isFetchingMoreReservations,
  isLoadMoreExist: reservations.isLoadMoreExist,
  page: reservations.page
})

export default withRouter(connect(mapStateToProps)(Reservations))
