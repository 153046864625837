export enum ConfigurationTypeKeys {
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
  FETCH_AREAS = 'FETCH_AREAS',
  FETCHING_AREAS = 'FETCHING_AREAS',
  FETCH_AREAS_SUCCESSFUL = 'FETCH_AREAS_SUCCESSFUL',
  SET_SELECTED_AREA = 'SET_SELECTED_AREA'
}

export interface IChangeLanguageAction {
  type: typeof ConfigurationTypeKeys.CHANGE_LANGUAGE
  payload: string
}

export interface IFetchAreasAction {
  type: typeof ConfigurationTypeKeys.FETCH_AREAS
}

export interface ISetIsFetchingAreasAction {
  type: typeof ConfigurationTypeKeys.FETCHING_AREAS
}

export interface IFetchAreasSuccessfulAction {
  type: typeof ConfigurationTypeKeys.FETCH_AREAS_SUCCESSFUL
  payload: any[]
}

export interface ISetSelectedAreaAction {
  type: typeof ConfigurationTypeKeys.SET_SELECTED_AREA
  payload: number
}

export type ConfigurationActionTypes =
  | IChangeLanguageAction
  | IFetchAreasAction
  | ISetIsFetchingAreasAction
  | IFetchAreasSuccessfulAction
  | ISetSelectedAreaAction
