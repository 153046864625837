import moment from 'moment'
import React, { CSSProperties, ReactNode } from 'react'
import { images } from '../../../../images'
import { IAvailability, IStop } from '../../../../models'
import { LiteralsService } from '../../../../services'
import theme from '../../../../theme'
import StyledDiv from './styles'

interface IProps {
  routeOption: IAvailability
  onClick: (ro: IAvailability) => void
  isLast: boolean
  pickedOriginBusStop?: IStop
  pickedDestinationBusStop?: IStop
}

export const Card: React.FC<IProps> = ({
  routeOption, onClick, isLast, pickedOriginBusStop, pickedDestinationBusStop
}) => {
  const cardStyles = (): CSSProperties => {
    if (!isLast) {
      return {
        borderBottomColor: theme.colors.greyLight,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid'
      }
    }
    return {}
  }

  const timeText = (): string => {
    const departureTime = moment(routeOption.inStop.dateTime).format('HH:mm')
    const arrivalTime = moment(routeOption.outStops[0].dateTime).format('HH:mm')

    // eslint-disable-next-line max-len
    return `${ LiteralsService.get('departure', true) } ${ departureTime } - ${ LiteralsService.get('arrival', true) } ${ arrivalTime }`
  }

  const isBeginingStopUnavailable = (): boolean => {
    const isBeginingStopDisabled = pickedOriginBusStop
      ? routeOption.inStop.name !== pickedOriginBusStop.name
      : false

    return routeOption.alternative && isBeginingStopDisabled
  }

  const isDestinationStopUnavailable = (): boolean => {
    const isDestinationStopDisabled = pickedDestinationBusStop
      ? routeOption.outStops[0].name !== pickedDestinationBusStop.name
      : false

    return routeOption.alternative && isDestinationStopDisabled
  }

  const routeIcons = (): ReactNode => {
    if (!routeOption.alternative) {
      return <img src={ images['bus'] }  alt="bus" style={ { width: '20px', height: '20px' } } />
    }

    if (isBeginingStopUnavailable() && !isDestinationStopUnavailable()) {
      return (
        <>
          <img src={ images['pedestrian'] } alt="pedestrian" style={ { width: '20px', height: '20px' } } />
          <span className="dotted">...</span>
          <img src={ images['bus'] } alt="bus" style={ { width: '20px', height: '20px' } } />
        </>
      )
    } else if (!isBeginingStopUnavailable() && isDestinationStopUnavailable()) {
      return (
        <>
          <img src={ images['bus'] } alt="bus" style={ { width: '20px', height: '20px' } } />
          <span className="dotted">...</span>
          <img src={ images['pedestrian'] } alt="pedestrian" style={ { width: '20px', height: '20px' } } />
        </>
      )
    } else {
      return (
        <>
          <img src={ images['pedestrian'] } alt="pedestrian" style={ { width: '20px', height: '20px' } } />
          <span className="dotted">...</span>
          <img src={ images['bus'] } alt="bus" style={ { width: '20px', height: '20px' } } />
        </>
      )
    }

  }

  return (
    <StyledDiv className="card" onClick={ () => onClick(routeOption) } style={ cardStyles() }>
      <div className="details">
        <div className="row">
          { routeIcons() }
          <span>
            { routeOption.inStop.name }
          </span>
        </div>
        <div className="row" style={{ marginTop: '8px' }}>
          <span>{ timeText() }</span>
        </div>
      </div>
      <div className="details">
        <img src={ images['arrowRight'] } alt="Go" style={{ width: '20px', height: '20px' }}/>
      </div>
    </StyledDiv>
  )
}
