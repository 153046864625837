import { IAvailability } from '../../../models'
import {
  IFetchEearlierRouteOptionsAction,
  IFetchLaterRouteOptionsAction,
  IFetchRouteOptionsAction,
  IFetchRouteOptionsSuccessfulAction,
  ISetAvailabilityAction,
  ISetIsFetchingRouteOptionsAction,
  RouteOptionStepTypeKeys
} from '../../types'

export function fetchRouteOptionsAction(): IFetchRouteOptionsAction {
  return { type: RouteOptionStepTypeKeys.FETCH_ROUTE_OPTIONS }
}

export function fetchEarlierRouteOptionsAction(): IFetchEearlierRouteOptionsAction {
  return { type: RouteOptionStepTypeKeys.FETCH_EARLIER_ROUTE_OPTIONS }
}

export function fetchLaterRouteOptionsAction(): IFetchLaterRouteOptionsAction {
  return { type: RouteOptionStepTypeKeys.FETCH_LATER_ROUTE_OPTIONS }
}

export function setIsFetchingRouteOptionsAction(): ISetIsFetchingRouteOptionsAction {
  return { type: RouteOptionStepTypeKeys.FETCHING_ROUTE_OPTIONS }
}

export function fetchRouteOptionsSuccessfulAction(options: IAvailability[]): IFetchRouteOptionsSuccessfulAction {
  return { type: RouteOptionStepTypeKeys.FETCH_ROUTE_OPTIONS_SUCCESSFUL, payload: options }
}

export function setDestinationAvailabilityAction(availabilty: IAvailability): ISetAvailabilityAction {
  return { type: RouteOptionStepTypeKeys.SET_AVAILABILITY, payload: availabilty }
}
