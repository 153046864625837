import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects'
import { ICognitoUserSession, IUser } from '../../models'
import { urls } from '../../routing'
import {
  AuthenticationService,
  LiteralsService,
  // ReservationService,
  UserService
} from '../../services'
import {
  changeUserLanguageAction,
  setIsLoadingUserInfoAction,
  // setIsUserExperienced,
  // setIsUserPenalizedAction,
  setNotificationAction,
  setUserInfoAction,
  signInSuccessfulAction
} from '../actions'
import { IStoreState } from '../states'
import { UserInfoTypeKeys } from '../types'

// Worker Saga
function* checkUserAsync() {
  let tokens: ICognitoUserSession | null = null
  let user: IUser | null = null
  const routerHistory = yield getContext('routerHistory')

  try {
    tokens = yield call(AuthenticationService.getTokens)
    console.log(tokens)
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.log(error)
  }

  if (tokens) {
    try {
      try {
        user = yield call(UserService.getInfo)
      } catch (error) {
        if (
          (error && error.message === '401') ||
          (error && error.message && error.message.includes(401))
        ) {
          throw error
        }
      }

      if (!user) {
        user = yield call(AuthenticationService.getUserAttributes)
      }
      if (!!tokens && !!user) {
        // INFO : Disabled penalties and experienced features on session check
        // let penalties = []

        // try {
        //   penalties = yield call(ReservationService.getFaultReservations)
        // } catch (error) {
        //   // tslint:disable-next-line: no-console
        //   console.log(error)
        // }

        // if (user.id) {
        //   try {
        //     const token = yield call(
        //       AuthenticationService.checkPermissionAndGetToken
        //     )

        //     yield call(UserService.sendDeviceToken, user.id, token)
        //   } catch (error) {
        //     // tslint:disable-next-line: no-console
        //     console.log(error)
        //   }
        // }

        const { language } = yield select((storeState: IStoreState) => storeState.configuration)
        if (user.id && user.locale !== language) {
           yield call(UserService.updateLanguage, user.id, language)
        }

        yield put(setUserInfoAction(user))
        // const isExperiended = yield call(UserService.getIsUserExperienced)
        // yield put(setIsUserExperienced(isExperiended))

        // const { locale } = yield select((state: IStoreState) => state.userInfo)
        // LiteralsService.setLanguage(locale)
        // if (penalties && penalties.length && penalties.length === 3) {
        //   yield put(setIsUserPenalizedAction())
        //   yield call(routerHistory.push, urls.penalties)
        // } else {
        const { isShowNotificationModal } = yield select((storeState: IStoreState) => storeState.reservations)
        if (!isShowNotificationModal) yield call(routerHistory.push, urls.reservations)
        // }
      } else {
        yield call(routerHistory.replace, urls.signIn)
      }
    } catch (error) {
      if (
        (error && error.message === '401') ||
        (error && error.message && error.message.includes(401))
      ) {
        yield call(routerHistory.replace, urls.blocked)
      }
    }
  } else {
    yield call(routerHistory.replace, urls.signIn)
  }

  yield put(signInSuccessfulAction())
}

function* saveUserInfoAsync() {
  const routerHistory = yield getContext('routerHistory')

  try {
    yield put(setIsLoadingUserInfoAction(true))
    const userInfo = yield select((state: IStoreState) => state.userInfo)
    yield call(UserService.updateInfo, userInfo)
    yield call(routerHistory.push, urls.configurationDisplay)
  } catch (error) {
    yield put(
      setNotificationAction({
        isError: true,
        label: LiteralsService.get('serverError', true)
      })
    )
  } finally {
    yield put(setIsLoadingUserInfoAction(false))
  }
}

function* saveUserLanguageAsync(action: any) {
  const routerHistory = yield getContext('routerHistory')

  try {
    yield put(setIsLoadingUserInfoAction(true))
    yield put(changeUserLanguageAction(action.payload))
    const { id } = yield select((state: IStoreState) => state.userInfo)
    yield call(UserService.updateLanguage, id, action.payload)
    yield call(routerHistory.push, urls.splash)
  } catch (error) {
    yield put(
      setNotificationAction({
        isError: true,
        label: LiteralsService.get('serverError', true)
      })
    )
  } finally {
    yield put(setIsLoadingUserInfoAction(false))
  }
}

// Watcher Saga:
function* watchCheckUserAsync() {
  yield takeLatest(UserInfoTypeKeys.CHECK_USER, checkUserAsync)
}

function* watchSaveUserInfoAsyc() {
  yield takeLatest(UserInfoTypeKeys.SAVE_USER_INFO, saveUserInfoAsync)
}

function* watchSaveLanguageAsyc() {
  yield takeLatest(UserInfoTypeKeys.SAVE_USER_LANGUAGE, saveUserLanguageAsync)
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([watchCheckUserAsync(), watchSaveUserInfoAsyc(), watchSaveLanguageAsyc()])
}
