import styled from 'styled-components'

export default styled.section`
  display: flex;
  flex-direction: column;
  position: absolute;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(104, 116, 148, 0.2);
  max-height: 245px;
  overflow-Y: scroll;
  overflow-x: hidden;
  width: 100px;
  position: absolute;
  bottom: 44px;
  left: 0;
  background-color: ${ ({ theme }) => theme.colors.white };

  li {
    padding: 8px 10px;

    &:hover {
      background-color: ${ ({ theme }) => theme.colors.primary }66;
    }

    &.selected {
      background-color: ${ ({ theme }) => theme.colors.primary };
      color: ${ ({ theme }) => theme.colors.white };
    }
  }
`
