import {
  IClearSignInErrorAction,
  IClearSignInFieldsAction,
  ISetIsSigningInAction,
  ISetSignInPasswordAction,
  ISetSignInUsernameAction,
  ISignInAction,
  ISignInFailedAction,
  ISignInNewPasswordRequiredAction,
  ISignInSuccessfulAction,
  ISignOutAction,
  ISignOutWithourUserCredentialsAction,
  SignInTypeKeys
} from '../types'

export function setSignInUsernameAction(username: string): ISetSignInUsernameAction {
  return { type: SignInTypeKeys.SET_SIGN_IN_USERNAME, payload: username }
}

export function setSignInPasswordAction(password: string): ISetSignInPasswordAction {
  return { type: SignInTypeKeys.SET_SIGN_IN_PASSWORD, payload: password }
}

export function signInAction(): ISignInAction {
  return { type: SignInTypeKeys.SIGN_IN }
}

export function setIsSigningInAction(): ISetIsSigningInAction {
  return { type: SignInTypeKeys.SIGNING_IN }
}

export function signInSuccessfulAction(): ISignInSuccessfulAction {
  return { type: SignInTypeKeys.SIGN_IN_SUCCESSFUL }
}

export function signInFailedAction(payload: any): ISignInFailedAction {
  return { type: SignInTypeKeys.SIGN_IN_FAILED, payload }
}

export function signOutAction(): ISignOutAction {
  return { type: SignInTypeKeys.SIGN_OUT }
}

export function signOutWithourUserCredentialsAction(): ISignOutWithourUserCredentialsAction {
  return { type: SignInTypeKeys.SIGN_OUT_WITHOUT_USER_CREDENTIALS }
}

export function clearSignInFieldsAction(): IClearSignInFieldsAction {
  return { type: SignInTypeKeys.CLEAR_SIGN_IN_FIELDS }
}

export function signInNewPasswordRequiredAction(required: boolean): ISignInNewPasswordRequiredAction {
  return { type: SignInTypeKeys.SIGN_IN_NEW_PASSWORD_REQUIRED, payload: required }
}

export function clearSignInErrorAction(): IClearSignInErrorAction {
  return { type: SignInTypeKeys.CLEAR_SIGN_IN_ERROR }
}
