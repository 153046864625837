import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  cursor: pointer;
  
  &:hover {
    background-color: ${ ({ theme }) => theme.colors.greyLight };
  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      span {
        font-family: ${ ({ theme }) => theme.fontFamily };
      }

      &:first-of-type {
        > span {
          font-size: 18px; 
          margin-left: 10px;
          align-self: center;
        }
      }

      &:last-of-type {
        > span {
          font-size: 14px; 
          color: ${ ({ theme }) => theme.colors.black };
          text-align: left;
        }
      }
    } 
  }

  .dotted {
    position: relative;
    bottom: 5px;
    margin: 0 4px;
    font-size: 24px;
    font-weight: bold;
    color: ${ ({ theme }) => theme.colors.black };
  }
`
