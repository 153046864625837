import React, { PureComponent, ReactNode } from 'react'
import { Provider } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Store } from 'redux'
import configureStore from '../../store'

type Props = RouteComponentProps

class ProviderWithRouter extends PureComponent<Props> {
  private store: Store

  constructor(props: Props) {
    super(props)
    this.store = configureStore({}, { routerHistory: props.history })
  }

  public render(): ReactNode {
    return (
      <Provider store={ this.store }>
        { this.props.children }
      </Provider>
    )
  }
}

export default withRouter(ProviderWithRouter)
