import { DateTime } from 'luxon'
import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { Button, Modal, Spinner, ValidatedInput } from '../../../components'
import { IFavorite, IReservation } from '../../../models'
import { LiteralsService } from '../../../services'
import { addFavoriteAction, setSelectedReservationModalAction } from '../../../store/actions'
import { IStoreState } from '../../../store/states'

interface IProps extends DispatchProp<AnyAction> {
  isLoading: boolean
  selected: IReservation | null
}

interface IState {
  name: string
}

class FavoriteModal extends PureComponent<IProps, IState> {
  private get modalContent(): ReactNode {
    const { isLoading } = this.props
    const { name } = this.state

    if (isLoading) {
      return (
        <article className="spinner-container">
          <Spinner />
        </article>
      )
    }
    return (
      <>
        <header>
          <h3>
          { LiteralsService.get('putName', true) }:
          </h3>
        </header>
        <section className="content" style={{ marginTop: '20px' }}>
          <ValidatedInput
            name="favorite-name"
            value={ name }
            label={ LiteralsService.get('name', true) }
            onChange={ _name => this.setState({ name: _name }) }
            styles={{ marginBottom: 0 }}
          />
        </section>
        <footer>
          <Button
            onPress={ this.handleClick }
            title={ LiteralsService.get('save', true) }
            styles={{ width: '155px' }}
            filled
          />
          <Button
            onPress={ this.handleClose }
            title={ LiteralsService.get('cancel', true) }
            styles={{ width: '155px' }}
          />
        </footer>
      </>
    )
  }

  constructor(props: IProps) {
    super(props)

    this.state = {
      name: ''
    }
  }

  public render(): ReactNode {
    const { selected } = this.props

    if (!selected) return null
    return (
      <Modal className="small" isHidden={ false } onOverlayClick={ this.handleClose }>
        { this.modalContent }
      </Modal>
    )
  }

  private handleClick = (): void => {
    const { name } = this.state
    const { selected, dispatch } = this.props
    const hour = selected?.beginningStop.dateTime
    const time = DateTime.fromSQL(hour ?? '').toLocaleString(DateTime.TIME_SIMPLE)

    const favorite: IFavorite = {
      id: 0,
      name,
      service: {
        id: selected?.service.id || 0
      },
      inStop: {
        id: selected?.beginningStop.id || 0
      },
      exitStop: {
        id: selected?.seats[0].exitStop.id || 0
      },
      time
    }

    this.setState({ name: '' })
    dispatch(addFavoriteAction(favorite))
  }

  private handleClose = (): void => {
    this.setState({ name: '' })
    this.props.dispatch(setSelectedReservationModalAction(null))
  }
}

const mapStateToProps = ({ favorites }: IStoreState) => ({
  isLoading: favorites.isLoadingFavorite,
  selected: favorites.selectedReservation
})

export default connect(mapStateToProps)(FavoriteModal)
