import { all, call, put, select, takeLatest, getContext } from 'redux-saga/effects'
import { formatPointToLocation, getPosition, makePointOnMap } from '../../helpers'
import { ISearchedType, IService, IStop } from '../../models'
import { urls } from '../../routing'
import {
  // GoogleMapsService,
  LiteralsService,
  ReservationService,
  ServicesService,
  TownService
} from '../../services'
import {
  confirmNewReservationSuccessfulAction,
  fetchDestinationBusStopsAction,
  fetchDestinationBusStopsSuccesfulAction,
  fetchReservationsAction,
  fetchRouteOptionsSuccessfulAction,
  fetchServicesSuccessfulAction,
  resetNewReservationFieldsAction,
  setBusStopAction,
  setIsConfirmingNewReservationAction,
  setIsFetchingDestinationBusStopsAction,
  setIsFetchingRouteOptionsAction,
  setIsFetchingServicesAction,
  setIsSeachingAction,
  setNotificationAction,
  setSearchedStopResultAction,
  setSearchStopsAction
} from '../actions'
import { IStoreState } from '../states'
import { BusStopsStepTypeKeys, ConfirmStepTypeKeys, RouteOptionStepTypeKeys } from '../types'

// Worker Saga
function* fetchServicesAsync() {
  try {
    yield put(setIsFetchingServicesAction())
    const isEdit = yield select(
      (state: IStoreState) => state.newReservation.isEdit
    )

    let services
    if (!isEdit) {
      const town = yield select((state: IStoreState) => state.userInfo.town)
      services = yield call(TownService.getServices, town.id)
    } else {
      const pickedService = yield select(
        (state: IStoreState) => state.newReservation.pickedService
      )
      services = yield call(
        ServicesService.getServicesByServiceId,
        pickedService
      )

      const pickedOriginBusStop = yield select(
        (state: IStoreState) => state.newReservation.pickedOriginBusStop
      )
      yield put(
        fetchDestinationBusStopsAction({
          idService: pickedService,
          stop: pickedOriginBusStop
        })
      )
    }
    yield put(fetchServicesSuccessfulAction(services))
  } catch (error) {
    yield put(
      setNotificationAction({
        isError: true,
        label: LiteralsService.get('serverError', true)
      })
    )
  }
}

function* fetchStopSearchAsync(action: any) {
  let stopServices = []
  // TODO: Return to call this when correct google api key
  // const stops = yield call(GoogleMapsService.getPlaces, action.payload);
  const services = yield select((state: IStoreState) => state.newReservation.services)
  const destinationStops: IStop[] = yield select ((state: IStoreState) => state.newReservation.destinationBusStops)
  if (action.payload && services && services.length > 0) {
    stopServices = services
      .map((s: IService) =>
        s.stops
          .filter((st: IStop) => {
            const stopNameExists = st.name.toUpperCase().includes(action.payload.toUpperCase())
            const isDestinationEnabled = destinationStops.some(stop => stop.id === st.id)

            if (destinationStops.length) return isDestinationEnabled && stopNameExists
            return stopNameExists
          })
          .map(st => ({ ...st, serviceId: s.id }))
      )
      .reduce((a: IStop[], b: IStop[]) => a.concat(b))
  }

  yield put(setSearchStopsAction(stopServices))
}

function* fetchStopSearchLocationAsync(action: any) {
  const pickedOriginBusStop = yield select(
    (state: IStoreState) => state.newReservation.pickedOriginBusStop
  )
  let coordinates
  let response
  let mapCoordinates

  switch (action.payload.type) {
    case ISearchedType.location:
      response = yield call(getPosition)
      // coordinates = response.coords
      coordinates = formatPointToLocation(response.coords)
      break
    // case ISearchedType.google:
    //   coordinates = yield call(
    //     GoogleMapsService.getCoordinatesPlace,
    //     action.payload.id
    //   )
    //   break
    case ISearchedType.stop:
      mapCoordinates = makePointOnMap(
        action.payload.location.geometry.coordinates,
        0
      )
      coordinates = formatPointToLocation(mapCoordinates)
      yield put(setBusStopAction({ idService: action.payload.serviceId, stop: action.payload }))
      if (!pickedOriginBusStop) {
        yield put(fetchDestinationBusStopsAction({ idService: action.payload.serviceId, stop: action.payload }))
      }
      break
    default:
      coordinates = null
      break
  }

  yield put(setIsSeachingAction(false))
  yield put(
    setSearchedStopResultAction({
      name: action.payload.name,
      type: action.payload.type,
      coordinates
    })
  )
}

function* fetchDestinationBusStopsAsync(action: any) {
  let stops = []
  try {
    yield put(setIsFetchingDestinationBusStopsAction())
    stops = yield call(
      ServicesService.getDestinationBusStops,
      action.payload.idService,
      action.payload.stop.id
    )
  } catch (error) {
    yield put(
      setNotificationAction({
        isError: true,
        label: LiteralsService.get('serverError', true)
      })
    )
  }
  yield put(fetchDestinationBusStopsSuccesfulAction(stops))
}

function* fetchRouteOptionsAsync() {
  let options = []
  const routerHistory = yield getContext('routerHistory')

  yield put(setIsFetchingRouteOptionsAction())
  const {
    pickedService,
    pickedDate,
    pickedSeats,
    pickedPrmSeats,
    specificDate
  } = yield select((state: IStoreState) => state.newReservation)

  const originStopId = yield select(
    (state: IStoreState) =>
      state.newReservation.pickedOriginBusStop &&
      state.newReservation.pickedOriginBusStop.id
  )
  const destinationStopId = yield select(
    (state: IStoreState) =>
      state.newReservation.pickedDestinationBusStop &&
      state.newReservation.pickedDestinationBusStop.id
  )

  try {
    options = yield call(
      ServicesService.getAvailabilities,
      pickedService,
      originStopId,
      destinationStopId,
      pickedSeats,
      pickedPrmSeats,
      pickedDate,
      specificDate
    )
  } catch (error) {
    yield put(
      setNotificationAction({
        isError: true,
        label: LiteralsService.get('serverError', true)
      })
    )
  }
  yield put(fetchRouteOptionsSuccessfulAction(options))
  yield call(routerHistory.push, urls.newReservationRouteOptionsStep)
}

function* fetchEarlierRouteOptionsAsync() {
  let options = []
  yield put(setIsFetchingRouteOptionsAction())

  const {
    pickedService,
    pickedDate,
    pickedSeats,
    pickedPrmSeats,
    specificDate
  } = yield select((state: IStoreState) => state.newReservation)

  const originStopId = yield select(
    (state: IStoreState) =>
      state.newReservation.pickedOriginBusStop &&
      state.newReservation.pickedOriginBusStop.id
  )
  const destinationStopId = yield select(
    (state: IStoreState) =>
      state.newReservation.pickedDestinationBusStop &&
      state.newReservation.pickedDestinationBusStop.id
  )
  const availabilityId = yield select(
    (state: IStoreState) => state.newReservation.routeOptions[0].id
  )

  try {
    options = yield call(
      ServicesService.getAvailabilitiesEarlier,
      pickedService,
      originStopId,
      destinationStopId,
      pickedSeats,
      pickedPrmSeats,
      pickedDate,
      availabilityId,
      specificDate
    )
  } catch (error) {
    yield put(
      setNotificationAction({
        isError: true,
        label: LiteralsService.get('serverError', true)
      })
    )
  }
  yield put(fetchRouteOptionsSuccessfulAction(options))
}

function* fetchLaterRouteOptionsAsync() {
  let options = []
  yield put(setIsFetchingRouteOptionsAction())

  const {
    pickedService,
    pickedDate,
    pickedSeats,
    pickedPrmSeats,
    specificDate
  } = yield select((state: IStoreState) => state.newReservation)

  const originStopId = yield select(
    (state: IStoreState) =>
      state.newReservation.pickedOriginBusStop &&
      state.newReservation.pickedOriginBusStop.id
  )
  const destinationStopId = yield select(
    (state: IStoreState) =>
      state.newReservation.pickedDestinationBusStop &&
      state.newReservation.pickedDestinationBusStop.id
  )
  const availabilityId = yield select(
    (state: IStoreState) => state.newReservation.routeOptions[0].id
  )

  try {
    options = yield call(
      ServicesService.getAvailabilitiesLater,
      pickedService,
      originStopId,
      destinationStopId,
      pickedSeats,
      pickedPrmSeats,
      pickedDate,
      availabilityId,
      specificDate
    )
  } catch (error) {
    yield put(
      setNotificationAction({
        isError: true,
        label: LiteralsService.get('serverError', true)
      })
    )
  }

  yield put(fetchRouteOptionsSuccessfulAction(options))
}

function* confirmNewReservationAsync() {
  const routerHistory = yield getContext('routerHistory')
  yield put(setIsConfirmingNewReservationAction())

  const {
    isEdit,
    pickedAvailability,
    pickedReservationId,
    pickedSeats,
    pickedPrmSeats
  } = yield select((state: IStoreState) => state.newReservation)

  try {
    if (!isEdit) {
      yield call(
        ReservationService.postReservation,
        pickedAvailability ?? false,
        pickedSeats,
        pickedPrmSeats
      )
    } else {
      yield call(
        ReservationService.updateReservation,
        pickedReservationId ?? false,
        pickedAvailability ?? false,
        pickedSeats,
        pickedPrmSeats
      )
    }

    yield put(
      setNotificationAction({
        isError: false,
        label: LiteralsService.get('bookConfirmed', true)
      })
    )

    yield call(routerHistory.push, urls.newReservationBusStopsStep)
    if (isEdit) {
      yield call(routerHistory.push, urls.reservations)
    } else {
      yield call(routerHistory.push, urls.reservations)
    }

    yield put(resetNewReservationFieldsAction())
    yield put(fetchReservationsAction())
  } catch (error) {
    yield put(
      setNotificationAction({
        isError: true,
        label: LiteralsService.get(
          error && error.message && error.message.includes(409)
            ? 'duplicatedReservation'
            : error && error.message && error.message.includes(406)
            ? 'maxCapacity'
            : error && error.message && error.message.includes(412)
            ? 'preconditionFailed'
            : error && error.message && error.message.includes(422)
            ? 'infiesibleReservation'
            : 'bookFailed',
          true
        )
      })
    )
  }

  yield put(confirmNewReservationSuccessfulAction())
}

// Watcher Saga:
function* watchFetchServicesAsync() {
  yield takeLatest(BusStopsStepTypeKeys.FETCH_SERVICES, fetchServicesAsync)
}

function* watchFetchStopSearchAsync() {
  yield takeLatest(BusStopsStepTypeKeys.SEARCH_STOPS, fetchStopSearchAsync)
}

function* watchFetchStopSearchLocationAsync() {
  yield takeLatest(BusStopsStepTypeKeys.SEARCH_STOP_COORDINATES, fetchStopSearchLocationAsync)
}

function* watchFetchDestinationBusStopsAsync() {
  yield takeLatest(BusStopsStepTypeKeys.FETCH_DESTINATION_BUS_STOPS, fetchDestinationBusStopsAsync)
}

function* watchFetchRouteOptionsAsync() {
  yield takeLatest(RouteOptionStepTypeKeys.FETCH_ROUTE_OPTIONS, fetchRouteOptionsAsync)
}

function* watchFetchEarlierRouteOptionsAsync() {
  yield takeLatest(RouteOptionStepTypeKeys.FETCH_EARLIER_ROUTE_OPTIONS, fetchEarlierRouteOptionsAsync)
}

function* watchFetchLaterRouteOptionsAsync() {
  yield takeLatest(RouteOptionStepTypeKeys.FETCH_LATER_ROUTE_OPTIONS, fetchLaterRouteOptionsAsync)
}

function* watchConfirmNewReservationAsync() {
  yield takeLatest(ConfirmStepTypeKeys.CONFIRM_NEW_RESERVATION, confirmNewReservationAsync)
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    watchFetchServicesAsync(),
    watchFetchStopSearchAsync(),
    watchFetchStopSearchLocationAsync(),
    watchFetchDestinationBusStopsAsync(),
    watchFetchRouteOptionsAsync(),
    watchFetchEarlierRouteOptionsAsync(),
    watchFetchLaterRouteOptionsAsync(),
    watchConfirmNewReservationAsync()
  ])
}
