import React from 'react'
import Styled from './styles'

interface IProps {
  className?: string
  isHidden?: boolean
}

export const Dropdown: React.FC<IProps> = ({ className, isHidden, children }) => {
  if (isHidden) return null

  return <Styled className={ className || '' } >{ children }</Styled>
}
