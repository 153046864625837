import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { Button, Page, Spinner, Title, ValidatedInput } from '../../../components'
import { isEmailValid, isVerificationCodeValid } from '../../../helpers'
import { images } from '../../../images'
import { urls } from '../../../routing'
import { LiteralsService } from '../../../services'
import {
  confirmVerificationCodeAction,
  setVerificationCodeAction,
  setVerificationEmailAction
} from '../../../store/actions'
import { IStoreState } from '../../../store/states'
import StyledArticle from '../styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  dni: string
  email: string
  code: string
  isVerificating: boolean
}

class AccountVerificationStep extends PureComponent<IProps> {
  private get buttonIsDisabled(): boolean {
    const { email, code, isVerificating } = this.props

    return !isEmailValid(email) || !isVerificationCodeValid(code) || isVerificating
  }

  private get verificationButton(): ReactNode {
    const { isVerificating } = this.props
    if (isVerificating) return <Spinner />
    return (
      <Button
        title={ LiteralsService.get('continue') }
        onPress={ this.handleValidatedButtonPress }
        filled
        upper
        isDisabled={ this.buttonIsDisabled }
      />
    )
  }

  public render(): ReactNode {
    const { email, code } = this.props

    return (
      <Page>
        <StyledArticle>
          <header>
            <img src={ images['logo'] } alt="AMB:Bus Metropolità"/>
          </header>
          <form onSubmit={ e => e.preventDefault() }>
            <Title text={ LiteralsService.get('verifyYourEmail', true) } />
            <section>
              <ValidatedInput
                name="email"
                label={ LiteralsService.get('email', true) }
                value={ email }
                onChange={ this.onEmailChange }
                error={ !isEmailValid(email) }
              />
              <ValidatedInput
                name="code"
                label={ LiteralsService.get('verificationCode', true) }
                value={ code }
                onChange={ this.onCodeChange }
                error={ !isVerificationCodeValid(code) }
              />
            </section>
            <section className="actions">
              { this.verificationButton }
              <div className="link">
                <span onClick={ this.goToNewConfirmationCode }>
                  { LiteralsService.get('requestNewSignUpVerificationCode', true) }
                </span>
              </div>
            </section>
          </form>
        </StyledArticle>
      </Page>
    )
  }

  private onEmailChange = (dni: string) => {
    const { dispatch } = this.props
    dispatch(setVerificationEmailAction(dni))
  }

  private onCodeChange = (code: string) => {
    const { dispatch } = this.props
    dispatch(setVerificationCodeAction(code))
  }

  private handleValidatedButtonPress = () => {
    const { dispatch } = this.props
    dispatch(confirmVerificationCodeAction())
  }

  private goToNewConfirmationCode = () => {
    this.props.history.push(urls.accountVerificationResend)
  }
}

const mapStateToProps = ({ signUp, verification }: IStoreState) => ({
  dni: verification.dni ? verification.dni : signUp.dni,
  email: verification.email ? verification.email : signUp.email,
  code: verification.code,
  isVerificating: verification.isVerificating
})

export default withRouter(connect(mapStateToProps)(AccountVerificationStep))
