import { IService, ITown } from '../../models'
import { BaseHttpService } from './base'

class TownService extends BaseHttpService {
  public getTowns = async (): Promise<ITown[]> => {
    return this.get('towns')
  }

  public getServices = async (townId: number): Promise<IService[]> => {
    return this.get(`towns/${townId}/services`)
  }
}

export default new TownService()
