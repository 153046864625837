import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
// import { LiteralsService } from '../../services'
import { setNotificationAction } from '../../store/actions'
import { IStoreState } from '../../store/states'
import { Notification } from '../../components'

interface IProps extends DispatchProp<AnyAction> {
  label: string
  isError: boolean
  show: boolean
}

class NotificationContainer extends PureComponent<IProps> {
  public render(): ReactNode {
    const { label, isError, show } = this.props
    if (show && label) {
      return (
        <Notification
          notification={{ label, isError }}
          resetScreenParams={this.resetScreenParams}
        />
      )
    } else return null
  }

  private resetScreenParams = (): void => {
    this.props.dispatch(setNotificationAction({
      label: '',
      isError: false
    }))
  }

  // private handleConnectivityChange = (isConnected: boolean): void => {
  //   const { dispatch } = this.props

  //   if (!isConnected) {
  //     dispatch(
  //       setNotificationAction({
  //         label: LiteralsService.get('noConnection', true),
  //         isError: true
  //       })
  //     )
  //   }
  // }
}

const mapStateToProps = ({ notification }: IStoreState) => ({
  ...notification
})

export default connect(mapStateToProps)(NotificationContainer)
