import styled from 'styled-components'

export default styled.article`
  display: flex;
  position: fixed;
  background: #0F2A4150;
  position: fixed;
  flex-direction: column;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  z-index: 100;

  .frame {
    display: flex;
    flex-direction: column;
    background: white;
    width: auto;
    border-radius: 4px;
    padding: 16px 8px;
    box-shadow: 0 4px 10px 0 rgba(66, 74, 97, 0.15);
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;
  }

  @media all and (max-width: 500px){
    &.small {
      .frame {
        width: 100% !important;
        padding: 20px !important;
      }
    }
  }
  
  &.small {
    .frame {
      width: 500px;
      padding: 40px;
      box-sizing: border-box;
      border-radius: 20px;

      .spinner-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 40px;
      }

      header{
        h3 {
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 33px;
          text-align: center;
          margin: 0 0 12px;
        }
      }

      .content {
        p {
          margin: 0;
          font-size: 18px;
          letter-spacing: 0;
          line-height: 20px;
          text-align: center;
        }
      }

      footer {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
        padding: 0;
        min-height: auto;
      }
    }
  }
`
