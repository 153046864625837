import moment from 'moment'
import React, { ReactNode } from 'react'
import { DateTime } from 'luxon'
import { MapContainer } from '../../containers'
import { images } from '../../images'
import { IAvailability, ICoordinate, IReservation, IStop } from '../../models'
import { LiteralsService } from '../../services'
import StyledSection from './styles'
import { Stops } from './Stops'

interface IProps {
  reservation?: IReservation
  availability?: IAvailability
  busLocation: ICoordinate | null
  extraInfoMessage: boolean
  onFavoriteClick?: () => void
  seats?: number
  prmSeats?: number
  aprox?: boolean
  service: number | null
  pickedOriginBusStop?: IStop
  pickedDestinationBusStop?: IStop
}

export const ReservationDetailsCard: React.FC<IProps> = ({
  reservation,
  availability,
  busLocation,
  extraInfoMessage,
  onFavoriteClick,
  seats,
  prmSeats,
  aprox,
  service,
  pickedOriginBusStop,
  pickedDestinationBusStop
}) => {
  const cardReservation = reservation ?? {
    ...availability,
    beginningStop: availability?.inStop,
    seats: availability?.outStops.map(out => ({
      exitStop: out
    })),
    service: {
      id: service
    }
  }
  const cancelledBanner = (
    <div className="cancelled-banner">
      <h4>{ LiteralsService.get('cancelled', true) }</h4>
      <p>{ LiteralsService.get('reasonsCancelled', true) }</p>
    </div>
  )

  const mapDisplay = (
    <div className="map-container" >
      { reservation?.cancelled && cancelledBanner }
      <MapContainer
        path={ reservation?.path }
        inStop={ reservation?.beginningStop ?? pickedOriginBusStop }
        outStop={ reservation?.seats[0].exitStop ?? pickedDestinationBusStop }
        busLocation={ busLocation }
        services={ [] }
        correction={ 0.0001 }
        controlsEnabled
      />
    </div>
  )

  const reservationDate = (): string => {
    const locale = LiteralsService.getLanguage()
    if (reservation?.beginningStop.dateTime) {
      const time = reservation?.beginningStop.dateTime
      const dt = DateTime.fromISO(time ?? '', { locale }).toLocaleString(DateTime.DATETIME_FULL)
      return dt
    } else {
      const time = availability?.inStop.dateTime
      const dt = DateTime.fromSQL(time ?? '', { locale }).toLocaleString(DateTime.DATETIME_FULL)
      return dt
    }
  }

  const reservationDetails = (): ReactNode => {
    const normalSeats =
      reservation && seats === undefined
        ? reservation.seats.filter(s => !s.prm).length
        : seats
    const prioritySeats =
      reservation && prmSeats === undefined
        ? reservation.seats.filter(s => s.prm).length
        : prmSeats
    const favoriteButton = onFavoriteClick
      ? (
          <button className="favorite" onClick={ onFavoriteClick }>
            <img
              src={ images[reservation?.favorite ? 'starSelected' : 'star'] }
              alt="Favorite"
              style={{ width: '20px', height: '20px' }}
            />
          </button>
        )
      : null

      return (
        <section className="top-details-container">
          <div className="top-details">
            <p className="detail-row">
              <img
                src={ images['ticket'] }
                alt="Reservation Date"
                style={{ width: '20px', height: '20px', marginRight: '10px' }}
              />
              { reservationDate() }
              { favoriteButton }
            </p>
            <p className="detail-row">
              <span className="place-text">
                {normalSeats}{' '}
                {normalSeats !== 1 ? LiteralsService.get('seats') : LiteralsService.get('seat')},
              </span>
              <span className="place-text">
                {prioritySeats}{' '}
                {prioritySeats !== 1 ? LiteralsService.get('prmSeats') : LiteralsService.get('prmSeat')}
              </span>
            </p>
          </div>
        </section>
      )
  }

  const stops = (): ReactNode => {
    return (
      <section className="stops">
        <Stops
          reservation={ cardReservation }
          aprox={ aprox }
          pickedOriginBusStop={ pickedOriginBusStop }
          pickedDestinationBusStop={ pickedDestinationBusStop }
        />
      </section>
    )
  }

  const message = (): ReactNode => {
    if (!reservation) return null
    else {
      const { minDateTime, maxDateTime } = reservation.beginningStop
      const minTime = moment(minDateTime)
      const maxTime = moment(maxDateTime)
      const time = moment.duration(maxTime.diff(minTime)).asMinutes()
      const extraInfoText = LiteralsService
        .get('hourMayUpdateWithTime', true)
        .replace('%MINUTESTOREPLACE%', Math.floor(time).toString())
      return !minDateTime || !maxDateTime || !time
        ? null
        : (
            <section className="message">
              <p>{ extraInfoText }</p>
            </section>
          )
    }
  }


  return (
    <StyledSection>
      { mapDisplay }
      <section className="details">
        { reservationDetails() }
        <div>
          { stops() }
          { extraInfoMessage && message() }
        </div>
      </section>
    </StyledSection>
  )
}
