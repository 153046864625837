import React, { CSSProperties } from 'react'
import { ImageName, images } from '../../images'
import StyledButton from './styles'

interface IProps {
  title: string
  icon?: ImageName
  filled?: boolean
  styles?: CSSProperties
  isDisabled?: boolean
  onPress?: () => any
  upper?: boolean
  className?: string
}

export const Button: React.FC<IProps> = ({ title, icon, filled, styles, isDisabled, onPress, upper, className }) => {
  const classNames = `${ className || '' } ${ filled ? 'filled' : '' }`
  const disabled = isDisabled || !onPress
  const buttonIcon = icon ? <img src={ images[icon] } className="icon" alt={ title } /> : null

  return (
    <StyledButton
      className={ classNames }
      style={ styles }
      onClick={ onPress }
      disabled={ disabled }
    >
      { buttonIcon }
      { upper ? title.toUpperCase() : title }
    </StyledButton>
  )
}
