import { IVerificationState } from '../states'
import { VerificationActionTypes, VerificationTypeKeys } from '../types'

export const initialVerificationState: IVerificationState = {
  dni: '',
  code: '',
  isVerificating: false,
  email: '',
  isSendingNewCode: false
}

const verification = (
  state: IVerificationState = initialVerificationState,
  action: VerificationActionTypes
): IVerificationState => {
  switch (action.type) {
    case VerificationTypeKeys.SET_VERIFICATION_DNI:
      return { ...state, dni: action.payload }
    case VerificationTypeKeys.SET_VERIFICATION_CODE:
      return { ...state, code: action.payload }
    case VerificationTypeKeys.CONFIRMING_VERIFICATION_CODE:
      return { ...state, isVerificating: true }
    case VerificationTypeKeys.CONFIRM_VERIFICATION_CODE_SUCCESSFUL:
      return { ...state, isVerificating: false }
    case VerificationTypeKeys.CONFIRM_VERIFICATION_CODE_FAILED:
      return { ...state, isVerificating: false }
    case VerificationTypeKeys.CLEAR_VERIFICATION_FIELDS:
      return { ...initialVerificationState }
    case VerificationTypeKeys.SET_VERIFICATION_EMAIL:
      return { ...state, email: action.payload }
    case VerificationTypeKeys.RESENDING_VERIFICATION_CODE:
      return { ...state, isVerificating: true }
    case VerificationTypeKeys.RESEND_VERIFICATION_CODE_SUCCESSFUL:
      return { ...state, isVerificating: false }
    case VerificationTypeKeys.RESEND_VERIFICATION_CODE_FAILED:
      return { ...state, isVerificating: false }
    default:
      return state
  }
}

export default verification
