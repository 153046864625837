import styled from 'styled-components'

export default styled.div`
  max-width: 250px;
  width: auto;
  height: auto;
  max-height: auto;
  background-color: ${ ({ theme }) => theme.colors.greenNotification };
  border-radius: 10px;
  overflow: hidden;
  z-index: 100;
  position: absolute;
  top: 90px;
  right: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  animation-name: appear, hide;
  animation-duration: 0.2s, 0.2s;
  animation-timing-function: ease-in-out, ease-in-out;
  animation-iteration-count: 1, 1;

  &.error {
    background-color: ${ ({ theme }) => theme.colors.red };
  }

  > span {
    display: inline-block;
    font-family: ${ ({ theme }) => theme.fontFamily };
    font-weight: 600;
    color: ${ ({ theme }) => theme.colors.white };
    text-align: center;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 6px 20px;
    font-size: 16px;
    text-align: center;
  }

  // TODO: Notification animation
  /* @keyframes appear {
    10% {
      max-height: 65px;
    }
  }
  @keyframes hide {
    100% {
      max-height: 0;
    }
  } */
`
