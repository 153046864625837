import { CognitoUserAttribute } from 'amazon-cognito-identity-js'
import { Language } from '../services/literals-service'
import { ITown } from './town'

export type ILocale = 'ca' | 'es' | 'it'

export interface IUser {
  id?: number
  email: string
  name: string
  lastName: string
  dni: string
  password?: string
  town?: ITown
  locale?: Language
}

export interface IUnsubscriptionReason {
  key: string
  label: string
}

export function cognitoUserAttributesToBodUser(
  attributes: CognitoUserAttribute[]
): IUser {
  return {
    email: getUserAttribute(attributes, 'email'),
    name: getUserAttribute(attributes, 'name'),
    lastName:
      getUserAttribute(attributes, 'family_name') ||
      getUserAttribute(attributes, 'custom:last_name'),
    dni: getUserAttribute(attributes, 'custom:dni_nie')
  }
}

export function getUserAttribute(
  attributes: CognitoUserAttribute[],
  key: string
) {
  const attribute = attributes.find(a => a.getName() === key)
  return attribute ? attribute.getValue() : ''
}
