import React, { CSSProperties, MouseEvent } from 'react'
import Styled from './styles'

interface IProps {
  isHidden?: boolean
  className?: string
  style?: CSSProperties
  onOverlayClick?: () => void
}

export const Modal: React.FC<IProps> = ({ isHidden, className, style, children, onOverlayClick }) => {
  if (isHidden) {
    document.body.style.overflow = 'visible'
    return null
  } else {
    document.body.style.overflow = 'hidden'
  }

  const handleOverlayClick = ({ currentTarget, target }: MouseEvent<HTMLDivElement>) => {
    if (!onOverlayClick || currentTarget !== target) return
    onOverlayClick()
  }

  return (
    <Styled className={ className || '' } onClick={ handleOverlayClick }>
      <section className="frame" style={ style }>{ children }</section>
    </Styled>
  )
}
