import React, { PureComponent, ReactNode } from 'react'
import { Page } from '../../components'

class Blocked extends PureComponent {
  public render(): ReactNode {
    return (
      <Page>
        Blocked
      </Page>
    )
  }
}

export default Blocked
