import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { AuthenticationService, LiteralsService } from '../../services'
import {
  changedPasswordFailedAction,
  changedPasswordSuccessfullAction,
  setIsChangingPasswordAction,
  setNotificationAction
} from '../actions'
import { IStoreState } from '../states'
import { ChangePasswordTypeKeys } from '../types'

// Worker Saga
function* changePasswordAsync() {
  try {
    yield put(setIsChangingPasswordAction())
    const { password, newPassword } = yield select(
      (state: IStoreState) => state.changePassword
    )

    yield call(AuthenticationService.changePassword, password, newPassword)

    yield put(changedPasswordSuccessfullAction())
    yield put(
      setNotificationAction({
        isError: false,
        label: LiteralsService.get('passwordChanged', true)
      })
    )
  } catch (error) {
    yield put(changedPasswordFailedAction())
    yield put(
      setNotificationAction({
        isError: true,
        label: LiteralsService.get('serverError', true)
      })
    )
  }
}

// Watcher Saga:
function* watchChangePasswordAsync() {
  yield takeLatest(ChangePasswordTypeKeys.CHANGE_PASSWORD, changePasswordAsync)
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([watchChangePasswordAsync()])
}
