import { INewPasswordRequiredState } from '../states'
import { NewPasswordRequiredActionTypes, NewPasswordRequiredTypeKeys } from '../types'

const initialState: INewPasswordRequiredState = {
  password: '',
  user: null
}

export default (
  state: INewPasswordRequiredState = initialState,
  action: NewPasswordRequiredActionTypes
): INewPasswordRequiredState => {
  switch (action.type) {
    case NewPasswordRequiredTypeKeys.RESET_FIELDS:
      return initialState
    case NewPasswordRequiredTypeKeys.SET_PASSWORD:
      return { ...state, password: action.payload }
    case NewPasswordRequiredTypeKeys.SET_USER:
      return { ...state, user: action.payload }
    default:
      return state
  }
}
