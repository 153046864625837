import { AccountVerificationResend, AccountVerificationStep } from '../pages'

export const verificationUrls = {
  accountVerificationStep: '/verification',
  accountVerificationResend: '/verification-resend'
}

export const verificationRoutes = [
  { content: AccountVerificationStep, url: verificationUrls.accountVerificationStep, isAuthenticated: false },
  { content: AccountVerificationResend, url: verificationUrls.accountVerificationResend, isAuthenticated: false }
]

export interface IAccountVerificationStepScreenParams {
  isSignUpInProcess: boolean
}
