import { IBase } from '../../models'

export enum UnsubscribeTypeKeys {
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  FETCH_UNSUBSCRIBE_REASONS = 'FETCH_UNSUBSCRIBE_REASONS',
  SET_UNSUBSCRIBE_REASONS = 'SET_UNSUBSCRIBE_REASONS',
  IS_LOADING_UNSUBSCRIBE = 'IS_LOADING_UNSUBSCRIBE'
}

export interface IUnsubscribeAction {
  type: UnsubscribeTypeKeys.UNSUBSCRIBE
  payload: string
}

export interface IFetchUnsubscribeReasonsAction {
  type: UnsubscribeTypeKeys.FETCH_UNSUBSCRIBE_REASONS
}

export interface ISetUnsubscribeReasonsAction {
  type: UnsubscribeTypeKeys.SET_UNSUBSCRIBE_REASONS
  payload: IBase[]
}

export interface ISetIsLoadingUnsubscribeAction {
  type: UnsubscribeTypeKeys.IS_LOADING_UNSUBSCRIBE
  payload: boolean
}

export type UnsubscribeActionTypes =
  | IUnsubscribeAction
  | IFetchUnsubscribeReasonsAction
  | ISetUnsubscribeReasonsAction
  | ISetIsLoadingUnsubscribeAction
