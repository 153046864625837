import {
  IBase,
  IFavoriteResponse,
  IReservation,
  ISearchedType,
  ISearchStop,
  IService,
  IStop
} from '../../../models'
import {
  BusStopsStepTypeKeys,
  IFetchDestinationBusStopsAction,
  IFetchDestinationBusStopsSuccesfulAction,
  IFetchServicesAction,
  IFetchServicesSuccessfulAction,
  IResetNewReservationFieldsAction,
  ISearchStopCoordinatesAction,
  ISearchStopsAction,
  ISetBusStopAction,
  ISetFavoriteNewReservationFieldsAction,
  ISetIsFetchingDestinationBusStopsAction,
  ISetIsFetchingServicesAction,
  ISetIsSeachingAction,
  ISetNewReservationFieldsAction,
  ISetSearchedStopResultAction,
  ISetSearchStopsAction,
  ISetSelectMarkerFlagAction
} from '../../types'

export function resetNewReservationFieldsAction(): IResetNewReservationFieldsAction {
  return { type: BusStopsStepTypeKeys.RESET_NEW_RESERVATION_FIELDS }
}

export function setNewReservationFieldsAction(reservation: IReservation): ISetNewReservationFieldsAction {
  return { type: BusStopsStepTypeKeys.SET_NEW_RESERVATION_FIELDS, payload: reservation }
}

export function setFavoriteNewReservationFieldsAction(
  favorite: IFavoriteResponse
): ISetFavoriteNewReservationFieldsAction {
  return { type: BusStopsStepTypeKeys.SET_FAVORITE_NEW_RESERVATION_FIELDS, payload: favorite }
}

export function fetchServicesAction(): IFetchServicesAction {
  return { type: BusStopsStepTypeKeys.FETCH_SERVICES }
}

export function setIsFetchingServicesAction(): ISetIsFetchingServicesAction {
  return { type: BusStopsStepTypeKeys.FETCHING_SERVICES }
}

export function fetchServicesSuccessfulAction(services: IService[]): IFetchServicesSuccessfulAction {
  return { type: BusStopsStepTypeKeys.FETCH_SERVICES_SUCCESSFUL, payload: services }
}

export function setIsSeachingAction(isSearching: boolean): ISetIsSeachingAction {
  return { type: BusStopsStepTypeKeys.SET_IS_SEARCHING, payload: isSearching }
}

export function searchStopsAction(stopNameToSearch: string): ISearchStopsAction {
  return { type: BusStopsStepTypeKeys.SEARCH_STOPS, payload: stopNameToSearch }
}

export function setSearchStopsAction(stops: IBase[]): ISetSearchStopsAction {
  return { type: BusStopsStepTypeKeys.SET_SEARCH_STOPS, payload: stops }
}

export function setSearchedStopResultAction(searchResult: {
  name: string
  type: ISearchedType
  coordinates: any
}): ISetSearchedStopResultAction {
  return { type: BusStopsStepTypeKeys.SET_SEARCHED_RESULT, payload: searchResult }
}

export function setSelectMarkerFlagAction(markerSelected: boolean): ISetSelectMarkerFlagAction {
  return { type: BusStopsStepTypeKeys.SET_SELECT_MARKER_FLAG, payload: markerSelected }
}

export function searchStopCoordinatesAction(stop: ISearchStop): ISearchStopCoordinatesAction {
  return { type: BusStopsStepTypeKeys.SEARCH_STOP_COORDINATES, payload: stop }
}

export function setBusStopAction({
  stop,
  idService
}: {
  stop: IStop
  idService: number
}): ISetBusStopAction {
  return { type: BusStopsStepTypeKeys.SET_BUS_STOP, payload: { stop, idService } }
}

export function fetchDestinationBusStopsAction({
  stop,
  idService
}: {
  stop: IStop
  idService: number
}): IFetchDestinationBusStopsAction {
  return { type: BusStopsStepTypeKeys.FETCH_DESTINATION_BUS_STOPS, payload: { stop, idService } }
}

export function setIsFetchingDestinationBusStopsAction(): ISetIsFetchingDestinationBusStopsAction {
  return { type: BusStopsStepTypeKeys.FETCHING_DESTINATION_BUS_STOPS }
}

export function fetchDestinationBusStopsSuccesfulAction(stops: IStop[]): IFetchDestinationBusStopsSuccesfulAction {
  return { type: BusStopsStepTypeKeys.FETCH_DESTINATION_BUS_STOPS_SUCCESSFUL, payload: stops }
}
