import {
  IRecoverPasswordAction,
  IRecoverPasswordFailedAction,
  IRecoverPasswordSuccessfulAction,
  IRequestRecoverCodeAction,
  IRequestRecoverCodeFailedAction,
  IRequestRecoverCodeSuccessfulAction,
  IResetRecoverPasswordFieldsAction,
  ISetIsRecoveringPasswordAction,
  ISetIsRequestingRecoverCodeAction,
  ISetRecoverPasswordEmailAction,
  ISetRecoverPasswordNewPasswordAction,
  ISetRecoverPasswordRepeatNewPasswordAction,
  ISetRecoverPasswordVerificationCodeAction,
  RecoverPasswordTypeKeys
} from '../types'

export function resetRecoverPasswordFieldsAction(): IResetRecoverPasswordFieldsAction {
  return { type: RecoverPasswordTypeKeys.RESET_RECOVER_PASSWORD_FIELDS }
}

export function setRecoverPasswordEmailAction(email: string): ISetRecoverPasswordEmailAction {
  return { type: RecoverPasswordTypeKeys.SET_RECOVER_PASSWORD_EMAIL, payload: email }
}

export function requestRecoverCodeAction(): IRequestRecoverCodeAction {
  return { type: RecoverPasswordTypeKeys.REQUEST_RECOVER_CODE }
}

export function setIsRequestingRecoverCodeAction(): ISetIsRequestingRecoverCodeAction {
  return { type: RecoverPasswordTypeKeys.REQUESTING_RECOVER_CODE }
}

export function requestRecoverCodeSuccessfulAction(): IRequestRecoverCodeSuccessfulAction {
  return { type: RecoverPasswordTypeKeys.REQUEST_RECOVER_CODE_SUCCESSFUL }
}

export function requestRecoverCodeFailedAction(errorMessage: string): IRequestRecoverCodeFailedAction {
  return { type: RecoverPasswordTypeKeys.REQUEST_RECOVER_CODE_FAILED, payload: errorMessage }
}

export function setRecoverPasswordVerificationCodeAction(
  verificationCode: string
): ISetRecoverPasswordVerificationCodeAction {
  return {
    type: RecoverPasswordTypeKeys.SET_RECOVER_PASSWORD_VERIFICATION_CODE,
    payload: verificationCode
  }
}

export function setRecoverPasswordNewPasswordAction(newPassword: string): ISetRecoverPasswordNewPasswordAction {
  return { type: RecoverPasswordTypeKeys.SET_RECOVER_PASSWORD_NEW_PASSWORD, payload: newPassword }
}

export function setRecoverPasswordRepeatNewPasswordAction(
  repeatNewPassword: string
): ISetRecoverPasswordRepeatNewPasswordAction {
  return {
    type: RecoverPasswordTypeKeys.SET_RECOVER_PASSWORD_REPEAT_NEW_PASSWORD,
    payload: repeatNewPassword
  }
}

export function recoverPasswordAction(): IRecoverPasswordAction {
  return { type: RecoverPasswordTypeKeys.RECOVER_PASSWORD }
}

export function setIsRecoveringPasswordAction(): ISetIsRecoveringPasswordAction {
  return { type: RecoverPasswordTypeKeys.RECOVERING_PASSWORD }
}

export function recoverPasswordSuccessfulAction(): IRecoverPasswordSuccessfulAction {
  return { type: RecoverPasswordTypeKeys.RECOVER_PASSWORD_SUCCESSFUL }
}

export function recoverPasswordFailedAction(error: string): IRecoverPasswordFailedAction {
  return { type: RecoverPasswordTypeKeys.RECOVER_PASSWORD_FAILED, payload: error }
}
