import styled from 'styled-components'

export default styled.article`
  width: 800px;
  border-radius: 4px;
  background-color: ${ ({ theme }) => theme.colors.white };
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  
  @media all and (max-width: 800px){
    width: 100%;
  }

  .spinner-container {
    flex: 1;
  }

  header {
    min-height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;

    h3 {
      color: ${ ({ theme }) => theme.colors.primary };
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0;
    }

    button {
      font-family: ${ ({ theme }) => theme.fontFamily };
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      background-color: transparent;
      border: none;
      color: ${ ({ theme }) => theme.colors.primary };
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;

      img {
        margin-right: 10px;
        height: 20px;
        width: auto;
      }
    }
  }

  > section {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;
  }

  footer {
    min-height: 50px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    padding-bottom: 10px;
    position: relative;

    button {
      width: 180px;
    }

    .feedback-container {
      position: relative;
      
      .feedback {
        height: 40px;
        border-radius: 40px;
        background-color: ${ ({ theme }) => theme.colors.white }90;
        display: flex;
        justify-content: center;
        align-items: center;

        .feedback-text {
          color: ${ ({ theme }) => theme.colors.primary };
          font-size: 18px;
        }
      }
    }
  }

  .map {
    width: calc(100% - 40px);
    min-height: 50vh;
    flex: 1;
    margin: 0 20px;
    border-radius: 10px;
    box-sizing: border-box;
  }

  .stops-summary {
    padding: 5px 50px;
    padding-top: 20px;
    flex: 0 0 auto;

    div:not(.dashed) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      
      span {
        font-family: ${ ({ theme }) => theme.fontFamily };
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 20px;
        color: ${ ({ theme }) => theme.colors.greyDark };
        margin-left: 14px;

        &.placeholder {
          color: ${ ({ theme }) => theme.colors.grey };
          font-weight: normal;
        }
      }

    }

    .dashed {
      border-left: 2px dotted ${ ({ theme }) => theme.colors.grey };
      margin: -4px 0 -4px 9px;
      height: 25px;
    }
  }

  &.list-mode {
    header {
      border-bottom: 1px solid ${ ({ theme }) => theme.colors.almostWhite };
    }
    footer {
      border-top: 1px solid ${ ({ theme }) => theme.colors.almostWhite };
    }
  }
`
