import styled from 'styled-components'

export default styled.section`
  display: flex;
  flex-direction: row;
  min-height: 80px;
  
  .column-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .stop-name {
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
      color: ${ ({ theme }) => theme.colors.greyDark };
      padding-left: 18px;
    }
  }

  .dashed {
    border-left: 2px dotted ${ ({ theme }) => theme.colors.grey };
    flex: 1;
    margin: -4px 0 -4px 9px;
  }
`
