/* eslint-disable max-len */

export const literals = {
  ca: {
    ca: 'Català',
    caShort: 'CAT',
    es: 'Castellà',
    esShort: 'ES',
    changeLanguage: 'modifica l\'idioma',
    name: 'nom',
    surname: 'cognoms',
    dni: 'DNI',
    email: 'correu electrònic',
    emailOrUsername: 'DNI o correu electrònic',
    municipality: 'municipi',
    phone: 'telèfon',
    personalInfo: 'dades personals',
    userInfo: 'dades d\'usuari',
    contactInfo: 'dades de contacte',
    save: 'guardar',
    reservations: 'reserves',
    nextReservations: 'pròximes reserves',
    newReservation: 'nova reserva',
    deleteReservation: 'Cancel·lar reserva',
    sureToDelete: 'Segur que vols cancel·lar aquesta reserva?',
    cancel: 'cancel·lar',
    back: 'enrere',
    delete: 'esborrar',
    reservationDeleted: 'reserva eliminada',
    reservationNotDeleted: 'reserva no s’ha eliminat',
    favorites: 'rutes preferides',
    noFavorites: 'no tens rutes preferides',
    goToYourListAndMarkFavorites:
      'mira a la llista de reserves i comença a guardar les teves rutes preferides',
    seeMyReservations: 'veure les meves reserves',
    penalites: 'incidències',
    pastReservations: 'historial de reserves',
    areas: 'àrees',
    configuration: 'configuració',
    information: 'informació',
    editUserInfo: 'editar dades de perfil',
    desiredRoute: 'ruta desitjada',
    optionsRoute: 'expedicions',
    dateAndHourDesired: 'data i hora desitjada',
    desiredDate: 'data desitjada' ,
    desiredHour: 'hora desitjada',
    hour: 'hora',
    routeDetails: 'detalls de ruta',
    takeAfter: 'sortir a partir de',
    arriveBefore: 'Arribar abans de',
    next: 'continuar',
    following: 'següent',
    previous: 'anterior',
    changePassword: 'canviar contrasenya',
    area: 'canviar àrea',
    chooseArea: 'selecciona àrea',
    dropOut: 'baixa del servei',
    closeSession: 'Tancar sessió',
    rebook: 'tornar a reservar',
    recommended: 'recomanada',
    hourMayUpdate:
      'L\'hora de sortida i arribada pot variar. El mantindrem informat si hi ha algun canvi',
    exit: 'sortida',
    aprox: 'aprox',
    arrival: 'arribada',
    stopNotAvailable: 'parada no disponible',
    stopNotAvailableExplanation: 'el bus no pot parar a la parada sol·licitada. T\'hem assignat una parada pròxima.',
    walk: 'camina',
    putName: 'posa un nom al teu trajecte',
    signIn: 'iniciar sessió',
    createAccount: 'crear compte',
    inputVerificationCode: 'Introduir codi de verificació',
    verificationCode: 'Codi de verificació',
    send: 'enviar',
    verifyYourDNI: 'verifica el DNI',
    verifyYourEmail: 'Verifica el correu electrònic',
    requestNewSignUpVerificationCode: 'sol·licitar un nou codi',
    continue: 'continuar',
    reservationDetails: 'detalls de reserva',
    cancelled: 'reserva cancel·lada',
    chooseOriginMap: 'selecciona l\'estació de sortida al mapa',
    chooseDestinationMap: 'selecciona l\'estació de destinació al mapa',
    chooseOriginList: 'selecciona l\'estació de sortida a la llista',
    chooseDestinationList: 'selecciona l\'estació de destinació a la llista',
    edit: 'editar',
    noResults: 'no hi ha resultats',
    noResultsExplanation:
      'no hi ha opcions de ruta disponibles per a l\'origen i destinació seleccionats',
    modifyRoute: 'modificar ruta',
    selectSeats: 'selecciona places',
    seat: 'plaça estàndard',
    seats: 'places estàndard',
    prmSeat: 'plaça mobilitat reduïda',
    prmSeats: 'places mobilitat reduïda',
    saved: 'guardat correctament',
    notSaved: 'not saved',
    reasonsCancelled:
      'A causa de problemes en la línia, l\'autobús no passará per la teva parada a l\'hora sol·licitada. Sentim les molèsties.',
    today: 'avui',
    yesterday: 'ahir',
    tomorrow: 'demà',
    errorFavorite: 'aquest nom de ruta ja està en ús',
    penaltyCeroTitle: 'fantàstic',
    penaltyFirstTitle: 'vaya',
    penaltySecondTitle: 'oh oh',
    penaltyThirdTitle: 'reserves bloquejades',
    penaltyCeroSubTitle: 'no tens cap falta d\'assistència.',
    penaltyFirstSubTitle: 'tens una falta d\'assistència.',
    penaltySecondSubTitle: 'Tens 2 faltes d\'assistència.',
    penaltyThirdSubTitle:
      'has acumulat massa faltes d\'assistència. No oblidis assistir a les teves reserves!',
    standard: 'estándar',
    password: 'contrasenya',
    repeatPassword: 'repetir contrasenya',
    repeatEmail: 'repetir correu electrònic',
    lastName: 'cognoms',
    finalize: 'finalitzar',
    yourUserData: 'les teves dades d\'usuari',
    whoAreYou: 'qui ets',
    termsAndConditions: 'he llegit i accepto els Termes i Condicions.',
    terms: 'he llegit i accepto els ',
    termsLink: 'Termes i Condicions.',
    notUserCreateOne: 'No tens usuari? Registra\'t',
    forgottenPassword: 'Has oblidat la contrasenya?',
    writeYourEmail: 'escriu el teu correu electrònic',
    willSendVerificationCode:
      't\'enviarem un codi de verificació per restablir la contrasenya',
    noUserWithThisMail:
      'no hi ha cap usuari amb aquest email, assegura\'t que aquesta ben escrit',
    newPassword: 'nova contrasenya',
    repeatNewPassword: 'repeteix nova contrasenya',
    reestablishPassword: 'restableix la teva contrasenya',
    haveSentVerificationCode:
      'hem enviat un codi al teu correu electrònic. Assegura\'t de comprovar també la carpeta de Spam',
    alreadyHaveAccount: 'Ja tens usuari? Inicia sessió.',
    alreadyUsedEmail:
      'aquest email ja està registrat en Nemi, pots iniciar sessió ',
    here: 'aquí',
    noReservations: 'no tens cap reserva',
    noReservationsExplanation:
      'dóna-li al botó \'nova reserva\' per començar a utilitzar el servei.',
    alreadyUsedDNI: 'aquest DNI ja està registrat en Nemi',
    alreadyUsedEmailAndDni: 'aquest email i DNI ja estan registrat en Nemi, pots iniciar sessió',
    dniInvalid: 'DNI incorrecte, assegura\'t que la lletra estigui en majúscules i el DNI sigui vàlid',
    searchDirectionOrStop: 'busca direcció o parada',
    myUbication: 'la meva ubicació',
    sooner: 'més d\'hora',
    later: 'més tard',
    moreOptions: 'més opcions',
    departure: 'sortida',
    serverError: 'Hi ha hagut un problema en el servidor',
    book: 'reservar',
    bookConfirmed: 'la reserva s\'ha realitzat correctament',
    bookFailed: 'error en fer la reserva',
    contactSupport: 'contactar amb suport',
    destinationStation: 'estació de destinació',
    jan: 'gen',
    feb: 'febr',
    mar: 'març',
    apr: 'abr',
    may: 'maig',
    jun: 'juny',
    jul: 'jul',
    aug: 'ag',
    sept: 'set',
    oct: 'oct',
    nov: 'nov',
    dec: 'des',
    mon: 'dl',
    tue: 'dt',
    wed: 'dc',
    thu: 'dj',
    fri: 'dv',
    sat: 'ds',
    sun: 'dg',
    tooMuchDistance: 'encara no arribem tan lluny!',
    pleaseChooseService:
      'si us plau tria un lloc de destí que entri en les nostres àrees de servei.',
    recenter: 'recentrar',
    destination: 'destí',
    finalStopTag: 'final de línia',
    origin: 'origen',
    notifications: 'notificacions',
    infoNotificationFlag:
      'rebre correus electrònics amb informació sobre el servei',
    rateNotificationFlag:
      'rebre alertes per opinar sobre el servei després de cada ruta',
    infoNewPassword:
      'assegura\'t que la nova contingui com a mínim un nombre i una lletra minúscula.',
    passwordChanged: 'contraseña actualizada',
    tooMuchPenalties: 'has acumulat massa faltes d\'assistència',
    rememberToAssist: 'no oblidis assistir a les teves reserves!',
    soon: 'sortida en',
    onGoing: 'en ruta',
    past: 'ruta acabada',
    noConnection: 'Sense conexió',
    sadToSeeYouGo:
      'lamentem que tinguis pensat donar-te de baixa del sistema. Si us plau, indiqueu el motiu pel qual et vols marxar',
    chooseAReason: 'tria un motiu',
    unsubscribe: 'donar de baixa',
    confirmUnsubscribe: 'confirmar baixa',
    validPassword: 'la contrasenya ha de tenir almenys 8 caràcters, una lletra minúscula i un número',
    userOrPasswordWrong: 'DNI, correu electrònic o contrasenya incorrectes.',
    verificationCodeSent: 'codi de verificació enviat',
    removeFavorite: 'eliminar el següent favorit',
    termsOfUse: 'condicions d\'ús',
    pressAgainToExit: 'prem una altra vegada per sortir',
    invalidCode: 'el codi introduït no és vàlid',
    duplicatedReservation: 'ja hi ha una reserva en aquesta expedició',
    duplicatedFavorite: 'ja existeix aquesta reserva favorita',
    maxCapacity: 'capacitat màxima excedida',
    infiesibleReservation: 'reserva inviable',
    expiresIn: 'expira en',
    days: 'dies',
    pickUpSoon: 'recollida a',
    preconditionFailed: 'impossible modificar a hores d\'ara',
    attentionSchedule:
      'horari d\'atenció al client: de dilluns a divendres de 8:00h a 20:00h',
    attentionScheduleTmesa:
      'horari d\'atenció al client: de dilluns a divendres de 9:00h a 19:30h',
    serviceInformation: 'horaris i informació del servei:',
    webPage: 'pàgina web',
    catala: 'català',
    castellano: 'castellà',
    italian: 'italià',
    blockDetailsLine1:
      'Per algun motiu aquest usuari no té accés al servei actualment.',
    blockDetailsLine2:
      'Si us plau contacti amb el suport si desitja conèixer els detalls i/o per sol·licitar la reactivació.',
    blockedAccount: 'el teu compte ha estat bloquejat',
    hourMayUpdateWithTime:
      'L\'hora de sortida i arribada pot arribar a variar un màxim de %MINUTESTOREPLACE% minuts depenent de les reserves dels altres usuaris. La teva reserva s\'anirà actualitzant i et notificarem quan l\'horari sigui definitiu.',
    aboutApp: 'sobre l\'aplicació:',
    confirmOrigin: 'confirma la parada d\'origen',
    confirmDestination: 'confirma la parada de destí',
    valueExperience: 'Valora la teva experiència',
    skip: 'saltar',
    whatProblem: 'Quin ha estat el teu principal problema?',
    dniHint: 'al ser un servei de transport públic, és necessari que sigui personal i així evitar el seu mal ús',
    close: 'tancar',
    CodeDeliveryFailureException: 'el codi de verificació no s\'ha lliurat correctament',
    InternalErrorException: 'hi ha un problema en la petició (Codi: InternalErrorException)',
    InvalidEmailRoleAccessPolicyException: 'no es pot utilitzar la seva identitat de correu electrònic',
    InvalidLambdaResponseException: 'hi ha un problema en la petició (Codi: InvalidLambdaResponseException)',
    InvalidParameterException: 'hi ha un problema en la petició (Codi: InvalidParameterException)',
    InvalidPasswordException: 'contrasenya invàlida',
    InvalidSmsRoleAccessPolicyException: 'hi ha un problema en la petició (Codi: InvalidSmsRoleAccessPolicyException)',
    InvalidSmsRoleTrustRelationshipException: 'hi ha un problema en la petició (Codi: InvalidSmsRoleTrustRelationshipException)',
    NotAuthorizedException: 'usuari no autoritzat',
    ResourceNotFoundException: 'recurs sol·licitat no trobat',
    TooManyRequestsException: 's\'han realitzat massa sol·licituds',
    UnexpectedLambdaException: 'hi ha un problema en la petició (Codi: UnexpectedLambdaException)',
    UserLambdaValidationException: 'hi ha un problema en la petició (Codi: UserLambdaValidationException)',
    UsernameExistsException: 'aquest usuari ja està registrat, ves a iniciar sessió!',
    LimitExceededException: 'Has superat el límit de reintent de contrasenya. Si us plau-ho a provar en una hora',
    PasswordResetRequiredException: 'es requereix un restabliment de contrasenya',
    UserNotConfirmedException: 'usuari no confirmat',
    UserNotFoundException: 'no hi ha cap usuari amb aquest email, assegura\'t que aquesta ben escrit',
    CodeMismatchException: 'el codi introduït no coincideix',
    yes: 'sí',
    no: 'no',
    salentoInfoDescription: 'This project has received funding from the European Union’s Horizon 2020 research and innovation programme under grant agreement No 769819.',
    seeList: 'Veure llista',
    seeMap: 'Veure mapa'
  },
  es: {
    ca: 'Catalan',
    caShort: 'CAT',
    es: 'Castellano',
    esShort: 'ES',
    changeLanguage: 'cambiar idioma',
    name: 'nombre',
    surname: 'apellidos',
    dni: 'DNI',
    email: 'correo electrónico',
    emailOrUsername: 'DNI o correo electrónico',
    municipality: 'municipio',
    phone: 'teléfono',
    personalInfo: 'datos personales',
    userInfo: 'datos de usuario',
    contactInfo: 'datos de contacto',
    save: 'guardar',
    reservations: 'reservas',
    nextReservations: 'próximas reservas',
    newReservation: 'nueva reserva',
    deleteReservation: 'Cancelar reserva',
    sureToDelete: 'Estas seguro de que quieres cancelar esta reserva?',
    cancel: 'cancelar',
    back: 'atrás',
    delete: 'borrar',
    reservationDeleted: 'reserva eliminada',
    reservationNotDeleted: 'reservationNotDeleted',
    favorites: 'rutas favoritas',
    noFavorites: 'no tienes rutas favoritas',
    goToYourListAndMarkFavorites:
      've a tu lista de reservas y empieza a guardar tus rutas favoritas',
    seeMyReservations: 'ver mis reservas',
    penalites: 'incidencias',
    pastReservations: 'historial de reservas',
    areas: 'áreas',
    configuration: 'configuración',
    information: 'información',
    editUserInfo: 'editar datos de perfil',
    desiredRoute: 'ruta deseada',
    optionsRoute: 'expediciones',
    dateAndHourDesired: 'fecha y hora deseada',
    desiredDate: 'fecha deseada' ,
    desiredHour: 'hora deseada',
    hour: 'hora',
    routeDetails: 'detalles de ruta',
    takeAfter: 'Salir a partir de',
    arriveBefore: 'llegar antes de',
    next: 'continuar',
    following: 'siguiente',
    previous: 'anterior',
    changePassword: 'cambiar contraseña',
    area: 'cambiar área',
    chooseArea: 'selecciona área',
    dropOut: 'baja del servicio',
    closeSession: 'Cerrar sesión',
    rebook: 'volver a reservar',
    recommended: 'recomendada',
    hourMayUpdate:
      'La hora de salida y llegada puede variar. Le mantendremos informado si hay algun cambio',
    exit: 'salida',
    aprox: 'aprox',
    arrival: 'llegada',
    stopNotAvailable: 'parada no disponible',
    stopNotAvailableExplanation: 'el bus no puede parar en la parada solicitada. Te hemos asignado una parada más cercana.',
    walk: 'camina',
    putName: 'pon un nombre a tu trayecto',
    signIn: 'iniciar sesión',
    createAccount: 'crear cuenta',
    inputVerificationCode: 'Introducir código de verificación',
    verificationCode: 'Código de verificación',
    send: 'enviar',
    verifyYourDNI: 'verifica tu DNI',
    verifyYourEmail: 'Verifica el correo electrónico',
    requestNewSignUpVerificationCode: 'solicitar un nuevo código',
    continue: 'continuar',
    reservationDetails: 'detalles de reserva',
    cancelled: 'reserva cancelada',
    chooseOriginMap: 'selecciona la estación de salida en el mapa',
    chooseDestinationMap: 'selecciona la estación de destino en el mapa',
    chooseOriginList: 'selecciona la estación de salida en la lista',
    chooseDestinationList: 'selecciona la estación de destino en la lista',
    edit: 'editar',
    noResults: 'no hay resultados',
    noResultsExplanation:
      'no existen opciones de ruta disponibles para el origen y destino seleccionados',
    modifyRoute: 'modificar ruta',
    selectSeats: 'selecciona plazas',
    seat: 'plaza estándar',
    seats: 'plazas estándar',
    prmSeat: 'plaza movilidad reducida',
    prmSeats: 'plazas movilidad reducida',
    saved: 'guardado correctamente',
    notSaved: 'not saved',
    reasonsCancelled:
      'Debido a problemas en la linea, el autobús no pasara por tu parada a la hora solicitada. Sentimos las molestias.',
    today: 'hoy',
    yesterday: 'ayer',
    tomorrow: 'mañana',
    errorFavorite: 'este nombre de ruta ya esta en uso',
    penaltyCeroTitle: 'estupendo',
    penaltyFirstTitle: 'vaya',
    penaltySecondTitle: 'oh oh',
    penaltyThirdTitle: 'reservas bloqueadas',
    penaltyCeroSubTitle: 'no tienes ninguna falta de asistencia.',
    penaltyFirstSubTitle: 'tienes una falta de asistencia.',
    penaltySecondSubTitle: 'Tienes 2 faltas de asistencia.',
    penaltyThirdSubTitle:
      'has acumulado demasiadas faltas de asistencia. No olvides asistir a tus reservas!',
    standard: 'estándar',
    password: 'contraseña',
    repeatPassword: 'repetir contraseña',
    repeatEmail: 'repetir correo electrónico',
    lastName: 'apellidos',
    finalize: 'finalizar',
    yourUserData: 'tus datos de usuario',
    whoAreYou: 'quién eres',
    termsAndConditions: 'he leído y acepto los Términos y Condiciones.',
    terms: 'he leído y acepto los ',
    termsLink: 'Términos y Condiciones.',
    notUserCreateOne: '¿No tienes usuario? Registrarte',
    forgottenPassword: '¿Has olvidado la contraseña?',
    writeYourEmail: 'escribe tu correo electrónico',
    willSendVerificationCode:
      'te enviaremos un código de verificación para restablecer tu contraseña',
    noUserWithThisMail:
      'no existe ningún usuario con este email, asegurate de que esta bien escrito',
    newPassword: 'nueva contraseña',
    repeatNewPassword: 'repite nueva contraseña',
    reestablishPassword: 'restablece tu contraseña',
    haveSentVerificationCode:
      'hemos enviado un código a tu correo electrónico. Asegúrate de comprobar también la carpeta de Spam',
    alreadyHaveAccount: '¿Ya tienes usuario? Inicia sesión.',
    alreadyUsedEmail:
      'este email ya esta registrado en Nemi, puedes iniciar sesión ',
    here: 'aquí',
    noReservations: 'no tienes ninguna reserva',
    noReservationsExplanation:
      'dale al botón \'nueva reserva\' para empezar a usar el servicio.',
    alreadyUsedDNI: 'este DNI ya esta registrado en Nemi',
    alreadyUsedEmailAndDni: 'este email y DNI ya están registrado en Nemi, puedes iniciar sesión',
    dniInvalid: 'DNI incorrecto, asegúrate de que la letra esté en mayúsculas y el DNI sea válido',
    searchDirectionOrStop: 'busca direccion o parada',
    myUbication: 'mi ubiación',
    sooner: 'más temprano',
    later: 'más tarde',
    departure: 'salida',
    moreOptions: 'más opciones',
    serverError: 'hubo un problema en el servidor',
    book: 'reservar',
    bookConfirmed: 'la reserva se ha realizado correctamente',
    bookFailed: 'error al realizar la reserva',
    contactSupport: 'contactar con soporte',
    destinationStation: 'estación de destino',
    jan: 'ene',
    feb: 'feb',
    mar: 'mar',
    apr: 'abr',
    may: 'may',
    jun: 'jun',
    jul: 'jul',
    aug: 'ago',
    sept: 'sep',
    oct: 'oct',
    nov: 'nov',
    dec: 'dic',
    mon: 'lun',
    tue: 'mar',
    wed: 'mié',
    thu: 'jue',
    fri: 'vie',
    sat: 'sáb',
    sun: 'dom',
    tooMuchDistance: 'todavia no llegamos tan lejos!',
    pleaseChooseService:
      'por favor elige un lugar de destino que entre en nuestras areas de servicio.',
    recenter: 'recentrar',
    destination: 'destino',
    finalStopTag: 'final de línea',
    origin: 'origen',
    notifications: 'notificaciones',
    infoNotificationFlag:
      'recibir correos electrónicos con información sobre el servicio',
    rateNotificationFlag:
      'recibir alertas para opinar sobre el servicio después de cada ruta',
    infoNewPassword:
      'asegúrate de que la nueva contraseña contenga como mínimo un número y una letra minúscula.',
    passwordChanged: 'contraseña actualizada',
    tooMuchPenalties: 'has acumulado demasiadas faltas de asistencia',
    rememberToAssist: 'no olvides asistir a tus reservas!',
    soon: 'salida en',
    onGoing: 'en ruta',
    past: 'ruta terminada',
    noConnection: 'sin conexión',
    sadToSeeYouGo:
      'lamentamos que tengas pensado darte de baja del sistema. Por favor, indícanos el motivo por el cual te quieres marchar',
    chooseAReason: 'elige un motivo',
    unsubscribe: 'baja del servicio',
    confirmUnsubscribe: 'confirmar baja',
    validPassword:
      'la contraseña debe contener al menos 8 caracteres, una letra minúscula y un número',
    userOrPasswordWrong: 'DNI, correo electronico o contraseña incorrectos.',
    verificationCodeSent: 'codigo de verificacion enviado',
    removeFavorite: 'eliminar el siguiente favorito',
    termsOfUse: 'condiciones de uso',
    pressAgainToExit: 'pulsa otra vez para salir',
    invalidCode: 'el codigo introducido no es valido',
    duplicatedReservation: 'ya existe una reserva en esta expedición',
    duplicatedFavorite: 'ya existe esta reserva favorita',
    maxCapacity: 'capacidad máxima excedida',
    infiesibleReservation: 'reserva inviable',
    expiresIn: 'expira en',
    days: 'dias',
    pickUpSoon: 'recogida en',
    preconditionFailed: 'imposible modificar a estas alturas',
    attentionSchedule:
      'horario de atención al cliente: de lunes a viernes de 8:00h a 20:00h',
    attentionScheduleTmesa:
      'horario de atención al cliente: de lunes a viernes de 9:00h a 19:30h',
    serviceInformation: 'horarios e información del servicio:',
    webPage: 'Página web',
    catala: 'catalán',
    castellano: 'castellano',
    italian: 'italiana',
    blockDetailsLine1:
      'Por algun motivo este usuario no tiene acceso al servicio actualmente.',
    blockDetailsLine2:
      'Por favor contacte con el soporte si desea conocer los detalles y/o para solicitar su reactivación.',
    blockedAccount: 'tu cuenta ha sido bloqueada',
    hourMayUpdateWithTime:
      'La hora de salida y llegada puede llegar a variar un máximo de %MINUTESTOREPLACE% minutos dependiendo de las reservas de los otros usuarios. Tu reserva se irá actualizando y te notificaremos cuando el horario sea definitivo.',
    aboutApp: 'sobre la aplicación:',
    confirmOrigin: 'confirma la parada de origen',
    confirmDestination: 'confirma la parada de destino',
    valueExperience: 'Valora tu experiencia',
    skip: 'saltar',
    whatProblem: '¿Cual ha sido tu principal problema?',
    dniHint: 'al ser un servicio de transporte público, es necesario que sea personal y así evitar su mal uso',
    close: 'cerrar',
    CodeDeliveryFailureException: 'el código de verificación no se ha entregado correctamente',
    InternalErrorException: 'hay un problema en la petición (Código: InternalErrorException)',
    InvalidEmailRoleAccessPolicyException: 'no se puede usar su identidad de correo electrónico',
    InvalidLambdaResponseException: 'hay un problema en la petición (Código: InvalidLambdaResponseException)',
    InvalidParameterException: 'hay un problema en la petición (Código: InvalidParameterException)',
    InvalidPasswordException: 'contraseña no válida',
    InvalidSmsRoleAccessPolicyException: 'hay un problema en la petición (Código: InvalidSmsRoleAccessPolicyException)',
    InvalidSmsRoleTrustRelationshipException: 'hay un problema en la petición (Código: InvalidSmsRoleTrustRelationshipException)',
    NotAuthorizedException: 'usuario no autorizado',
    ResourceNotFoundException: 'recurso solicitado no encontrado',
    TooManyRequestsException: 'se han realizado demasiadas solicitudes',
    UnexpectedLambdaException: 'hay un problema en la petición (Código: UnexpectedLambdaException)',
    UserLambdaValidationException: 'hay un problema en la petición (Código: UserLambdaValidationException)',
    UsernameExistsException: 'este usuario ya está registrado, ve a iniciar sesión!',
    LimitExceededException: 'Has superado el límite de reintento de contraseña. Por favor intente nuevamente en una hora',
    PasswordResetRequiredException: 'se requiere un restablecimiento de contraseña',
    UserNotConfirmedException: 'usuario no confirmado',
    UserNotFoundException: 'no existe ningún usuario con este email, asegurate de que esta bien escrito',
    CodeMismatchException: 'el código ingresado no coincide',
    yes: 'sí',
    no: 'no',
    salentoInfoDescription: 'This project has received funding from the European Union’s Horizon 2020 research and innovation programme under grant agreement No 769819.',
    seeList: 'Ver lista',
    seeMap: 'Ver mapa'
  }
  // it: {
  //   changeLanguage: 'cambia lingua',
  //   name: 'nome',
  //   surname: 'cognome',
  //   dni: 'codice fiscale',
  //   email: 'email',
  //   emailOrUsername: 'email o Codigo Fiscale',
  //   municipality: 'comune',
  //   phone: 'numero di telefono',
  //   personalInfo: 'informazioni personali',
  //   userInfo: 'informazioni dell\'utente',
  //   contactInfo: 'informazioni di contatto',
  //   save: 'salva',
  //   nextReservations: 'prossima prenotazione',
  //   newReservation: 'nuova prenotazione',
  //   deleteReservation: 'elimina prenotazione',
  //   sureToDelete: 'sicuro/a di voler cancellare la prenotazione?',
  //   cancel: 'cancella',
  //   back: 'indietro',
  //   delete: 'elimina',
  //   reservationDeleted: 'prenotazione eliminata',
  //   reservationNotDeleted: 'prenotazione non eliminata',
  //   favorites: 'percorsi preferiti',
  //   noFavorites: 'non ci sono percorsi preferiti',
  //   goToYourListAndMarkFavorites:
  //     'vai alla tua lista delle prenotazioni e scopri i tuoi percorsi preferiti',
  //   seeMyReservations: 'vedi le mie prenotazioni',
  //   penalites: 'penalità',
  //   pastReservations: 'storico prenotazioni',
  //   areas: 'aree',
  //   configuration: 'configurazione',
  //   information: 'informazioni',
  //   editUserInfo: 'modifica profilo',
  //   desiredRoute: 'percorso desiderato',
  //   optionsRoute: 'corse',
  //   dateAndHourDesired: 'data e ora desiderata',
  //   routeDetails: 'dettagli percorso',
  //   takeAfter: 'partenza dalle ore',
  //   arriveBefore: 'arrivo entro le ore',
  //   next: 'continuare',
  //   changePassword: 'modifica password',
  //   area: 'cambiare area',
  //   chooseArea: 'seleziona area',
  //   dropOut: 'Fuori servizio',
  //   closeSession: 'chiudi sessione',
  //   rebook: 'prenota di nuovo',
  //   recommended: 'suggerita',
  //   hourMayUpdate:
  //     'l\'orario di partenza e arrivo potrà subire variazioni. Ti terremo aggiornato/a.',
  //   exit: 'partenza',
  //   aprox: 'circa',
  //   arrival: 'arrivo',
  //   stopNotAvailable: 'fermata non disponibile',
  //   stopNotAvailableExplanation: 'il bus non può fermarsi alla fermata richiesta. Ti è stata assegnata una fermata vicina.',
  //   walk: 'cammina',
  //   putName: 'dai un nome al tuo percorso',
  //   signIn: 'accedi',
  //   createAccount: 'crea account',
  //   inputVerificationCode: 'inserire codice di verifica',
  //   verificationCode: 'codice di verifica',
  //   send: 'invia',
  //   verifyYourDNI: 'verifca il tuo codice fiscale',
  //   verifyYourEmail: 'verifica Email',
  //   requestNewSignUpVerificationCode: 'invia nuovo codice di verifica',
  //   continue: 'continuare',
  //   reservationDetails: 'dettagli della prenotazione',
  //   cancelled: 'prenotazione cancellata',
  //   chooseOriginMap: 'seleziona la fermata di partenza sulla mappa',
  //   chooseDestinationMap: 'seleziona la fermata di arrivo sulla mappa',
  //   chooseOriginList: 'seleziona la fermata di partenza dalla lista',
  //   chooseDestinationList: 'seleziona la fermata di arrivo dalla lista',
  //   edit: 'modifica',
  //   noResults: 'non ci sono risultati',
  //   noResultsExplanation:
  //     'non esistono percorsi disponibili dal punto di partenza e arrivo selezionati',
  //   modifyRoute: 'modifica percorso',
  //   seat: 'Posto a sedere',
  //   seats: 'Posti a sedere',
  //   prmSeat: 'Posto a sedere per mobilità ridotta',
  //   prmSeats: 'Posti a sedere per mobilità ridotta',
  //   saved: 'salvato correttamente',
  //   notSaved: 'non salvato',
  //   reasonsCancelled:
  //     'A causa di problemi sulla linea, l\'autobus non passerà dalla tua fermata all\'orario richiesto. Ci scusiamo per il disagio.',
  //   today: 'oggi',
  //   yesterday: 'ieri',
  //   tomorrow: 'domani',
  //   errorFavorite: 'Il nome del percorso è già in uso',
  //   penaltyCeroTitle: 'grande',
  //   penaltyFirstTitle: 'attento/a',
  //   penaltySecondTitle: 'oh oh',
  //   penaltyThirdTitle: 'prenotazioni bloccate',
  //   penaltyCeroSubTitle: 'Non hai mancato nessuna prenotazione.',
  //   penaltyFirstSubTitle: 'Hai una prenotazione mancata.',
  //   penaltySecondSubTitle: 'Hai 2 prenotazioni mancate.',
  //   penaltyThirdSubTitle:
  //     'Non ti sei presentato/a a troppe prenotazioni. Non dimenticare di cancellarle se non dovessero servirti più!',
  //   standard: 'standard',
  //   password: 'password',
  //   repeatPassword: 'ripeti password',
  //   repeatEmail: 'ripeti email',
  //   lastName: 'cognome',
  //   finalize: 'termina',
  //   yourUserData: 'le tue informazioni utente',
  //   whoAreYou: 'chi sei',
  //   termsAndConditions: 'Ho letto e accetto i Termini e le Condizioni.',
  //   terms: 'Ho letto e accetto i ',
  //   termsLink: 'Termini e le Condizioni.',
  //   notUserCreateOne: 'Non hai un account? Registrati',
  //   forgottenPassword: 'Password smarrita?',
  //   writeYourEmail: 'scrivi la tua email',
  //   willSendVerificationCode:
  //     'ti invieremo un codice di verifica per reimpostare la tua password',
  //   noUserWithThisMail:
  //     'non esiste un account con questa email. Assicurati di averla scritta bene',
  //   newPassword: 'nuova password',
  //   repeatNewPassword: 'ripeti nuova password',
  //   reestablishPassword: 'resetta la tua password',
  //   haveSentVerificationCode:
  //     'codice di verifica inviato alla tua email. Controlla anche nella cartella spam',
  //   alreadyHaveAccount: 'Hai già un account? Accedi.',
  //   alreadyUsedEmail:
  //     'email già registrata su Nemi, puoi accedere al servizio',
  //   here: 'qui',
  //   noReservations: 'non hai nessuna prenotazione',
  //   noReservationsExplanation:
  //     'Premi il pulsante nuova prenotazione per iniziare a utilizzare il servizio',
  //   alreadyUsedDNI: 'questo codice fiscale è già registrato su Nemi',
  //   alreadyUsedEmailAndDni: 'il codice fiscale e la email sono già registrate su Nemi. Puoi accedere al servizio',
  //   dniInvalid: 'Codice fiscale non-valido. Assicurati che sia corretto e scritto in maiuscolo',
  //   searchDirectionOrStop: 'cerca direzione o fermata',
  //   myUbication: 'la mia posizione',
  //   sooner: 'più presto',
  //   later: 'più tardi',
  //   departure: 'partenza',
  //   moreOptions: 'più opzioni',
  //   serverError: 'errore sul server',
  //   book: 'prenota',
  //   bookConfirmed: 'la prenotazione è avvenuta correttamente',
  //   bookFailed: 'errore durante la prenotazione',
  //   contactSupport: 'contatta il supporto',
  //   destinationStation: 'fermata di destinazione',
  //   jan: 'gen',
  //   feb: 'feb',
  //   mar: 'mar',
  //   apr: 'apr',
  //   may: 'mag',
  //   jun: 'giu',
  //   jul: 'lug',
  //   aug: 'ago',
  //   sept: 'set',
  //   oct: 'ott',
  //   nov: 'nov',
  //   dec: 'dic',
  //   mon: 'lun',
  //   tue: 'mar',
  //   wed: 'mer',
  //   thu: 'gio',
  //   fri: 'ven',
  //   sat: 'sab',
  //   sun: 'dom',
  //   tooMuchDistance: 'sfortunatamente non operiamo in questa area!',
  //   pleaseChooseService:
  //     'per favore scegli un punto di arrivo all\'interno dell\'area.',
  //   recenter: 'riposiziona',
  //   destination: 'destinazione',
  //   finalStopTag: 'capolinea',
  //   origin: 'origine',
  //   notifications: 'notifiche',
  //   infoNotificationFlag:
  //     'ricevi email con le informazioni sul servizio',
  //   rateNotificationFlag:
  //     'ricevi un avviso per lasciare una recenzione dopo ogni viaggio',
  //   infoNewPassword:
  //     'assicurati che la nuova password contenga almeno un numero e una lettera minuscola.',
  //   passwordChanged: 'password modificata',
  //   tooMuchPenalties: 'hai accumulato troppe assenze',
  //   rememberToAssist: 'non dimenticare di presentarti alle tue prenotazioni!',
  //   soon: 'partenza tra',
  //   onGoing: 'in viaggio',
  //   past: 'viaggio terminato',
  //   noConnection: 'non c\'è connessione',
  //   sadToSeeYouGo:
  //     'ci dispiace che lasci l\'applicazione. Per favore, indica il motivo della tua scelta',
  //   chooseAReason: 'scegli un motivo',
  //   unsubscribe: 'Lascia il servizio',
  //   confirmUnsubscribe: 'conferma',
  //   validPassword:
  //     'la password deve contenere almeno 8 caratteri, una lettera minuscola e un numero',
  //   userOrPasswordWrong: 'codice fiscale, email o password errati.',
  //   verificationCodeSent: 'codice di verifica invitato',
  //   removeFavorite: 'elimina preferito',
  //   termsOfUse: 'condizioni di utilizzo',
  //   pressAgainToExit: 'premi di nuovo per uscire',
  //   invalidCode: 'il codice inserito non è valido',
  //   duplicatedReservation: 'esiste già una prenotazione per questa corsa',
  //   duplicatedFavorite: 'questo percorso è già tra i preferiti',
  //   maxCapacity: 'Capacità massima raggiunta',
  //   infiesibleReservation: 'prenotazione non attuabile',
  //   expiresIn: 'scade tra',
  //   days: 'giorni',
  //   pickUpSoon: 'passeremo tra',
  //   preconditionFailed: 'impossibile modificare a questo punto',
  //   attentionSchedule:
  //     'orario di assistenza al cliente: dal lunedì al venerdì dalle 8 alle 20',
  //   attentionScheduleTmesa:
  //     'orario di assistenza al cliente: dal lunedì al venerdì dalle 9 alle 19:30',
  //   serviceInformation: 'orario e informazioni di servizio:',
  //   webPage: 'sito web',
  //   catala: 'catalano',
  //   castellano: 'castigliano',
  //   italian: 'italiana',
  //   blockDetailsLine1:
  //     'Per qualche motivo questo utente non ha attualmente accesso al servizio.',
  //   blockDetailsLine2:
  //     'Per favore contatta il supporto se vuoi conoscere i dettagli o sollecitare la riattivazione.',
  //   blockedAccount: 'il tuo account è stato bloccato',
  //   hourMayUpdateWithTime:
  //     'Gli orari di partenza e di arrivo possono variare al massimo di %MINUTESTOREPLACE% minuti a seconda delle prenotazioni degli altri utenti. La prenotazione verrà aggiornata e ti informeremo quando l\'orario sarà definitivo.',
  //   aboutApp: 'sull\'applicazione:',
  //   confirmOrigin: 'Conferma fermata di partenza',
  //   confirmDestination: 'Conferma fermata di destinazione',
  //   valueExperience: 'Valuta la tua esperienza',
  //   skip: 'salta',
  //   whatProblem: 'Qual è stato il tuo problema principale?',
  //   dniHint: 'essendo un servizio di trasporto pubblico, è necessario che sia personale e quindi evitare abusi',
  //   close: 'chiudi',
  //   CodeDeliveryFailureException: 'il codice di verifica non è stato consegnato correttamente',
  //   InternalErrorException: 'c\'è un problema con la richiesta (Codice: InternalErrorException)',
  //   InvalidEmailRoleAccessPolicyException: 'il tuo indirizzo email non può essere utilizzato',
  //   InvalidLambdaResponseException: 'c\'è un problema con la richiesta (Codice: InvalidLambdaResponseException)',
  //   InvalidParameterException: 'c\'è un problema con la richiesta (Codice: InvalidParameterException)',
  //   InvalidPasswordException: 'password non valida',
  //   InvalidSmsRoleAccessPolicyException: 'c\'è un problema con la richiesta (Codice: InvalidSmsRoleAccessPolicyException)',
  //   InvalidSmsRoleTrustRelationshipException: 'c\'è un problema con la richiesta (Codice: InvalidSmsRoleAccessPolicyException)',
  //   NotAuthorizedException: 'utente non autorizzato',
  //   ResourceNotFoundException: 'risorsa non trovata',
  //   TooManyRequestsException: 'sono state effettuate troppe richieste',
  //   UnexpectedLambdaException: 'c\'è un problema con la richiesta (Codice: UnexpectedLambdaException)',
  //   UserLambdaValidationException: 'c\'è un problema con la richiesta (Codice: UserLambdaValidationException)',
  //   UsernameExistsException: 'questo utente è già registrato, accedi',
  //   LimitExceededException: 'Has superado el límite de reintento de contraseña. Por favor intente nuevamente en una hora',
  //   PasswordResetRequiredException: 'è necessario resettare la password',
  //   UserNotConfirmedException: 'utente non confermato',
  //   UserNotFoundException: 'non esiste nessun utente con questa email, assicurati che sia scritto bene',
  //   CodeMismatchException: 'il codice inserito non coincide',
  //   yes: 'si',
  //   no: 'no',
  //   salentoInfoDescription: 'This project has received funding from the European Union’s Horizon 2020 research and innovation programme under grant agreement No 769819.'
  // }
}
