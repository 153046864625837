import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { Button, Page, Title, ValidatedInput } from '../../../components'
import { isPasswordValid, isVerificationCodeValid } from '../../../helpers'
import { images } from '../../../images'
import { LiteralsService } from '../../../services'
import {
  recoverPasswordAction,
  recoverPasswordFailedAction,
  setRecoverPasswordNewPasswordAction,
  setRecoverPasswordRepeatNewPasswordAction,
  setRecoverPasswordVerificationCodeAction
} from '../../../store/actions'
import { IStoreState } from '../../../store/states'
import StyledArticle from '../styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  verificationCode: string
  newPassword: string
  repeatNewPassword: string
  error: string
  isLoading: boolean
}

class RestoreStep extends PureComponent<IProps> {
  private get buttonIsDisabled(): boolean {
    const { verificationCode, newPassword, repeatNewPassword, isLoading, error } = this.props

    return (
      !isVerificationCodeValid(verificationCode) ||
      !isPasswordValid(newPassword) ||
      !isPasswordValid(repeatNewPassword) ||
      newPassword !== repeatNewPassword ||
      isLoading ||
      !!error
    )
  }

  private get passwordAlert(): ReactNode {
    const { newPassword } = this.props

    if (!newPassword || isPasswordValid(newPassword)) return null
    return (
      <div className="error-container">
        <span className="error">
          { LiteralsService.get('validPassword', true) }
        </span>
      </div>
    )
  }

  private get passwordError(): boolean {
    const { newPassword, repeatNewPassword } = this.props

    return (
      newPassword !== repeatNewPassword && !!newPassword && !!repeatNewPassword
    )
  }

  private get invalidCodeError(): ReactNode {
    const error = this.props.error
    if (!error) return null
    return (
      <div className="error-container">
        <span className="error">
          { LiteralsService.get(error as any, true) }
        </span>
      </div>
    )
  }

  public render(): ReactNode {
    const { verificationCode, newPassword, repeatNewPassword } = this.props

    return (
      <Page>
        <StyledArticle>
          <header>
            <img src={ images['logo'] } alt="AMB:Bus Metropolità"/>
          </header>
          <form onSubmit={ e => e.preventDefault() }>
            <Title text={ LiteralsService.get('reestablishPassword', true) } />
            <section>
              <ValidatedInput
                name="code"
                label={ LiteralsService.get('verificationCode', true) }
                value={ verificationCode }
                onChange={ this.onVerificationCodeChange }
                error={ !isVerificationCodeValid(verificationCode) }
              />
              <ValidatedInput
                name="password"
                label={ LiteralsService.get('newPassword', true) }
                value={ newPassword }
                onChange={ this.onNewPasswordChange }
                error={ this.passwordError || !isPasswordValid(newPassword) }
                isPassword
              />
              {this.passwordAlert}
              <ValidatedInput
                name="repeatPassword"
                label={ LiteralsService.get('repeatNewPassword', true) }
                value={ repeatNewPassword}
                onChange={ this.onRepeatNewPasswordChange }
                error={ this.passwordError || !isPasswordValid(repeatNewPassword) }
                isPassword
              />
              { this.invalidCodeError }
            </section>
            <section className="actions">
              <Button
                title={ LiteralsService.get('save') }
                onPress={ this.handleSaveButtonPress }
                filled
                upper
                isDisabled={ this.buttonIsDisabled }
              />
            </section>
          </form>
        </StyledArticle>
      </Page>
    )
  }

  private onVerificationCodeChange = (verificationCode: string): void => {
    const { dispatch, error } = this.props
    if (error) dispatch(recoverPasswordFailedAction(''))
    dispatch(setRecoverPasswordVerificationCodeAction(verificationCode))
  }

  private onNewPasswordChange = (newPassword: string): void => {
    const { dispatch, error } = this.props
    if (error) dispatch(recoverPasswordFailedAction(''))
    dispatch(setRecoverPasswordNewPasswordAction(newPassword))
  }

  private onRepeatNewPasswordChange = (repeatNewPassword: string): void => {
    const { dispatch , error} = this.props
    if (error) dispatch(recoverPasswordFailedAction(''))
    dispatch(setRecoverPasswordRepeatNewPasswordAction(repeatNewPassword))
  }

  private handleSaveButtonPress = (): void => {
    const { dispatch } = this.props
    dispatch(recoverPasswordAction())
  }
}

const mapStateToProps = ({ recoverPassword }: IStoreState) => ({
  verificationCode: recoverPassword.verificationCode,
  newPassword: recoverPassword.newPassword,
  repeatNewPassword: recoverPassword.repeatNewPassword,
  error: recoverPassword.error,
  isLoding: recoverPassword.isLoading
})

export default withRouter(connect(mapStateToProps)(RestoreStep))
