import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects'
import { urls } from '../../routing'
import {
  AuthenticationService,
  LiteralsService
} from '../../services'
import {
  setIsSigningUpAction,
  setNotificationAction,
  signUpFailedAction,
  signUpSuccessfulAction
} from '../actions'
import { ISignUpState, IStoreState } from '../states'
import { SignUpTypeKeys } from '../types'

// Worker Saga
function* fetchSignUpAsync() {
  const routerHistory = yield getContext('routerHistory')
  try {
    yield put(setIsSigningUpAction())
    const signUp: ISignUpState = yield select(
      (state: IStoreState) => state.signUp
    )
    yield call(AuthenticationService.signUp, { ...signUp })
    yield call(routerHistory.push, urls.signUpStep1)
    yield put(signUpSuccessfulAction())
    yield put(
      setNotificationAction({
        isError: false,
        label: LiteralsService.get('verificationCodeSent', true)
      })
    )
    yield call(routerHistory.push, urls.accountVerificationStep)
  } catch (error) {
    yield put(
      setNotificationAction({
        isError: true,
        label: LiteralsService.get(error.code, true)
      })
    )
    yield put(signUpFailedAction(error))
  }
}

// Watcher Saga:
function* watchFetchSignUpAsync() {
  yield takeLatest(SignUpTypeKeys.SIGN_UP, fetchSignUpAsync)
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    watchFetchSignUpAsync()
  ])
}
