import { IFavorite, IFavoriteResponse } from '../../models'
import { BaseHttpService } from './base'

class FavoritesService extends BaseHttpService {
  public getFavorites = async (): Promise<IFavoriteResponse[]> => {
    return this.get('favorites')
  }

  public addFavorite = async (favorite: IFavorite): Promise<any> => {
    return this.post('favorites', favorite)
  }

  public updateFavorite = async (favorite: IFavorite): Promise<any> => {
    return this.put(`favorites/${favorite.id}`, favorite)
  }

  public removeFavorite = async (id: number): Promise<any> => {
    return this.delete(`favorites/${id}`)
  }
}

export default new FavoritesService()
