export enum VerificationTypeKeys {
  CLEAR_VERIFICATION_FIELDS = 'CLEAR_VERIFICATION_FIELDS',
  SET_VERIFICATION_DNI = 'SET_VERIFICATION_DNI',
  SET_VERIFICATION_CODE = 'SET_VERIFICATION_CODE',
  CONFIRM_VERIFICATION_CODE = 'CONFIRM_VERIFICATION_CODE',
  CONFIRMING_VERIFICATION_CODE = 'CONFIRMING_VERIFICATION_CODE',
  CONFIRM_VERIFICATION_CODE_SUCCESSFUL = 'CONFIRM_VERIFICATION_CODE_SUCCESSFUL',
  CONFIRM_VERIFICATION_CODE_FAILED = 'CONFIRM_VERIFICATION_CODE_FAILED',
  SET_VERIFICATION_EMAIL = 'SET_VERIFICATION_EMAIL',
  RESEND_VERIFICATION_CODE = 'RESEND_VERIFICATION_CODE',
  RESENDING_VERIFICATION_CODE = 'RESENDING_VERIFICATION_CODE',
  RESEND_VERIFICATION_CODE_SUCCESSFUL = 'RESEND_VERIFICATION_CODE_SUCCESSFUL',
  RESEND_VERIFICATION_CODE_FAILED = 'RESEND_VERIFICATION_CODE_FAILED'
}

export interface IClearVerificationFieldsAction {
  type: VerificationTypeKeys.CLEAR_VERIFICATION_FIELDS
}

export interface ISetVerificationDNIAction {
  type: VerificationTypeKeys.SET_VERIFICATION_DNI
  payload: string
}

export interface ISetVerificationCodeAction {
  type: VerificationTypeKeys.SET_VERIFICATION_CODE
  payload: string
}

export interface IConfirmVerificationCodeAction {
  type: VerificationTypeKeys.CONFIRM_VERIFICATION_CODE
}

export interface ISetIsConfirmingVerificationCodeAction {
  type: VerificationTypeKeys.CONFIRMING_VERIFICATION_CODE
}

export interface IConfirmVerificationCodeSuccessfulAction {
  type: VerificationTypeKeys.CONFIRM_VERIFICATION_CODE_SUCCESSFUL
}

export interface IConfirmVerificationCodeFailedAction {
  type: VerificationTypeKeys.CONFIRM_VERIFICATION_CODE_FAILED
}

export interface ISetVerificationEmailAction {
  type: VerificationTypeKeys.SET_VERIFICATION_EMAIL
  payload: string
}

export interface IResendVerificationCodeAction {
  type: VerificationTypeKeys.RESEND_VERIFICATION_CODE
}

export interface ISetIsResendingVerificationCodeAction {
  type: VerificationTypeKeys.RESENDING_VERIFICATION_CODE
}

export interface IResendVerificationCodeSuccessfulAction {
  type: VerificationTypeKeys.RESEND_VERIFICATION_CODE_SUCCESSFUL
}

export interface IResendVerificationCodeFailedfulAction {
  type: VerificationTypeKeys.RESEND_VERIFICATION_CODE_FAILED
}

export type VerificationActionTypes =
  | IClearVerificationFieldsAction
  | ISetVerificationDNIAction
  | ISetVerificationCodeAction
  | IConfirmVerificationCodeAction
  | ISetIsConfirmingVerificationCodeAction
  | IConfirmVerificationCodeSuccessfulAction
  | IConfirmVerificationCodeFailedAction
  | ISetVerificationEmailAction
  | IResendVerificationCodeAction
  | ISetIsResendingVerificationCodeAction
  | IResendVerificationCodeSuccessfulAction
  | IResendVerificationCodeFailedfulAction
