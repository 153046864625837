import React, { PureComponent, ReactNode } from 'react'
import { Page } from '../../../components'

class UserInfo extends PureComponent {
  public render(): ReactNode {
    return (
      <Page>
        UserInfo
      </Page>
    )
  }
}

export default UserInfo
