export enum NewPasswordRequiredTypeKeys {
  RESET_FIELDS = 'NEW_PASSWORD_REQUIRED_RESET_FIELDS',
  SET_PASSWORD = 'NEW_PASSWORD_REQUIRED_SET_PASSWORD',
  SET_USER = 'NEW_PASSWORD_REQUIRED_SET_USER',
  SAVE_PASSWORD = 'NEW_PASSWORD_REQUIRED_SAVE_PASSWORD'
}

export interface IResetNewPasswordRequiredAction {
  type: typeof NewPasswordRequiredTypeKeys.RESET_FIELDS
}

export interface ISetNewPasswordRequiredPasswordAction {
  type: typeof NewPasswordRequiredTypeKeys.SET_PASSWORD
  payload: string
}

export interface ISetNewPasswordRequiredUserAction {
  type: typeof NewPasswordRequiredTypeKeys.SET_USER
  payload: any
}

export interface ISetNewPasswordRequiredSavePasswordAction {
  type: typeof NewPasswordRequiredTypeKeys.SAVE_PASSWORD
}

export type NewPasswordRequiredActionTypes =
  | IResetNewPasswordRequiredAction
  | ISetNewPasswordRequiredPasswordAction
  | ISetNewPasswordRequiredUserAction
  | ISetNewPasswordRequiredSavePasswordAction
