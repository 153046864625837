import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { Button, Page, Spinner, Title, ValidatedInput } from '../../../components'
import { isEmailValid } from '../../../helpers'
import { images } from '../../../images'
import { LiteralsService } from '../../../services'
import {
  requestRecoverCodeAction,
  requestRecoverCodeFailedAction,
  setRecoverPasswordEmailAction
} from '../../../store/actions'
import { IStoreState } from '../../../store/states'
import StyledArticle from '../styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  email: string
  error: string
  isLoading: boolean
}

class RequestCodeStep extends PureComponent<IProps> {
  private get buttonIsDisabled(): boolean {
    const { email, isLoading, error } = this.props

    return !isEmailValid(email) || isLoading || !!error
  }

  private get noUserError(): ReactNode {
    const error = this.props.error
    if (!error) return null
    return (
      <div className="error-container">
        <span className="error">
          { LiteralsService.get(error as any, true) }
        </span>
      </div>
    )
  }

  private get recoverButton(): ReactNode {
    const { isLoading } = this.props
    if (isLoading) return <Spinner />
    return (
      <Button
        title={ LiteralsService.get('next') }
        onPress={ this.handleValidatedButtonPress }
        filled
        upper
        isDisabled={ this.buttonIsDisabled }
      />
    )
  }

  public render(): ReactNode {
    const { email, error } = this.props
    return (
      <Page>
        <StyledArticle>
          <header>
            <img src={ images['logo'] } alt="AMB:Bus Metropolità"/>
          </header>
          <form onSubmit={ e => e.preventDefault() }>
            <Title text={ LiteralsService.get('writeYourEmail', true) } />
            <section>
              <ValidatedInput
                name="email"
                label={ LiteralsService.get('email', true) }
                value={ email }
                onChange={ this.onEmailChange }
                error={ !isEmailValid(email) || !!error }
              />
              { this.noUserError }
            </section>
            <section className="actions">
              { this.recoverButton }
            </section>
          </form>
        </StyledArticle>
      </Page>
    )
  }

  private onEmailChange = (email: string): void => {
    const { dispatch, error } = this.props
    if (error) dispatch(requestRecoverCodeFailedAction(''))
    dispatch(setRecoverPasswordEmailAction(email))
  }

  private handleValidatedButtonPress = (): void => {
    const { dispatch } = this.props
    dispatch(requestRecoverCodeAction())
  }
}

const mapStateToProps = ({ recoverPassword }: IStoreState) => ({
  email: recoverPassword.email,
  error: recoverPassword.error,
  isLoading: recoverPassword.isLoading
})

export default withRouter(connect(mapStateToProps)(RequestCodeStep))
