import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { Page, Spinner } from '../../../components'
import { images } from '../../../images'
import { urls } from '../../../routing'
import { LiteralsService } from '../../../services'
import { fetchPastReservationsAction } from '../../../store/actions'
import { IStoreState } from '../../../store/states'
import { Card } from '../Reservations/Card'
import StyledSection from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  pastReservations: any[]
  isFetchingPastReservations: boolean
}

class PastReservations extends PureComponent<IProps> {
  private get emptyReservations(): ReactNode {
    return (
      <div className="empty-reservations">
        <img src={ images['noReservations'] } alt={ LiteralsService.get('noReservations') }/>
        {/* <h3>{ LiteralsService.get('noReservations', true) }</h3>
        <p>{ LiteralsService.get('noReservationsExplanation', true) }</p> */}
      </div>
    )
  }

  private get reservationsContent(): ReactNode {
    const { pastReservations, isFetchingPastReservations } = this.props

    if (isFetchingPastReservations) {
      return (
        <article className="spinner-container">
          <Spinner />
        </article>
      )
    } else {
      if (!pastReservations.length) return this.emptyReservations
      return pastReservations.map((reservation, i) => (
        <Card
          key={ i }
          reservation={ reservation }
          onClick={ () => console.log(reservation.id) }
          onFavoriteClick={ () => console.log(reservation.id) }
        />
      ))
    }
  }

  public componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchPastReservationsAction())
  }

  public render(): ReactNode {
    const { history } = this.props

    return (
      <Page className="authenticated">
        <StyledSection>
          <nav className="tabs">
            <div onClick={ () => history.push(urls.reservations) }>
              <span>{ LiteralsService.get('nextReservations', true) }</span>
            </div>
            <div className="divider" />
            <div className="active">
              <span>{ LiteralsService.get('pastReservations', true) }</span>
            </div>
          </nav>
          { this.reservationsContent }
        </StyledSection>
      </Page>
    )
  }
}

const mapStateToProps = ({ reservations }: IStoreState) => ({
  pastReservations: reservations.pastReservations,
  isFetchingPastReservations: reservations.isFetchingPastReservations
})

export default withRouter(connect(mapStateToProps)(PastReservations))
