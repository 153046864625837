import { PureComponent } from 'react'
import { DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { checkSessionAction } from '../store/actions'

export abstract class AuthenticatedRoutable<
  P extends DispatchProp<AnyAction>,
  S = Record<string, unknown>
> extends PureComponent<P, S> {
  public componentDidMount() {
    this.props.dispatch(checkSessionAction())
  }
}
