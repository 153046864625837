import React, { useState } from 'react'
import { Button, Modal, QuantityInput } from '../../../../components'
import { LiteralsService } from '../../../../services'
import StyledSection from './styles'

interface IProps {
  visible: boolean
  seats: number
  prmSeats: number
  onSave: (seats: number, prmSeats: number) => any
  close: () => void
}

export const SeatsModal: React.FC<IProps> = ({ visible, seats, prmSeats, onSave, close }) => {
  const [currentSeats, setCurrentSeats] = useState(seats)
  const [currentPrmSeats, setCurrentPrmSeats] = useState(prmSeats)
  const areSeatsEdited = seats !== currentSeats || prmSeats !== currentPrmSeats

  const handleChangeSeats = (newSeats: number) => {
    setCurrentSeats(newSeats)
  }

  const handleChangePrmSeats = (newPrmSeats: number) => {
    setCurrentPrmSeats(newPrmSeats)
  }

  const handleSave = () => {
    if (currentSeats + currentPrmSeats > 0) onSave(currentSeats, currentPrmSeats)
  }

  const handleClose = () => {
    setCurrentSeats(seats)
    setCurrentPrmSeats(prmSeats)
    close()
  }

  return (
    <Modal isHidden={ !visible } onOverlayClick={ close } >
      <StyledSection>
        <header>
          <h3>{ LiteralsService.get('selectSeats', true).toUpperCase() }</h3>
        </header>
        <section>
          <QuantityInput
            label={ LiteralsService.get('seats', true) }
            amount={ currentSeats }
            onChange={ handleChangeSeats }
          />
          <QuantityInput
            label={ LiteralsService.get('prmSeats', true) }
            amount={ currentPrmSeats }
            onChange={ handleChangePrmSeats }
          />
        </section>
        <footer>
          <Button
            title={ LiteralsService.get('save') }
            onPress={ handleSave }
            isDisabled={ !areSeatsEdited }
            filled
            upper
          />
          <Button
            title={ LiteralsService.get('close') }
            onPress={ handleClose }
            upper
          />
        </footer>
      </StyledSection>
    </Modal>
  )
}
