export enum DateStepTypeKeys {
  SET_TYPE_SPECIFIC_DATE_FLAG = 'SET_TYPE_SPECIFIC_DATE_FLAG',
  SET_PICKED_DATE = 'SET_PICKED_DATE',
  SET_PICKED_SEATS = 'SET_PICKED_SEATS'
}

export interface ISetTypeSpecificDateFlag {
  type: typeof DateStepTypeKeys.SET_TYPE_SPECIFIC_DATE_FLAG
  payload: boolean
}

export interface ISetPickedDateAction {
  type: typeof DateStepTypeKeys.SET_PICKED_DATE
  payload: Date
}

export interface ISetPickedSeatsAction {
  type: typeof DateStepTypeKeys.SET_PICKED_SEATS
  payload: { seats: number; prmSeats: number }
}

export type DateStepActionTypes =
  | ISetTypeSpecificDateFlag
  | ISetPickedDateAction
  | ISetPickedSeatsAction
