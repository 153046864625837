import { ISignUpState } from '../states'
import { SignUpActionTypes, SignUpTypeKeys } from '../types'

export const initialSignUpState: ISignUpState = {
  name: '',
  lastName: '',
  password: '',
  dni: '',
  email: '',
  termsAndConditions: false,
  isSigningUp: false
}

const signUp = (
  state: ISignUpState = initialSignUpState,
  action: SignUpActionTypes
): ISignUpState => {
  switch (action.type) {
    case SignUpTypeKeys.SET_SIGN_UP_EMAIL:
      return { ...state, email: action.payload }
    case SignUpTypeKeys.SET_SIGN_UP_PASSWORD:
      return { ...state, password: action.payload }
    case SignUpTypeKeys.SET_SIGN_UP_NAME:
      return { ...state, name: action.payload }
    case SignUpTypeKeys.SET_SIGN_UP_LAST_NAME:
      return { ...state, lastName: action.payload }
    case SignUpTypeKeys.SET_SIGN_UP_DNI:
      return { ...state, dni: action.payload }
    case SignUpTypeKeys.SET_SIGN_UP_TERMS:
      return { ...state, termsAndConditions: !state.termsAndConditions }
    case SignUpTypeKeys.CLEAR_SIGN_UP_FIELDS:
      return { ...initialSignUpState }
    case SignUpTypeKeys.SIGNING_UP:
      return { ...state, isSigningUp: true }
    case SignUpTypeKeys.SIGN_UP_SUCCESSFUL:
      return { ...state, isSigningUp: false }
    case SignUpTypeKeys.SIGN_UP_FAILED:
      return { ...state, isSigningUp: false }
    default:
      return state
  }
}

export default signUp
