/* eslint-disable camelcase */
import Amplify, { Auth } from 'aws-amplify'
import { isEmailValid } from '../helpers'
import { cognitoUserAttributesToBodUser } from '../models'
import { ISignUpState } from '../store/states'

class AuthenticationService {
  constructor() {
    Amplify.configure({
      Auth: {
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        region: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEBCLIENT_ID
      }
    })
  }

  public signIn(args: any): Promise<{ challengeName?: any }> {
    const username = isEmailValid(args.username)
      ? args.username.toLowerCase()
      : args.username
    return Auth.signIn(username, args.password)
  }

  public signUp(args: ISignUpState): Promise<any> {
    const { name, lastName, email, dni, password } = args
    const signUpData: any = {
      username: dni,
      password,
      attributes: {
        name,
        email: email.toLowerCase(),
        family_name: lastName,
        'custom:dni_nie': dni
      }
    }
    return Auth.signUp(signUpData)
  }

  public resendSignUpConfirmationCode(dni: string) {
    return Auth.resendSignUp(dni)
  }

  public confirmSignUp(dni: string, code: string): Promise<any> {
    return Auth.confirmSignUp(dni, code)
  }

  public signOut(): Promise<any> {
    return Auth.signOut()
  }

  public getTokens(): Promise<any> {
    return Auth.currentSession() as Promise<any>
  }

  public requestRecoverPasswordCode(email: string): Promise<any> {
    return Auth.forgotPassword(email)
  }

  public requestRecoverPasswordChange(
    email: string,
    code: string,
    password: string
  ): Promise<any> {
    return Auth.forgotPasswordSubmit(email, code, password)
  }

  public async completeNewPassword(user: any, password: string): Promise<any> {
    const {...attributes } = user.challengeParam
      .userAttributes as any
    return Auth.completeNewPassword(user, password, attributes)
  }

  public async getUserAttributes(): Promise<any> {
    const user = await Auth.currentAuthenticatedUser()
    const attributes = await Auth.userAttributes(user)
    return cognitoUserAttributesToBodUser(attributes)
  }

  public async changePassword(
    password: string,
    newPassword: string
  ): Promise<any> {
    const user = await Auth.currentAuthenticatedUser()
    return Auth.changePassword(user, password, newPassword)
  }

  public async updateAttributes(attributes: any): Promise<any> {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.updateUserAttributes(user, attributes)
    return this.getUserAttributes()
  }
}

export default new AuthenticationService()
