import { Language } from './literals-service'
// import { DateTime } from 'luxon'
// import * as Keychain from 'react-native-keychain'
// import { ITokenData } from '../models'

class StorageService {
  private prefix = '@AMB'
  private keys = {
    language: `${this.prefix}:language`,
    routeName: `${this.prefix}:route`
  }

  public setLanguage(language: Language): void {
    return this.setByKey(this.keys.language, language)
  }

  public getLanguage(): Language {
    return this.getByKey(this.keys.language)
  }

  public removeLanguage(): void {
    return this.removeByKey(this.keys.language)
  }

  public setRouteName(routeName: string): void {
    return this.setByKey(this.keys.routeName, routeName)
  }

  public getRouteName(): string {
    return this.getByKey(this.keys.routeName)
  }

  public removeRouteName(): void {
    return this.removeByKey(this.keys.routeName)
  }

  private getByKey(key: string): any {
    try {
      const value = localStorage.getItem(key)
      return value ? JSON.parse(value) : null
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error)
    }
  }

  private setByKey(key: string, value: any): void {
    try {
      localStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error)
    }
  }

  private removeByKey(key: string): any {
    try {
      localStorage.removeItem(key)
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error)
    }
  }
}

export default new StorageService()
