import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { geoContains } from 'd3-geo'
import { Button, Page, Spinner } from '../../../components'
import { ListStopsView, MapStopsView, SearchStopsView } from '../../../containers'
import { images } from '../../../images'
import { IBase, ILocation, ISearchedType, IService, IStop } from '../../../models'
import { urls } from '../../../routing'
import { LiteralsService } from '../../../services'
import {
  fetchDestinationBusStopsAction,
  fetchServicesAction,
  resetNewReservationFieldsAction,
  searchStopCoordinatesAction,
  setBusStopAction,
  setIsSeachingAction
} from '../../../store/actions'
import { IStoreState } from '../../../store/states'
import StyledArticle from '../styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  services: IService[]
  isFetchingServices: boolean
  pickedOriginBusStop?: IStop
  pickedDestinationBusStop?: IStop
  pickedService: number | null
  isSearching: boolean
  coordinates: ILocation | null
  isPenalized: boolean
  town: IBase
  searchedType: ISearchedType | null
  isMarkerSelected: boolean
}

interface IState {
  isInListMode: boolean
  isUserFocused: boolean
}

class BusStopsStep extends PureComponent<IProps, IState> {
  private get viewMode(): ReactNode {
    const { isInListMode } = this.state
    const { isSearching } = this.props

    if (isInListMode) return <ListStopsView />
    else if (isSearching) return <SearchStopsView />
    else return <MapStopsView />
  }

  private get isEveryFieldValid(): boolean {
    const { pickedOriginBusStop, pickedDestinationBusStop } = this.props
    return !!pickedOriginBusStop && !!pickedDestinationBusStop
  }

  private get modeButton(): ReactNode {
    const { isInListMode } = this.state
    if (isInListMode) {
      return (
        <button onClick={ this.handleToggleMode }>
          <img src={ images['map'] } alt="map" />
          <span>{ LiteralsService.get('seeMap', true) }</span>
        </button>
      )
    } else {
      return (
        <button onClick={ this.handleToggleMode }>
          <img src={ images['list'] } alt="list" />
          <span>{ LiteralsService.get('seeList', true) }</span>
        </button>
      )
    }
  }

  private get isInvalidLocation(): boolean {
    const { services, coordinates, searchedType } = this.props
    const hasCoordinates = !!coordinates
    const hasServices = !!services.length
    const areServicesInRegion = !!coordinates && services.some(s =>
        geoContains(s.outline as any, [
            coordinates.geometry.coordinates[0],
            coordinates.geometry.coordinates[1]
        ])
    )

    return hasCoordinates && hasServices && !areServicesInRegion && searchedType !== ISearchedType.stop
  }

  private get feedback(): ReactNode {
    const {
      isSearching
    } = this.props
    const { isInListMode } = this.state

    if (isSearching) return null
    else if (isInListMode) {
      return (
        <section className="feedback-container">
          <div className="feedback">
            <span className="feedback-text" >{ this.feedbackText }</span>
          </div>
        </section>
      )
    }
  }

  private get feedbackText() {
    const { isInListMode } = this.state
    const { pickedOriginBusStop, isMarkerSelected } = this.props

    if (isInListMode) {
      return LiteralsService.get(
        !pickedOriginBusStop ? 'chooseOriginList' : 'chooseDestinationList',
        true
      )
    } else if (isMarkerSelected) {
      return LiteralsService.get(
        !pickedOriginBusStop ? 'confirmOrigin' : 'confirmDestination',
        true
      )
    } else {
      return LiteralsService.get(
        !pickedOriginBusStop ? 'chooseOriginMap' : 'chooseDestinationMap',
        true
      )
    }
  }

  private get displayNextButton(): ReactNode {
    const { isInListMode } = this.state
    const { history, isSearching, town } = this.props

    if (this.isInvalidLocation && !isInListMode && !isSearching) {
      return (
        <Button
          title={`${LiteralsService.get('recenter')} ${!town ? '' : town.name}`}
          onPress={ this.onTapReenter }
          filled
          upper
        />
      )
    } else if (!isSearching) {
      return (
        <Button
          title={ LiteralsService.get('following') }
          onPress={ () => history.push(urls.newReservationDateStep) }
          isDisabled={ !this.isEveryFieldValid }
          filled
          upper
        />
      )
    }
  }

  constructor(props: IProps) {
    super(props)

    this.state = {
      isInListMode: false,
      isUserFocused: false
    }
  }

  public componentDidMount() {
    const { dispatch } = this.props

    dispatch(fetchServicesAction())
  }

  public componentDidUpdate(prevProps: IProps) {
    const { pickedOriginBusStop, dispatch, pickedService } = this.props

    if (
      pickedOriginBusStop &&
      pickedService &&
      pickedOriginBusStop !== prevProps.pickedOriginBusStop
    ) {
      dispatch(
        fetchDestinationBusStopsAction({
          stop: pickedOriginBusStop,
          idService: pickedService
        })
      )
    }
  }

  public render(): ReactNode {
    const { isFetchingServices, services } = this.props

    if (isFetchingServices && !services.length) {
      return (
        <Page className="authenticated">
          <article className="spinner-container">
           <Spinner />
          </article>
        </Page>
      )
    }
    return (
      <Page className="authenticated">
        <StyledArticle className={ `${ this.state.isInListMode ? 'list-mode' : '' }` }>
          <header>
            <h3>
              { LiteralsService.get('desiredRoute').toUpperCase() }
            </h3>
            { this.modeButton }
          </header>
          { this.viewMode }
          <footer>
            { this.displayNextButton }
            { this.feedback }
          </footer>
        </StyledArticle>
      </Page>
    )
  }

  // TODO: Refactor this to redux
  private handleToggleMode = (): void => {
    const { isInListMode } = this.state

    this.setState({ isInListMode: !isInListMode })
  }

  private handleSearchMode = (searchMode: boolean): void => {
    this.props.dispatch(setIsSeachingAction(searchMode))
  }

  private handleStopClick = (idService: number, stop: IStop) => {
    const { dispatch } = this.props

    dispatch(setBusStopAction({ idService, stop }))
  }

  // TODO: Refactor this to container
  private onTapReenter = () => {
    this.setState(
      { isUserFocused: false },
      () => {
        const { dispatch } = this.props
        dispatch(searchStopCoordinatesAction({ type: null } as any))
      }
    )
  }

  // TODO: Refactor this to container
  private changeMapFocus = () => {
    this.setState(
      {
        isUserFocused: !this.state.isUserFocused
      },
      () => {
        const { dispatch } = this.props

        if (this.state.isUserFocused) {
          dispatch(
            searchStopCoordinatesAction({ type: ISearchedType.location } as any)
          )
        } else {
          dispatch(searchStopCoordinatesAction({ type: null } as any))
        }
      }
    )
  }

  // TODO : Take a look reset
  private handleCloseClick = (): void => {
    const { dispatch, history } = this.props

    dispatch(resetNewReservationFieldsAction())
    if (this.state.isInListMode) this.handleToggleMode()
    history.push(urls.reservations)
  }
}

const mapStateToProps = ({ newReservation, userInfo }: IStoreState) => ({
  services: newReservation.services,
  isFetchingServices: newReservation.isFetchingServices,
  pickedOriginBusStop: newReservation.pickedOriginBusStop,
  pickedDestinationBusStop: newReservation.pickedDestinationBusStop,
  pickedService: newReservation.pickedService,
  isSearching: newReservation.isSearching,
  coordinates: newReservation.coordinates,
  isPenalized: userInfo.isPenalized,
  town: userInfo.town,
  searchedType: newReservation.searchedType,
  isMarkerSelected: newReservation.isMarkerSelected
})

export default withRouter(connect(mapStateToProps)(BusStopsStep))
