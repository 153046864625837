import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { Page, Spinner } from '../../components'
import { LiteralsService, StorageService } from '../../services'
import { checkSessionAction, setChangeLanguageAction } from '../../store/actions'

type Props = DispatchProp<AnyAction>

class Splash extends PureComponent<Props> {
  public async componentDidMount() {
    const { dispatch } = this.props
    const language = await StorageService.getLanguage()

    if (language) {
      LiteralsService.setLanguage(language)
      dispatch(setChangeLanguageAction(language))
    }

    dispatch(checkSessionAction())
  }

  public render(): ReactNode {
    return (
      <Page style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Spinner />
      </Page>
    )
  }
}

export default connect()(Splash)
