import styled from 'styled-components'

export default styled.section`
  position: relative;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 35px 0 90px;
  flex: 1;

  .spinner-container {
    flex: 1;
  }

  .empty-reservations {
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 348px;
      height: auto;
      margin-bottom: 72px;
    }

    h3,
    p {
      margin: 0;
      color: ${ ({ theme }) => theme.colors.grey };
    }

    h3 {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
      text-align: center;
    }
  }
`
