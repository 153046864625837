import { INotificationState } from '../states'
import { NotificationsActionTypes, NotificationsTypeKeys } from '../types'

export const initialNotificationState: INotificationState = {
  label: '',
  isError: false,
  show: false
}

const notification = (
  state: INotificationState = initialNotificationState,
  action: NotificationsActionTypes
): INotificationState => {
  switch (action.type) {
    case NotificationsTypeKeys.SET_NOTIFICATION:
      return { ...state, ...action.payload, show: true }
    case NotificationsTypeKeys.CLEAR_NOTIFICATION:
      return { ...initialNotificationState }
    default:
      return state
  }
}

export default notification
