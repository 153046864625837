import { all } from 'redux-saga/effects'
import changePasswordSagas from './change-password'
import configurationSagas from './configuration'
import favoritesSagas from './favorites'
import newPasswordRequiredSagas from './new-password-required'
import newReservationSagas from './new-reservation'
import penaltiesSagas from './penalties'
import recoverPasswordSagas from './recover-password'
import reservationSagas from './reservations'
import signInSagas from './sign-in'
import signUpSagas from './sign-up'
import unsubscribeSagas from './unsubscribe'
import userInfoSagas from './user-info'
import verificationSagas from './verification'

export default function* rootSaga() {
  yield all([
    reservationSagas(),
    newReservationSagas(),
    penaltiesSagas(),
    favoritesSagas(),
    signUpSagas(),
    signInSagas(),
    newPasswordRequiredSagas(),
    recoverPasswordSagas(),
    verificationSagas(),
    userInfoSagas(),
    configurationSagas(),
    changePasswordSagas(),
    unsubscribeSagas()
  ])
  // yield all(sagas.map((saga: any) => fork(saga, context)))
}
