import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects'
import { urls } from '../../routing'
import { AuthenticationService, LiteralsService } from '../../services'
import {
  checkSessionAction,
  confirmVerificationCodeFailedAction,
  resendVerificationCodeFailedAction,
  resendVerificationCodeSuccessfulAction,
  setIsConfirmingVerificationCodeAction,
  setIsResendingVerificationCodeAction,
  setNotificationAction
} from '../actions'
import { IStoreState } from '../states'
import { VerificationTypeKeys } from '../types'

// Worker Saga
function* fetchVerificationCodeAsync() {
  const routerHistory = yield getContext('routerHistory')

  try {
    yield put(setIsConfirmingVerificationCodeAction())
    const dni = yield select((state: IStoreState) =>
      state.signUp.dni
    )
    const code = yield select((state: IStoreState) => state.verification.code)
    yield call(AuthenticationService.confirmSignUp, dni, code)
    try {
      const { email: username, password } = yield select(
        (state: IStoreState) => state.signUp
      )
      yield call(AuthenticationService.signIn, {
        username,
        password
      })
      yield put(checkSessionAction())
    } catch (error) {
      yield call(routerHistory.replace, urls.signIn)
    }
  } catch (error) {
    yield put(confirmVerificationCodeFailedAction())
    yield put(
      setNotificationAction({
        isError: true,
        label: LiteralsService.get('serverError', true)
      })
    )
  }
}

function* resendVerificationCodeAsync() {
  const routerHistory = yield getContext('routerHistory')

  try {
    yield put(setIsResendingVerificationCodeAction())
    const { dni } = yield select((state: IStoreState) => state.signUp)
    yield call(AuthenticationService.resendSignUpConfirmationCode, dni)
    yield put(resendVerificationCodeSuccessfulAction())
    yield put(
      setNotificationAction({
        isError: false,
        label: LiteralsService.get('verificationCodeSent', true)
      })
    )
    yield call(routerHistory.replace, urls.accountVerificationStep)
  } catch (error) {
    yield put(resendVerificationCodeFailedAction())
    yield put(
      setNotificationAction({
        isError: true,
        label: LiteralsService.get('serverError', true)
      })
    )
  }
}

// Watcher Saga:
function* watchFetchVerificationAsync() {
  yield takeLatest(VerificationTypeKeys.CONFIRM_VERIFICATION_CODE, fetchVerificationCodeAsync)
}

function* watchResendVerificationAsync() {
  yield takeLatest(VerificationTypeKeys.RESEND_VERIFICATION_CODE, resendVerificationCodeAsync)
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([watchFetchVerificationAsync(), watchResendVerificationAsync()])
}
