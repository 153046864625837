import { IUnsubscribeState } from '../states'
import { UnsubscribeActionTypes, UnsubscribeTypeKeys } from '../types'

export const initialUnsubscribeState: IUnsubscribeState = {
  isLoading: false,
  reasons: []
}

const signIn = (
  state: IUnsubscribeState = initialUnsubscribeState,
  action: UnsubscribeActionTypes
): IUnsubscribeState => {
  switch (action.type) {
    case UnsubscribeTypeKeys.IS_LOADING_UNSUBSCRIBE:
      return { ...state, isLoading: action.payload }
    case UnsubscribeTypeKeys.SET_UNSUBSCRIBE_REASONS:
      return { ...state, reasons: action.payload }
    default:
      return state
  }
}

export default signIn
