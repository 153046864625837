import styled from 'styled-components'

export default styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 35px 0 0;
  overflow-y: hidden;
  overflow-x: visible;
  flex: 1;

  .tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 800px;
    border-bottom: 2px solid ${ ({ theme }) => theme.colors.greyLight };
    margin-bottom: 44px;

    > div {
      width: 240px;
      padding: 26px 0 23px;
      text-align: center;
      border-bottom: 3px solid transparent;
      margin: 0 auto;

      &.active {
        border-bottom-color: ${ ({ theme }) => theme.colors.primary };
      }

      &:not(.active) {
        cursor: pointer;
      }

      &.divider {
        height: 24px;
        padding: 0;
        width: 2px;
        background-color: ${ ({ theme }) => theme.colors.greyLight };
        margin: 0;
      }

      span {
        text-transform: uppercase;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
        color: ${ ({ theme }) => theme.colors.greyDark };
      }
    }
  }

  .reservations {
    width: 100%;
    max-width: 800px;
    height: 100%;
    overflow-x: visible;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    > section:last-of-type {
      margin-bottom: 120px;
    }

    .spinner-container {
      flex: 1;
    }
  }

  .empty-reservations {
    /* margin-top: 10%; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;

    img {
      width: 348px;
      height: auto;
      margin-bottom: 72px;
      margin-top: 50px;
    }

    h3,
    p {
      margin: 0;
      color: ${ ({ theme }) => theme.colors.grey };
    }

    h3 {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
      text-align: center;
    }
  }
`
