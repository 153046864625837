import { SignUpStep1, SignUpStep2, SignUpStep3 } from '../pages'

export const signUpUrls = {
  signUpStep1: '/sign-up/step1',
  signUpStep2: '/sign-up/step2',
  signUpStep3: '/sign-up/step3'
}

export const signUpRoutes = [
  { content: SignUpStep1, url: signUpUrls.signUpStep1, isAuthenticated: false },
  { content: SignUpStep2, url: signUpUrls.signUpStep2, isAuthenticated: false },
  { content: SignUpStep3, url: signUpUrls.signUpStep3, isAuthenticated: false }
]
