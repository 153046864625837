import { IPenaltyModalState } from '../states'
import { PenaltyModalActionTypes, PenaltyModalTypeKeys } from '../types'

export const initialPenaltyModalState: IPenaltyModalState = {
  showModal: false
}

const signIn = (
  state: IPenaltyModalState = initialPenaltyModalState,
  action: PenaltyModalActionTypes
): IPenaltyModalState => {
  switch (action.type) {
    case PenaltyModalTypeKeys.SHOW_PENALTY_MODAL:
      return { ...state, showModal: true }
    case PenaltyModalTypeKeys.HIDE_PENALTY_MODAL:
      return { ...initialPenaltyModalState }
    default:
      return state
  }
}

export default signIn
