import React from 'react'

interface IProps {
  onClick: () => void
  isSelected: boolean
}

export const Item: React.FC<IProps> = ({ children, onClick, isSelected }) => {
  return (
    <li
      className={ `option ${ isSelected ? 'selected' : '' }` }
      onClick={ onClick }
    >
      <span>{ children }</span>
    </li>
  )
}
