import styled from 'styled-components'

const StyledTooltipContainer = styled.section`
  position: relative;
  flex: -1;
  flex-basis: 28;
  height: 28;
  margin-left: 8;

  img {
    width: 24px; 
    height: 24px;
    margin-left: 10px;
  }
`

const StyledTooltipContent = styled.section`
  display: flex;
  position: absolute;
  bottom: -78px;
  left: 0;
  width: 100%;
  padding: 10px;
  border-radius: 12px;
  background-color: ${ ({ theme }) => theme.colors.almostWhite };
  z-index: 4;
`

const StyledTooltipText = styled.p`
  color: ${ ({ theme }) => theme.colors.black };
  text-align: center;
  font-size: 14;
  font-weight: bold;
  margin: 0;
`

const StyledArrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  bottom: -23px;
  right: 7px;
  border-right-color: transparent;
  border-right-width: 8px;
  border-bottom-width: 15px;
  border-bottom-color: ${ ({ theme }) => theme.colors.almostWhite };
  border-left-width: 8px;
  border-color: transparent;
  z-index: 5;
`

export { StyledTooltipContainer, StyledTooltipContent, StyledTooltipText, StyledArrow }
