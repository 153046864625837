import { IFavoritesState } from '../states'
import { FavoritesActionTypes, FavoriteTypeKeys } from '../types'

export const initialFavoritesState: IFavoritesState = {
  favorites: [],
  isFetchingFavorites: false,

  isLoadingFavorite: false,
  selectedReservation: null,
  selectedFavorite: null
}

const favorites = (
  state: IFavoritesState = initialFavoritesState,
  action: FavoritesActionTypes
): IFavoritesState => {
  switch (action.type) {
    case FavoriteTypeKeys.FETCHING_FAVORITES:
      return { ...state, isFetchingFavorites: true }
    case FavoriteTypeKeys.FETCH_FAVORITES_SUCCESSFUL:
      return {
        ...state,
        isFetchingFavorites: false,
        favorites: action.payload
      }
    case FavoriteTypeKeys.SET_SELECTED_RESERVATION_MODAL:
      return {
        ...state,
        isLoadingFavorite: false,
        selectedReservation: action.payload
      }
    case FavoriteTypeKeys.ADDING_FAVORITE:
      return { ...state, isLoadingFavorite: true }
    case FavoriteTypeKeys.ADD_FAVORITE_SUCCESSFUL:
      return {
        ...state,
        isLoadingFavorite: false,
        selectedReservation: null
      }
    case FavoriteTypeKeys.SET_SELECTED_FAVORITE_MODAL:
      return {
        ...state,
        isLoadingFavorite: false,
        selectedFavorite: action.payload
      }
    case FavoriteTypeKeys.REMOVING_FAVORITE:
      return { ...state, isLoadingFavorite: true }
    case FavoriteTypeKeys.REMOVE_FAVORITE_SUCCESSFUL:
      return {
        ...state,
        isLoadingFavorite: false,
        selectedFavorite: null
      }
    default:
      return state
  }
}

export default favorites
