import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { images } from '../../images'
import { IMenuOption } from '../../models'
import { urls } from '../../routing'
import { LiteralsService } from '../../services'
import { signOutAction } from '../../store/actions'
import { IStoreState } from '../../store/states'
import { Dropdown } from './../../components'
import StyledSection from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  username: string
  lastname: string
}

class Menu extends PureComponent<IProps> {
  private get accountOptions(): IMenuOption[] {
    return [
      { icon: 'close', label: LiteralsService.get('closeSession', true), action: this.handleSignOut }
    ]
  }
  private get isReservationTabActive(): boolean {
    const { history } = this.props
    return history.location.pathname.includes(urls.reservations)
      || history.location.pathname.includes(urls.pastReservations)
  }

  public render(): ReactNode {
    const { history, username, lastname } = this.props
    return (
      <StyledSection>
        <section className="logo">
          <img src={ images['logoWhite'] } alt="AMB:Bus Metropolità"/>
        </section>
        <section className="navigation">
          <Link
            className={`${ this.isReservationTabActive ? 'active' : ''}`}
            to={ urls.reservations }
          >
            { LiteralsService.get('reservations') }
          </Link>
          <Link
            className={ `${ history.location.pathname.includes(urls.favorites) ? 'active' : ''}` }
            to={ urls.favorites }
          >
            { LiteralsService.get('favorites') }
          </Link>
        </section>
        <section className="account">
          <Dropdown options={ this.accountOptions }>
            <p className="username">
              <img src={ images['avatar'] } alt="User"/>
              <span>
                { username } { lastname }
              </span>
            </p>
          </Dropdown>
        </section>
      </StyledSection>
    )
  }

  private handleSignOut = () => this.props.dispatch(signOutAction())
}

const mapStateToProps = ({ userInfo }: IStoreState) => ({
  username: userInfo.firstName,
  lastname: userInfo.lastName
})

export default withRouter(connect(mapStateToProps)(Menu))
