import { IReservation } from '../../models'
import {
  IClearReservationDetailsAction,
  IDeleteReservationAction,
  IDeleteReservationSuccessfulAction,
  IFetchMoreReservationsAction,
  IFetchMoreReservationsSuccessfulAction,
  IFetchPastReservationsAction,
  IFetchPastReservationsSuccessfulAction,
  IFetchReservationDetailsAction,
  IFetchReservationDetailsSuccessfulAction,
  IFetchReservationsAction,
  IFetchReservationsSuccessfulAction,
  IHideNotificationModalAction,
  ISetExpeditionStatusAction,
  ISetFullscreenMapAction,
  ISetIsDeletingReservationAction,
  ISetIsFetchingMoreReservationsAction,
  ISetIsFetchingPastReservationsAction,
  ISetIsFetchingReservationDetailsAction,
  ISetIsFetchingReservationsAction,
  IShowNotificationModalAction,
  IUpdateReservationFavoriteFlagAction,
  ReservationsTypeKeys
} from '../types'

export function fetchReservationsAction(): IFetchReservationsAction {
  return { type: ReservationsTypeKeys.FETCH_RESERVATIONS }
}

export function setIsFetchingReservationsAction(): ISetIsFetchingReservationsAction {
  return { type: ReservationsTypeKeys.FETCHING_RESERVATIONS }
}

export function fetchReservationsSuccessfulAction(
  reservations: IReservation[]
): IFetchReservationsSuccessfulAction {
  return { type: ReservationsTypeKeys.FETCH_RESERVATIONS_SUCCESSFUL, payload: reservations }
}

export function fetchMoreReservationsAction(currentPage: number): IFetchMoreReservationsAction {
  return { type: ReservationsTypeKeys.FETCH_MORE_RESERVATIONS, payload: currentPage }
}

export function setIsMoreFetchingReservationsAction(): ISetIsFetchingMoreReservationsAction {
  return { type: ReservationsTypeKeys.FETCHING_MORE_RESERVATIONS }
}

export function fetchMoreReservationsSuccessfulAction(
  reservations: IReservation[]
): IFetchMoreReservationsSuccessfulAction {
  return { type: ReservationsTypeKeys.FETCH_MORE_RESERVATIONS_SUCCESSFUL, payload: reservations }
}

export function updateReservationFavoriteFlagAction(reservationId: number): IUpdateReservationFavoriteFlagAction {
  return { type: ReservationsTypeKeys.UPDATE_RESERVATION_FAVORITE_FLAG, payload: reservationId }
}

export function fetchPastReservationsAction(): IFetchPastReservationsAction {
  return { type: ReservationsTypeKeys.FETCH_PAST_RESERVATIONS }
}

export function setIsFetchingPastReservationsAction(): ISetIsFetchingPastReservationsAction {
  return { type: ReservationsTypeKeys.FETCHING_PAST_RESERVATIONS }
}

export function fetchPastReservationsSuccessfulAction(
  reservations: IReservation[]
): IFetchPastReservationsSuccessfulAction {
  return { type: ReservationsTypeKeys.FETCH_PAST_RESERVATIONS_SUCCESSFUL, payload: reservations }
}

export function fetchReservationDetailsAction(id: number, cancelled = false): IFetchReservationDetailsAction {
  return { type: ReservationsTypeKeys.FETCH_RESERVATION_DETAILS, payload: { id, cancelled } }
}

export function setIsFetchingReservationDetailsAction(): ISetIsFetchingReservationDetailsAction {
  return { type: ReservationsTypeKeys.FETCHING_RESERVATION_DETAILS }
}

export function fetchReservationDetailsSuccessfulAction(
  reservation: IReservation
): IFetchReservationDetailsSuccessfulAction {
  return { type: ReservationsTypeKeys.FETCH_RESERVATION_DETAILS_SUCCESSFUL, payload: reservation }
}

export function clearReservationDetailsAction(): IClearReservationDetailsAction {
  return { type: ReservationsTypeKeys.CLEAR_RESERVATION_DETAILS }
}

export function deleteReservationAction(id: number): IDeleteReservationAction {
  return { type: ReservationsTypeKeys.DELETE_RESERVATION, payload: id }
}

export function setIsDeletingReservationAction(): ISetIsDeletingReservationAction {
  return { type: ReservationsTypeKeys.DELETING_RESERVATION }
}

export function deleteReservationSuccessfulAction(): IDeleteReservationSuccessfulAction {
  return { type: ReservationsTypeKeys.DELETE_RESERVATION_SUCCESSFUL }
}

export function setExpeditionStatusAction(status: string): ISetExpeditionStatusAction {
  return {
    type: ReservationsTypeKeys.SET_EXPEDITION_STATUS,
    payload: status
  }
}

export function setFullscreenMapAction(status: boolean): ISetFullscreenMapAction {
  return {
    type: ReservationsTypeKeys.SET_FULLSCREEN_MAP,
    payload: status
  }
}

export function showNotificationModalAction(payload: any): IShowNotificationModalAction {
  return {
    type: ReservationsTypeKeys.SHOW_NOTIFICATION_MODAL,
    payload
  }
}

export function hideNotificationModalAction(): IHideNotificationModalAction {
  return {
    type: ReservationsTypeKeys.HIDE_NOTIFICATION_MODAL
  }
}
