import { DateTime } from 'luxon'
import React from 'react'
import { Button, StopsCard } from '../../../components'
import { images } from '../../../images'
import { IFavoriteResponse } from '../../../models'
import { LiteralsService } from '../../../services'
import StyledSection from './styles'

interface IProps {
  favorite: IFavoriteResponse
  onClick: () => void
  onRepeat: (favorite: IFavoriteResponse) => void
}

export const Card: React.FC<IProps> = ({ favorite, onClick, onRepeat }) => {
  console.log('Favorite', favorite)
  return (
    <StyledSection>
      <header>
        <h4>
          { favorite.name }
          <button onClick={ onClick }>
            <img
              src={ images['starSelected']}
              alt="Star Selected"
              style={{ zIndex: 10, width: '20px', height: '20px' }}
            />
          </button>
        </h4>
        <Button
          onPress={ () => onRepeat(favorite) }
          title={ LiteralsService.get('rebook', true) }
          styles={{ width: '180px' }}
          filled
          upper
        />
      </header>
      <section className="content">
        <div className="details">
          <header>
            <span className="date">
              { DateTime.fromISO(favorite.time).toLocaleString(DateTime.TIME_24_SIMPLE) }
            </span>
          </header>
          <div>
            <StopsCard
              inStopName={ favorite.inStop.properties.name }
              outStopName={ favorite.exitStop.properties.name }
            />
          </div>
        </div>
      </section>
    </StyledSection>
  )
}
