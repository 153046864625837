import styled from 'styled-components'

export default styled.section`
  padding-bottom: 12px;
  border-bottom: 4px solid ${ ({ theme }) => theme.colors.primary };
  margin-bottom: 48px;

  h2 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    color: ${ ({ theme }) => theme.colors.black };
  }
`
