import moment from 'moment'
import { repeatStringForService } from '../../helpers'
import { IService, ITown } from '../../models'
import { BaseHttpService } from './base'

class ServicesService extends BaseHttpService {
  public getServicesByServiceId = async (
    serviceId: number
  ): Promise<IService[]> => {
    return this.get(`services/${serviceId}`)
  }

  public getDestinationBusStops = async (
    serviceId: number,
    stopId: number
  ): Promise<ITown[]> => {
    return this.get(`services/${serviceId}/stops-from/${stopId}`)
  }

  public getAvailabilities = async (
    serviceId: number,
    originStopId: number,
    destinationStopId: number,
    exitSeats: number,
    exitPrmSeats: number,
    date: Date,
    specificDate: boolean
  ): Promise<IService[]> => {
    const exitString = repeatStringForService(
      '',
      destinationStopId,
      exitSeats + exitPrmSeats
    )

    let exitPrmSeatsString = ''
    if (exitPrmSeats > 0) {
      exitPrmSeatsString = repeatStringForService(
        '&prm-exit-stops=',
        destinationStopId,
        exitPrmSeats
      )
    }

    return this.get(
      `services/${serviceId}/availabilities/${originStopId}/${exitString}?date-time=${moment(
        date
      ).format(
        'YYYYMMDDHHmm'
      )}${exitPrmSeatsString}&arrival-time=${specificDate}`
    )
  }

  public getAvailabilitiesEarlier = async (
    serviceId: number,
    originStopId: number,
    destinationStopId: number,
    exitSeats: number,
    exitPrmSeats: number,
    date: Date,
    availabilityId: number,
    specificDate: boolean
  ): Promise<IService[]> => {
    const exitString = repeatStringForService(
      '',
      destinationStopId,
      exitSeats + exitPrmSeats
    )

    let exitPrmSeatsString = ''
    if (exitPrmSeats > 0) {
      exitPrmSeatsString = repeatStringForService(
        '&prm-exit-stops=',
        destinationStopId,
        exitPrmSeats
      )
    }

    return this.get(
      `services/${serviceId}/availabilities/${originStopId}/${exitString}?early=${availabilityId}&date-time=${moment(
        date
      ).format(
        'YYYYMMDDHHmm'
      )}${exitPrmSeatsString}&arrival-time=${specificDate}`
    )
  }

  public getAvailabilitiesLater = async (
    serviceId: number,
    originStopId: number,
    destinationStopId: number,
    exitSeats: number,
    exitPrmSeats: number,
    date: Date,
    availabilityId: number,
    specificDate: boolean
  ): Promise<IService[]> => {
    const exitString = repeatStringForService(
      '',
      destinationStopId,
      exitSeats + exitPrmSeats
    )

    let exitPrmSeatsString = ''
    if (exitPrmSeats > 0) {
      exitPrmSeatsString = repeatStringForService(
        '&prm-exit-stops=',
        destinationStopId,
        exitPrmSeats
      )
    }

    return this.get(
      `services/${serviceId}/availabilities/${originStopId}/${exitString}?late=${availabilityId}&date-time=${moment(
        date
      ).format(
        'YYYYMMDDHHmm'
      )}${exitPrmSeatsString}&arrival-time=${specificDate}`
    )
  }
}

export default new ServicesService()
