import styled from 'styled-components'

export default styled.li`
  width: 100%;
  height: 55px;
  border-bottom-color: ${ ({ theme }) => theme.colors.grey };
  border-bottom-width: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 50px;
  box-sizing: border-box;
  cursor: pointer;

  > div {
    flex: 1;

    > p {
      font-size: 18px;
      color:  ${ ({ theme }) => theme.colors.black };
      margin: 0;

      .code {
        color: ${ ({ theme }) => theme.colors.grey };
        font-weight: normal;
      }
    }
  }
`
