import { IReservationsState } from '../states'
import { ReservationsActionTypes, ReservationsTypeKeys } from '../types'

export const initialReservationsState: IReservationsState = {
  upComing: [],
  isFetchingReservations: false,
  isFetchingMoreReservations: false,
  isLoadMoreExist: true,
  page: 1,
  size: 10,
  pastReservations: [],
  isFetchingPastReservations: false,
  pickedReservation: null,
  isDeletingReservation: false,
  isFullscreen: false,
  isShowNotificationModal: false,
  notificationTitle: '',
  notificationText: ''
}

function updateReservationExpeditionStatus(
  state: IReservationsState,
  status: string
): IReservationsState {
  if (state.pickedReservation) {
    const pickedReservation = state.pickedReservation
    pickedReservation.expeditionStatus = status
    return { ...state, pickedReservation }
  } else return state
}

const reservations = (
  state: IReservationsState = initialReservationsState,
  action: ReservationsActionTypes
): IReservationsState => {
  let stateUpComing
  let index

  switch (action.type) {
    case ReservationsTypeKeys.FETCHING_RESERVATIONS:
      return { ...state, isFetchingReservations: true }
    case ReservationsTypeKeys.FETCH_RESERVATIONS_SUCCESSFUL:
      return {
        ...state,
        isFetchingReservations: false,
        page: 1,
        isLoadMoreExist: action.payload.length < state.size ? false : true,
        upComing: action.payload
      }
    case ReservationsTypeKeys.FETCHING_MORE_RESERVATIONS:
      return { ...state, isFetchingMoreReservations: true }
    case ReservationsTypeKeys.FETCH_MORE_RESERVATIONS_SUCCESSFUL:
      return {
        ...state,
        isFetchingMoreReservations: false,
        page: state.page + 1,
        isLoadMoreExist: action.payload.length < state.size ? false : true,
        upComing: [...state.upComing, ...action.payload]
      }
    case ReservationsTypeKeys.UPDATE_RESERVATION_FAVORITE_FLAG:
      stateUpComing = state.upComing
      index = stateUpComing.findIndex(post => post.id === action.payload)

      return {
        ...state,
        upComing: [
          ...stateUpComing.slice(0, index),
          {
            ...stateUpComing[index],
            favorite: !stateUpComing[index].favorite
          },
          ...stateUpComing.slice(index + 1)
        ]
      }
    case ReservationsTypeKeys.FETCHING_PAST_RESERVATIONS:
      return { ...state, isFetchingPastReservations: true }
    case ReservationsTypeKeys.FETCH_PAST_RESERVATIONS_SUCCESSFUL:
      return {
        ...state,
        isFetchingPastReservations: false,
        pastReservations: action.payload
      }
    case ReservationsTypeKeys.FETCH_RESERVATION_DETAILS_SUCCESSFUL:
      return {
        ...state,
        pickedReservation: action.payload
      }
    case ReservationsTypeKeys.CLEAR_RESERVATION_DETAILS:
      return {
        ...state,
        pickedReservation: null
      }
    case ReservationsTypeKeys.DELETING_RESERVATION:
      return {
        ...state,
        isDeletingReservation: true
      }
    case ReservationsTypeKeys.DELETE_RESERVATION_SUCCESSFUL:
      return {
        ...state,
        isDeletingReservation: false
      }
    case ReservationsTypeKeys.SET_EXPEDITION_STATUS:
      return updateReservationExpeditionStatus(state, action.payload)
    case ReservationsTypeKeys.SET_FULLSCREEN_MAP:
      return { ...state, isFullscreen: action.payload }
    case ReservationsTypeKeys.SHOW_NOTIFICATION_MODAL:
      return {
        ...state,
        isShowNotificationModal: true,
        notificationText: action.payload.text,
        notificationTitle: action.payload.title
      }
    case ReservationsTypeKeys.HIDE_NOTIFICATION_MODAL:
      return { ...state, isShowNotificationModal: false , notificationTitle: '', notificationText: '' }
    default:
      return state
  }
}

export default reservations
