import styled from 'styled-components'

export default styled.section`
  width: 620px;
  background-color: ${ ({ theme }) => theme.colors.white };
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media all and (max-width: 700px){
    width: 100%;
    header {
      padding: 0 10px !important;
    }
    section {
      padding: 0 10px !important;
    }
    footer {
      padding: 0 10px !important;
      button {
        width: 100px !important;
      }
    }
  }


  header {
    min-height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;

    h3 {
      color: ${ ({ theme }) => theme.colors.primary };
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0;
    }
  }

  > section {
    padding: 0 50px;
  }

  footer {
    min-height: 104px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    position: relative;

    button {
      width: 180px;
    }
  }
`
