import { all, call, put, takeLatest } from 'redux-saga/effects'
import { LiteralsService, ReservationService } from '../../services'
import {
  fetchPenaltiesSuccessfulAction,
  setIsFetchingPenaltiesAction,
  setNotificationAction
} from '../actions'
import { PenaltiesTypeKeys } from '../types'

// Worker Saga
function* fetchPenaltiesAsync() {
  let penalties = []
  try {
    yield put(setIsFetchingPenaltiesAction())
    penalties = yield call(ReservationService.getFaultReservations)
  } catch (error) {
    yield put(
      setNotificationAction({
        isError: true,
        label: LiteralsService.get('serverError', true)
      })
    )
  }
  yield put(fetchPenaltiesSuccessfulAction(penalties))
}

// Watcher Saga:
function* watchFetchPenaltiesAsync() {
  yield takeLatest(PenaltiesTypeKeys.FETCH_PENALTIES, fetchPenaltiesAsync)
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([watchFetchPenaltiesAsync()])
}
