import React, { ReactNode, CSSProperties } from 'react'
import { ImageName, images } from '../../../../images'
import { IService, IStop } from '../../../../models'
import theme from '../../../../theme'
import StyledLi from './styles'

interface IProps {
  stop: IStop
  service: IService
  onPress: (stop: IStop) => any
  destinationBusStops: IStop[]
  pickedService: number | null
  pickedOriginBusStop?: IStop
  pickedDestinationBusStop?: IStop
}

export const StopLine: React.FC<IProps> =
  ({ stop, service, onPress, destinationBusStops, pickedService, pickedOriginBusStop, pickedDestinationBusStop }) => {
    const isDifferentService = !!(pickedService && service.id !== pickedService)

    const isDisabled = (): boolean => {
      if (isDifferentService) return true

      const isSelectable = destinationBusStops.some(dbs => dbs.id === stop.id)
      const isOrigin =
        pickedOriginBusStop == null
          ? false
          : pickedOriginBusStop && pickedOriginBusStop.id === stop.id

      if (isOrigin) return false
      return destinationBusStops.length > 0 && !isSelectable
    }

    const icon = (): ImageName => {
      if (isDifferentService) return 'stopDisabled'

      const pickedOriginBusStopId = pickedOriginBusStop && pickedOriginBusStop.id
      const pickedDestinationBusStopId =
        pickedDestinationBusStop && pickedDestinationBusStop.id
      let icon = ''

      switch (stop.id) {
        case pickedOriginBusStopId:
          icon = 'stopOrigin'
          break
        case pickedDestinationBusStopId:
          icon = 'stopDestination'
          break
        default:
          icon = 'stop'
          break
      }

      if (isDisabled() && icon === 'stop') return 'stopDisabled'

      return icon as ImageName
    }

    const getTextStyle = (): CSSProperties => {
      if (isDifferentService) {
        return {
          fontSize: '18px',
          opacity: isDisabled() ? 0.5 : 1
        }
      }

      const pickedOriginBusStopId = pickedOriginBusStop && pickedOriginBusStop.id
      const pickedDestinationBusStopId =
        pickedDestinationBusStop && pickedDestinationBusStop.id

      switch (stop.id) {
        case pickedOriginBusStopId:
        case pickedDestinationBusStopId:
          return {
            fontSize: '18px',
            color: theme.colors.black
          }
        default:
          return {
            fontSize: '18px',
            opacity: isDisabled() ? 0.5 : 1
          }
      }
    }

    const codeStyle = (): CSSProperties => {
      const opacity = isDisabled() ? 0.5 : 1
      return {
        opacity,
        color: theme.colors.grey,
        margin: '0 4px'
      }
    }

    const stopCode = (): ReactNode => {
      const code = stop.code
      if (!code) return null
      return <span style={ codeStyle() }>({ code })</span>
    }

    return (
      <StyledLi
        onClick={ () => !isDisabled() && onPress(stop) }
        // disabled={ this.isDisabled }
      >
        <img src={ images[icon()] } alt={ service.name }/>
        <div className="line-info">
          <p style={ getTextStyle() }>{ stop.name } { stopCode }</p>
        </div>
      </StyledLi>
    )
}
