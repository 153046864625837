import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { Button, Page, ReservationDetailsCard, Spinner } from '../../../components'
import { IAvailability, IStop } from '../../../models'
import { LiteralsService } from '../../../services'
import { confirmNewReservationAction } from '../../../store/actions'
import { IStoreState } from '../../../store/states'
import theme from '../../../theme'
import StyledArticle from '../styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  pickedAvailability: IAvailability | null
  isConfirmingReservation: boolean
  pickedSeats: number
  pickedPrmSeats: number
  pickedService: number | null
  pickedOriginBusStop?: IStop
  pickedDestinationBusStop?: IStop
}

class ConfirmationStep extends PureComponent<IProps> {
  private get displaySaveButton(): ReactNode {
    const { isConfirmingReservation } = this.props

    if (isConfirmingReservation) return <Spinner />
    return (
      <Button
        title={ LiteralsService.get('book') }
        onPress={  this.onValidatedButtonPress }
        isDisabled={ this.props.isConfirmingReservation}
        filled
        upper
      />
    )
  }

  public render(): ReactNode {
    const {
      pickedAvailability,
      pickedSeats,
      pickedPrmSeats,
      pickedService,
      pickedOriginBusStop,
      pickedDestinationBusStop
    } = this.props

    if (!pickedAvailability) return null
    return (
      <Page className="authenticated">
        <StyledArticle>
          <header>
            <h3>
              { LiteralsService.get('routeDetails').toUpperCase() }
            </h3>
          </header>
          <ReservationDetailsCard
            availability={ pickedAvailability }
            seats={ pickedSeats }
            prmSeats={ pickedPrmSeats }
            service={ pickedService }
            busLocation={ null }
            pickedOriginBusStop={ pickedOriginBusStop }
            pickedDestinationBusStop={ pickedDestinationBusStop }
            extraInfoMessage
            aprox
          />
          <footer style={{ borderTop: '1px solid', borderTopColor: theme.colors.almostWhite }}>
            { this.displaySaveButton }
          </footer>
        </StyledArticle>
      </Page>
    )
  }

  private onValidatedButtonPress = () => {
    this.props.dispatch(confirmNewReservationAction())
  }
}

const mapStateToProps = ({ newReservation }: IStoreState) => ({
  pickedAvailability: newReservation.pickedAvailability,
  isConfirmingReservation: newReservation.isConfirmingReservation,
  pickedSeats: newReservation.pickedSeats,
  pickedPrmSeats: newReservation.pickedPrmSeats,
  pickedService: newReservation.pickedService,
  pickedOriginBusStop: newReservation.pickedOriginBusStop,
  pickedDestinationBusStop: newReservation.pickedDestinationBusStop
})

export default withRouter(connect(mapStateToProps)(ConfirmationStep))
