import React, { useEffect, useRef, useState } from 'react'
import { isClickingOutside } from '../../helpers'
import { IMenuOption } from '../../models'
import { Option } from './Option'
import StyledDiv from './styles'

interface IProps {
  options: IMenuOption[]
  isDisabled?: boolean
  isHidden?: boolean
}

export const Dropdown: React.FC<IProps> = ({ options, isDisabled, isHidden, children }) => {
  const ref = useRef<HTMLDivElement>(null)
  const listRef = useRef<HTMLUListElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => setIsOpen(current => !current)

  const handleDocumentClick =  (event: MouseEvent) => {
    if (isClickingOutside(ref, listRef, event)) setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  const menuOptions = isOpen
    ? (
        <ul className="options" ref={ listRef }>
          { options.map((o, i) => <Option key={ i } option={ o } onClick={ toggleOpen } />) }
        </ul>
      )
    : null

  if (isHidden) return null
  return (
    <StyledDiv ref={ ref }>
      <button onClick={ toggleOpen } disabled={ isDisabled }>
        { children }
      </button>
      { menuOptions }
    </StyledDiv>
  )
}

