import styled from 'styled-components'

export default styled.li`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 24px 20px;
  cursor: pointer;
  color: ${ ({ theme }) => theme.colors.greyDark };
  text-align: center;

  img {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }

  span {
    font-family: ${ ({ theme }) => theme.fontFamily };
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 20px;
  }

  &:not(.disabled):hover {
    /* background-color: ${ ({ theme }) => theme.colors.greyLight }; */
    color: ${ ({ theme }) => theme.colors.black };
  }

  &.disabled {
    user-select: none;
    pointer-events: none;
    background-color: ${ ({ theme }) => theme.colors.greyLight };
    color: ${ ({ theme }) => theme.colors.grey };
  }
`
