import { IUserInfoState } from '../states'
import { UserInfoActionTypes, UserInfoTypeKeys } from '../types'

export const initialUserInfoState: IUserInfoState = {
  id: 0,
  name: '',
  firstName: '',
  lastName: '',
  dni: '',
  email: '',
  town: { id: 0, name: '' },
  notificationFeedback: false,
  notificationReservationConfirmation: false,
  locale: 'ca',
  isPenalized: false,
  isLoadingUserInfo: false,
  experienced: true
}

const userInfo = (
  state: IUserInfoState = initialUserInfoState,
  action: UserInfoActionTypes
): IUserInfoState => {
  switch (action.type) {
    case UserInfoTypeKeys.SET_USER_INFO:
      return { ...state, ...action.payload }
    case UserInfoTypeKeys.SET_USER_INFO_EMAIL:
      return { ...state, email: action.payload }
    case UserInfoTypeKeys.SET_USER_INFO_DNI:
      return { ...state, dni: action.payload }
    case UserInfoTypeKeys.SET_USER_INFO_NAME:
      return { ...state, firstName: action.payload }
    case UserInfoTypeKeys.SET_USER_INFO_LAST_NAME:
      return { ...state, lastName: action.payload }
    case UserInfoTypeKeys.CHANGE_USER_LENGUAGE:
      return { ...state, locale: action.payload }
    case UserInfoTypeKeys.CHANGE_CONFIRMATION_FLAG:
      return {
        ...state,
        notificationReservationConfirmation: !state.notificationReservationConfirmation
      }
    case UserInfoTypeKeys.CHANGE_FEEDBACK_FLAG:
      return {
        ...state,
        notificationFeedback: !state.notificationFeedback
      }
    case UserInfoTypeKeys.SET_IS_USER_PENALIZED:
      return {
        ...state,
        isPenalized: true
      }
    case UserInfoTypeKeys.IS_LOADING_USER_INFO:
      return {
        ...state,
        isLoadingUserInfo: action.payload
      }
    case UserInfoTypeKeys.SET_IS_USER_EXPERIENCED:
      return {
        ...state,
        experienced: action.payload
      }
    default:
      return state
  }
}

export default userInfo
