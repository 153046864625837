import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { FavoritesService, LiteralsService } from '../../services'
import {
  addFavoriteSuccessfulAction,
  fetchFavoritesAction,
  fetchFavoritesSuccessfulAction,
  fetchReservationsAction,
  isAddingFavoriteAction,
  isFetchingFavoritesAction,
  isRemovingFavoriteAction,
  removeFavoriteSuccessfulAction,
  setNotificationAction
} from '../actions'
import { IStoreState } from '../states'
import { FavoriteTypeKeys } from '../types'

// Worker Saga
function* fetchFavoritesAsync() {
  let favorites = []
  yield put(isFetchingFavoritesAction())
  try {
    favorites = yield call(FavoritesService.getFavorites)
  } catch (error) {
    yield put(
      setNotificationAction({
        isError: true,
        label: LiteralsService.get('serverError', true)
      })
    )
  }
  yield put(fetchFavoritesSuccessfulAction(favorites))
}

function* addFavoriteAsync(action: any) {
  try {
    yield put(isAddingFavoriteAction())
    yield call(FavoritesService.addFavorite, action.payload)
    yield put(
      setNotificationAction({
        isError: false,
        label: LiteralsService.get('saved', true)
      })
    )
    yield put(fetchReservationsAction())
    yield put(fetchFavoritesAction())
  } catch (error) {
    yield put(
      setNotificationAction({
        isError: true,
        label: LiteralsService.get(
          error && error.message && error.message.includes(409)
            ? 'duplicatedFavorite'
            : 'serverError',
          true
        )
      })
    )
  } finally {
    yield put(addFavoriteSuccessfulAction())
  }
}

function* removeFavoriteAsync() {
  try {
    yield put(isRemovingFavoriteAction())
    const { id } = yield select(
      (state: IStoreState) => state.favorites.selectedFavorite
    )
    yield call(FavoritesService.removeFavorite, id)
    yield put(
      setNotificationAction({
        isError: false,
        label: LiteralsService.get('saved', true)
      })
    )
    yield put(fetchReservationsAction())
    yield put(fetchFavoritesAction())
  } catch (error) {
    yield put(
      setNotificationAction({
        isError: true,
        label: LiteralsService.get('serverError', true)
      })
    )
  } finally {
    yield put(removeFavoriteSuccessfulAction())
  }
}

// Watcher Saga:
function* watchFetchFavoritesAsync() {
  yield takeLatest(FavoriteTypeKeys.FETCH_FAVORITES, fetchFavoritesAsync)
}

function* watchAddFavoriteAsync() {
  yield takeLatest(FavoriteTypeKeys.ADD_FAVORITE, addFavoriteAsync)
}

function* watchRemoveFavoriteAsync() {
  yield takeLatest(FavoriteTypeKeys.REMOVE_FAVORITE, removeFavoriteAsync)
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    watchFetchFavoritesAsync(),
    watchAddFavoriteAsync(),
    watchRemoveFavoriteAsync()
  ])
}
