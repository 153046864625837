import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction } from 'redux'
import { Button, Modal, Spinner } from '../../../components'
import { IFavoriteResponse } from '../../../models'
import { LiteralsService } from '../../../services'
import { removeFavoriteAction, setSelectedFavoriteModalAction } from '../../../store/actions'
import { IStoreState } from '../../../store/states'

interface IProps extends DispatchProp<AnyAction> {
  isLoading: boolean
  selected: IFavoriteResponse | null
}

class RemoveFavoriteModal extends PureComponent<IProps> {
  private get modalContent(): ReactNode {
    const { isLoading , selected } = this.props

    if (isLoading) {
      return (
        <article className="spinner-container">
          <Spinner />
        </article>
      )
    }
    return (
      <>
        <header>
          <h3>
          { LiteralsService.get('removeFavorite', true) }:
          </h3>
        </header>
        <section className="content">
          <p>{ selected && selected.name}</p>
        </section>
        <footer>
          <Button
            onPress={ this.handleClick }
            title={ LiteralsService.get('delete', true) }
            styles={{ width: '155px' }}
            filled
          />
          <Button
            onPress={ this.handleClose }
            title={ LiteralsService.get('cancel', true) }
            styles={{ width: '155px' }}
          />
        </footer>
      </>
    )
  }

  public render(): ReactNode {
    const { selected } = this.props

    if (!selected) return null
    return (
      <Modal className="small" isHidden={ false } onOverlayClick={ this.handleClose }>
        { this.modalContent }
      </Modal>
    )
  }

  private handleClick = (): void => {
    this.props.dispatch(removeFavoriteAction())
  }

  private handleClose = (): void => {
    this.props.dispatch(setSelectedFavoriteModalAction(null))
  }
}

const mapStateToProps = ({ favorites }: IStoreState) => ({
  isLoading: favorites.isLoadingFavorite,
  selected: favorites.selectedFavorite
})

export default connect(mapStateToProps)(RemoveFavoriteModal)
