import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects'
import { urls } from '../../routing'
import { AuthenticationService, LiteralsService } from '../../services'
import {
  recoverPasswordFailedAction,
  recoverPasswordSuccessfulAction,
  requestRecoverCodeFailedAction,
  requestRecoverCodeSuccessfulAction,
  setIsRecoveringPasswordAction,
  setIsRequestingRecoverCodeAction,
  setNotificationAction
} from '../actions'
import { IStoreState } from '../states'
import { RecoverPasswordTypeKeys } from '../types'

// Worker Saga
function* requestCodeAsync() {
  const routerHistory = yield getContext('routerHistory')

  try {
    yield put(setIsRequestingRecoverCodeAction())
    const email = yield select(
      (state: IStoreState) => state.recoverPassword.email
    )
    yield call(
      AuthenticationService.requestRecoverPasswordCode,
      email
    )
    yield put(requestRecoverCodeSuccessfulAction())
    yield put(
      setNotificationAction({
        isError: false,
        label: LiteralsService.get('verificationCodeSent', true)
      })
    )
    yield call(routerHistory.push, urls.recoverPasswordRestoreStep)
  } catch (error) {
    yield put(requestRecoverCodeFailedAction(error.code))
  }
}

function* recoverPasswordAsync() {
  const routerHistory = yield getContext('routerHistory')

  try {
    yield put(setIsRecoveringPasswordAction())
    const { email, verificationCode, newPassword } = yield select(
      (state: IStoreState) => state.recoverPassword
    )

    yield call(
      AuthenticationService.requestRecoverPasswordChange,
      email,
      verificationCode,
      newPassword
    )
    yield put(recoverPasswordSuccessfulAction())
    try {
      yield call(AuthenticationService.signIn, {
        email,
        password: newPassword
      })
      yield call(routerHistory.push, urls.reservations)
    } catch (error) {
      yield call(routerHistory.push, urls.signIn)
    }
  } catch (error) {
    yield put(recoverPasswordFailedAction(error.code))
  }
}

// Watcher Saga:
function* watchRequestCodeAsync() {
  yield takeLatest(RecoverPasswordTypeKeys.REQUEST_RECOVER_CODE, requestCodeAsync)
}

// Watcher Saga:
function* watchRecoverPasswordAsync() {
  yield takeLatest(RecoverPasswordTypeKeys.RECOVER_PASSWORD, recoverPasswordAsync)
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([watchRequestCodeAsync(), watchRecoverPasswordAsync()])
}
