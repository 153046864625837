import { IPenaltiesState } from '../states'
import { PenaltiesActionTypes, PenaltiesTypeKeys } from '../types'

export const initialReservationsState: IPenaltiesState = {
  penalties: [],
  isFetchingPenalties: false
}

const penalties = (
  state: IPenaltiesState = initialReservationsState,
  action: PenaltiesActionTypes
): IPenaltiesState => {
  switch (action.type) {
    case PenaltiesTypeKeys.FETCHING_PENALTIES:
      return { ...state, isFetchingPenalties: true }
    case PenaltiesTypeKeys.FETCH_PENALTIES_SUCCESSFUL:
      return {
        ...state,
        isFetchingPenalties: false,
        penalties: action.payload
      }
    default:
      return state
  }
}

export default penalties
