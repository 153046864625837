// import { Language } from '../../services/literals-service'
import {
  ConfigurationTypeKeys,
  IChangeLanguageAction,
  IFetchAreasAction,
  IFetchAreasSuccessfulAction,
  ISetIsFetchingAreasAction,
  ISetSelectedAreaAction
} from '../types'

export function fetchAreasAction(): IFetchAreasAction {
  return { type: ConfigurationTypeKeys.FETCH_AREAS }
}

export function setIsFetchingAreasAction(): ISetIsFetchingAreasAction {
  return { type: ConfigurationTypeKeys.FETCHING_AREAS }
}

export function fetchAreasSuccessfulAction(areas: any): IFetchAreasSuccessfulAction {
  return { type: ConfigurationTypeKeys.FETCH_AREAS_SUCCESSFUL, payload: areas }
}

export function setSelectedAreaAction(selectedAreaId: number): ISetSelectedAreaAction {
  return { type: ConfigurationTypeKeys.SET_SELECTED_AREA, payload: selectedAreaId }
}

export function setChangeLanguageAction(lang: string): IChangeLanguageAction {
  return { type: ConfigurationTypeKeys.CHANGE_LANGUAGE, payload: lang }
}
