export type ImageName = keyof typeof images

export const images = {
  avatar: '/assets/images/il-avatar-white.png',
  logo: '/assets/images/logo.png',
  logoWhite: '/assets/images/logo-white.png',
  ambLogo: '/assets/images/amb-logo.png',
  noReservations: '/assets/images/il-no-reservations@2x.png',
  noOptions: '/assets/images/il-no-opciones@2x.png',
  ticket: '/assets/images/ic-ticket.png',
  plus: '/assets/images/ic-plus.png',
  plusWhite: '/assets/images/ic-plus-white.png',
  options: '/assets/images/ic-options.png',
  faceHappy: '/assets/images/ic-face-happy.png',
  starEmpty: '/assets/images/ic-star.png',
  star: '/assets/images/ic-star-grey-filled.png',
  starSelected: '/assets/images/ic-star-filled.png',
  settings: '/assets/images/ic-settings.png',
  noFavorites: '/assets/images/ic-no-favorites.png',
  back: '/assets/images/ic-back.png',
  stopDisabled: '/assets/images/ic-stop-disabled.png',
  stopDestination: '/assets/images/ic-stop-end.png',
  stopOrigin: '/assets/images/ic-stop-origin.png',
  stop: '/assets/images/ic-stop.png',
  arrowRight: '/assets/images/ic-arrow-right.png',
  close: '/assets/images/ic-close.png',
  map: '/assets/images/ic-map.png',
  list: '/assets/images/ic-list.png',
  less: '/assets/images/ic-less.png',
  more: '/assets/images/ic-more.png',
  bus: '/assets/images/ic-bus.png',
  faceMeh: '/assets/images/ic-face-meh.png',
  faceMehLightOrange: '/assets/images/ic-face-meh-light-orange.png',
  faceHappyGreen: '/assets/images/ic-face-happy-green.png',
  faceSad: '/assets/images/ic-face-sad.png',
  faceSadOrange: '/assets/images/ic-face-sad-orange.png',
  faceDizzy: '/assets/images/ic-face-dizzy.png',
  faceDizzyPink: '/assets/images/ic-face-dizzy-pink.png',
  web: '/assets/images/il-web.png',
  phone: '/assets/images/il-phone.png',
  phoneRed: '/assets/images/il-phone-red.png',
  email: '/assets/images/il-email.png',
  galileo: '/assets/images/logo_G4M.png',
  gsa: '/assets/images/gsa_logo_negatif_CMYK_300dpi_3.png',
  pedestrian: '/assets/images/ic-pedestrian.png',
  info: '/assets/images/ic-info@1,5x.png',
  nemiLogo: '/assets/images/nemi-logo@1x.png',
  penaltyCero: '/assets/images/il-incidencias-0.png',
  penaltyFirst: '/assets/images/il-incidencias-1.png',
  penaltySecond: '/assets/images/il-incidencias-2.png',
  penaltyThird: '/assets/images/il-incidencias-4.png',
  bigBus: '/assets/images/il-bus.png',
  checkBoxEmpty: '/assets/images/ic-checkbox-empty.png',
  checkBoxFilled: '/assets/images/ic-checkbox-filled.png',
  checkBoxFilledRed: '/assets/images/ic-checkbox-filledred.png',
  myLocation: '/assets/images/ic-my-location.png',
  area: '/assets/images/il-area.png',
  clear: '/assets/images/ic-clear.png',
  lens: '/assets/images/ic-lens.png',
  check: '/assets/images/ic-check.png',
  down: '/assets/images/ic-arrow-down.png',
  up: '/assets/images/ic-arrow-up.png',
  inStop: '/assets/images/ic-stop-origin.png',
  outStop: '/assets/images/ic-stop-end.png',
  pin: '/assets/images/il-pin.png',
  background: '/assets/images/map-background.png',
  teisa: '/assets/images/teisa.jpg',
  tpg: '/assets/images/tpg.gif',
  splash: '/assets/images/splash.png',
  link: '/assets/images/link.png',
  positive: '/assets/images/il-positive.png',
  negative: '/assets/images/il-negative.png'
}
