import { INotification } from '../../models'
import { IClearNotificationAction, ISetNotificationAction, NotificationsTypeKeys } from '../types'

export function setNotificationAction(notification: INotification): ISetNotificationAction {
  return { type: NotificationsTypeKeys.SET_NOTIFICATION, payload: notification }
}

export function clearNotificationAction(): IClearNotificationAction {
  return { type: NotificationsTypeKeys.CLEAR_NOTIFICATION }
}
