import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { Button, Page, Title, ValidatedInput } from '../../../components'
import { isDniValid, isTextValid } from '../../../helpers'
import { images } from '../../../images'
import { urls } from '../../../routing'
import { LiteralsService } from '../../../services'
import { setSignUpDNIAction, setSignUpLastNameAction, setSignUpNameAction } from '../../../store/actions'
import { IStoreState } from '../../../store/states'
import StyledArticle from '../styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  name: string
  lastName: string
  dni: string
}

class SignUpStep2 extends PureComponent<IProps> {
  private get buttonIsDisabled(): boolean {
    const { name, lastName, dni } = this.props

    return (
      !isTextValid(name) ||
      !isTextValid(lastName) ||
      !isDniValid(dni)
    )
  }

  private get dniInvalidError(): ReactNode {
    const dni = this.props.dni
    if (!dni || dni.length > 9 || dni.length < 9) return null
    return (!!dni && !isDniValid(dni)) && (
      <div className="error-container">
        <span className="error">
          { LiteralsService.get('dniInvalid', true) }
        </span>
      </div>
    )
  }

  public render(): ReactNode {
    const { name, lastName, dni } = this.props

    return (
      <Page>
        <StyledArticle>
          <header>
            <img src={ images['logo'] } alt="AMB:Bus Metropolità"/>
          </header>
          <form onSubmit={ e => e.preventDefault() }>
            <Title text={ LiteralsService.get('whoAreYou', true) } />
            <section>
              <ValidatedInput
                name="name"
                label={ LiteralsService.get('name', true) }
                value={ name }
                onChange={ this.onNameChange }
                error={ !isTextValid(name) }
              />
              <ValidatedInput
                name="lastName"
                label={ LiteralsService.get('lastName', true) }
                value={ lastName }
                onChange={ this.onLastNameChange }
                error={ !isTextValid(lastName) }
              />
              <ValidatedInput
                name="dni"
                label={ LiteralsService.get('dni').toUpperCase() }
                value={ dni }
                onChange={ this.onDNIChange }
                error={ !isDniValid(dni) }
                tooltip={ LiteralsService.get('dniHint', true) }
              />
              { this.dniInvalidError }
            </section>
            <section className="actions">
              <Button
                title={ LiteralsService.get('continue') }
                onPress={ this.goToStep3 }
                filled
                upper
                isDisabled={ this.buttonIsDisabled }
              />
            </section>
          </form>
        </StyledArticle>
      </Page>
    )
  }

  private onNameChange = (name: string) => {
    const { dispatch } = this.props
    dispatch(setSignUpNameAction(name))
  }

  private onLastNameChange = (lastName: string) => {
    const { dispatch } = this.props
    dispatch(setSignUpLastNameAction(lastName))
  }

  private onDNIChange = (dni: string) => {
    const { dispatch } = this.props
    dispatch(setSignUpDNIAction(dni))
  }

  private goToStep3 = () => {
    this.props.history.push(urls.signUpStep3)
  }
}

const mapStateToProps = ({ signUp }: IStoreState) => ({
  name: signUp.name,
  lastName: signUp.lastName,
  dni: signUp.dni
})

export default withRouter(connect(mapStateToProps)(SignUpStep2))
