import { IPenalty } from '../../models'

export enum PenaltiesTypeKeys {
  FETCH_PENALTIES = 'FETCH_PENALTIES',
  FETCHING_PENALTIES = 'FETCHING_PENALTIES',
  FETCH_PENALTIES_SUCCESSFUL = 'FETCH_PENALTIES_SUCCESSFUL'
}

export interface IFetchPenaltiesAction {
  type: typeof PenaltiesTypeKeys.FETCH_PENALTIES
}

export interface ISetIsFetchingPenaltiesAction {
  type: typeof PenaltiesTypeKeys.FETCHING_PENALTIES
}

export interface IFetchPenaltiesSuccessfulAction {
  type: typeof PenaltiesTypeKeys.FETCH_PENALTIES_SUCCESSFUL
  payload: IPenalty[]
}

export type PenaltiesActionTypes =
  | IFetchPenaltiesAction
  | ISetIsFetchingPenaltiesAction
  | IFetchPenaltiesSuccessfulAction
