import { IFeedback, IFeedbackOption } from '../../models'
import { BaseHttpService } from './base'

class FeedbackService extends BaseHttpService {
  public getFeedbackOptions = async (): Promise<IFeedbackOption[]> => {
    return this.get('feedback')
  }
  public getReservationFeedback = async (reservationId: number): Promise<IFeedback> => {
    return this.get(`feedback/${reservationId}`)
  }

  public sendFeedback = async (feedback: IFeedback): Promise<void> => {
    await this.post('feedback', feedback)
  }
}

export default new FeedbackService()
