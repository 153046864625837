export enum PenaltyModalTypeKeys {
  SHOW_PENALTY_MODAL = 'SHOW_PENALTY_MODAL',
  HIDE_PENALTY_MODAL = 'HIDE_PENALTY_MODAL'
}

export interface IShowPenaltyModalAction {
  type: typeof PenaltyModalTypeKeys.SHOW_PENALTY_MODAL
}

export interface IHidePenaltyModalAction {
  type: typeof PenaltyModalTypeKeys.HIDE_PENALTY_MODAL
}

export type PenaltyModalActionTypes =
  | IShowPenaltyModalAction
  | IHidePenaltyModalAction
