import styled from 'styled-components'

export default styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h4 {
    color: ${ ({ theme }) => theme.colors.black };
    font-size: 18px;
    font-weight: normal;
  }

  > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    > button {
      width: 44px;
      height: 44px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: ${ ({ theme }) => theme.colors.almostWhite };
      border: 1.5px solid ${ ({ theme }) => theme.colors.primary };
      border-radius: 22px;
      cursor: pointer;

      &:hover {
        background-color: ${ ({ theme }) => theme.colors.primary }66;
      }
      &:disabled {
        pointer-events: none;
        cursor: not-allowed;
        border-color: ${ ({ theme }) => theme.colors.grey };
      }
    }
    > span {
      margin: 0 15px;
      font-size: 18px;
    }
  }
`
