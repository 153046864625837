export enum RecoverPasswordTypeKeys {
  RESET_RECOVER_PASSWORD_FIELDS = 'RESET_RECOVER_PASSWORD_FIELDS',
  SET_RECOVER_PASSWORD_EMAIL = 'SET_RECOVER_PASSWORD_EMAIL',
  REQUEST_RECOVER_CODE = 'REQUEST_RECOVER_CODE',
  REQUESTING_RECOVER_CODE = 'REQUESTING_RECOVER_CODE',
  REQUEST_RECOVER_CODE_SUCCESSFUL = 'REQUEST_RECOVER_CODE_SUCCESSFUL',
  REQUEST_RECOVER_CODE_FAILED = 'REQUEST_RECOVER_CODE_FAILED',
  SET_RECOVER_PASSWORD_VERIFICATION_CODE = 'SET_RECOVER_PASSWORD_VERIFICATION_CODE',
  SET_RECOVER_PASSWORD_NEW_PASSWORD = 'SET_RECOVER_PASSWORD_NEW_PASSWORD',
  SET_RECOVER_PASSWORD_REPEAT_NEW_PASSWORD = 'SET_RECOVER_PASSWORD_REPEAT_NEW_PASSWORD',
  RECOVER_PASSWORD = 'RECOVER_PASSWORD',
  RECOVERING_PASSWORD = 'RECOVERING_PASSWORD',
  RECOVER_PASSWORD_SUCCESSFUL = 'RECOVER_SUCCESSFUL_PASSWORD',
  RECOVER_PASSWORD_FAILED = 'RECOVER_FAILED_PASSWORD'
}

export interface IResetRecoverPasswordFieldsAction {
  type: typeof RecoverPasswordTypeKeys.RESET_RECOVER_PASSWORD_FIELDS
}

export interface ISetRecoverPasswordEmailAction {
  type: typeof RecoverPasswordTypeKeys.SET_RECOVER_PASSWORD_EMAIL
  payload: string
}

export interface IRequestRecoverCodeAction {
  type: typeof RecoverPasswordTypeKeys.REQUEST_RECOVER_CODE
}

export interface ISetIsRequestingRecoverCodeAction {
  type: typeof RecoverPasswordTypeKeys.REQUESTING_RECOVER_CODE
}

export interface IRequestRecoverCodeSuccessfulAction {
  type: typeof RecoverPasswordTypeKeys.REQUEST_RECOVER_CODE_SUCCESSFUL
}

export interface IRequestRecoverCodeFailedAction {
  type: typeof RecoverPasswordTypeKeys.REQUEST_RECOVER_CODE_FAILED
  payload: string
}

export interface ISetRecoverPasswordVerificationCodeAction {
  type: typeof RecoverPasswordTypeKeys.SET_RECOVER_PASSWORD_VERIFICATION_CODE
  payload: string
}

export interface ISetRecoverPasswordNewPasswordAction {
  type: typeof RecoverPasswordTypeKeys.SET_RECOVER_PASSWORD_NEW_PASSWORD
  payload: string
}

export interface ISetRecoverPasswordRepeatNewPasswordAction {
  type: typeof RecoverPasswordTypeKeys.SET_RECOVER_PASSWORD_REPEAT_NEW_PASSWORD
  payload: string
}

export interface IRecoverPasswordAction {
  type: typeof RecoverPasswordTypeKeys.RECOVER_PASSWORD
}

export interface ISetIsRecoveringPasswordAction {
  type: typeof RecoverPasswordTypeKeys.RECOVERING_PASSWORD
}

export interface IRecoverPasswordSuccessfulAction {
  type: typeof RecoverPasswordTypeKeys.RECOVER_PASSWORD_SUCCESSFUL
}

export interface IRecoverPasswordFailedAction {
  type: typeof RecoverPasswordTypeKeys.RECOVER_PASSWORD_FAILED
  payload: string
}

export type RecoverPasswordActionTypes =
  | IResetRecoverPasswordFieldsAction
  | ISetRecoverPasswordEmailAction
  | IRequestRecoverCodeAction
  | ISetIsRequestingRecoverCodeAction
  | IRequestRecoverCodeSuccessfulAction
  | IRequestRecoverCodeFailedAction
  | ISetRecoverPasswordVerificationCodeAction
  | ISetRecoverPasswordNewPasswordAction
  | ISetRecoverPasswordRepeatNewPasswordAction
  | IRecoverPasswordAction
  | ISetIsRecoveringPasswordAction
  | IRecoverPasswordSuccessfulAction
  | IRecoverPasswordFailedAction
