import React from 'react'
import { images } from '../../images'
import StyledSection from './styles'

interface IProps {
  inStopName: string
  outStopName: string
  noColors?: boolean
}

export const StopsCard: React.FC<IProps> = ({ inStopName, outStopName, noColors }) => {
  return (
    <StyledSection>
      <div className="column-details">
        <img src={ images[noColors ? 'stop' : 'stopOrigin'] } alt="Origin"/>
        <div className="dashed" />
        <img src={ images[noColors ? 'stop' : 'stopDestination'] } alt="Destination"/>
      </div>
      <div className="column-details">
        <span className="stop-name">
          { inStopName }
        </span>
        <span className="stop-name">
          { outStopName }
        </span>
      </div>
    </StyledSection>
  )
}
