import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { Button, Page, Spinner } from '../../../components'
import { images } from '../../../images'
import { IAvailability, IStop } from '../../../models'
import { urls } from '../../../routing'
import { LiteralsService } from '../../../services'
import { setDestinationAvailabilityAction } from '../../../store/actions'
import { IStoreState } from '../../../store/states'
import StyledArticle from '../styles'
import { Card } from './Card'
import StyledSection from './styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  pickedOrigin?: IStop
  pickedDestination?: IStop
  pickedDate: Date
  routeOptions: IAvailability[]
  isFetchingRouteOptions: boolean
}

class RouteOptionsStep extends PureComponent<IProps> {
  private get noOptions(): ReactNode {
    return (
        <div className="no-options">
          <img src={ images['noOptions'] } alt="No options" />
          <h3>
            { LiteralsService.get('noResults', true)}
          </h3>
          <p>
            { LiteralsService.get('noResultsExplanation', true)}
          </p>
          <Button
            title={ LiteralsService.get('modifyRoute') }
            onPress={ () => this.props.history.push(urls.newReservationDateStep) }
            filled
            upper
          />
        </div>
    )
  }

  private get routeOptionsDisplay(): ReactNode {
    const { routeOptions, pickedOrigin, pickedDestination } = this.props
    return routeOptions
      .slice(1)
      .map((ro, index) => (
        <Card
          key={ `${ ro.id }-${ index }` }
          isLast={ (index + 2) === routeOptions.length }
          routeOption={ ro }
          pickedOriginBusStop={ pickedOrigin }
          pickedDestinationBusStop={ pickedDestination }
          onClick={ this.onRouteOptionClick }
        />
      ))
  }

  private get optionsDisplay(): ReactNode {
    const { pickedOrigin, pickedDestination } = this.props

    return (
      <section className="options">
        <h3>
          { LiteralsService.get('recommended', true) }
        </h3>
        <Card
          isLast={ true }
          routeOption={ this.props.routeOptions[0] }
          onClick={ this.onRouteOptionClick }
          pickedOriginBusStop={ pickedOrigin }
          pickedDestinationBusStop={ pickedDestination }
        />
      </section>
    )
  }

  private get moreOptionsDisplay(): ReactNode {
    return (
      <section className="options">
        <h3>
          { LiteralsService.get('moreOptions', true) }
        </h3>
        { this.routeOptionsDisplay }
      </section>
    )
  }

  private get content(): ReactNode {
    const { isFetchingRouteOptions, routeOptions } = this.props

    if (isFetchingRouteOptions) return <Spinner />
    else if (!routeOptions.length) return this.noOptions
    else return this.optionsDisplay
  }

  public render(): ReactNode {
    return (
      <Page className="authenticated">
        <StyledArticle>
          <header>
            <h3>
              { LiteralsService.get('desiredRoute', true).toUpperCase() }
            </h3>
          </header>
          <StyledSection>
            { this.content }
            { this.props.routeOptions.length > 1 && this.moreOptionsDisplay }
          </StyledSection>
        </StyledArticle>
      </Page>
    )
  }

  private onRouteOptionClick = (availability: IAvailability) => {
    const { dispatch, history } = this.props
    dispatch(setDestinationAvailabilityAction(availability))
    history.push(urls.newReservationConfirmationStep)
  }
}

const mapStateToProps = ({ newReservation }: IStoreState) => ({
  pickedDate: newReservation.pickedDate,
  routeOptions: newReservation.routeOptions,
  pickedOrigin: newReservation.pickedOriginBusStop,
  pickedDestination: newReservation.pickedDestinationBusStop,
  isFetchingRouteOptions: newReservation.isFetchingRouteOptions
})

export default withRouter(connect(mapStateToProps)(RouteOptionsStep))
