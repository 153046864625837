import { IAvailability, IReservation } from '../../models'
import { BaseHttpService } from './base'

class ReservationService extends BaseHttpService {
  public getReservation = async (id: number): Promise<IReservation> => {
    return this.get(`reservations/${id}`)
  }

  public getReservationStatus = async (id: number): Promise<string> => {
    return this.get(`reservations/${id}/expeditionstatus`)
  }

  public getBusPosition = async (id: number): Promise<any> => {
    return this.get(`reservations/${id}/busposition`)
  }

  public getCancelledReservation = async (
    id: number
  ): Promise<IReservation> => {
    return this.get(`reservations/cancelled/${id}`)
  }

  public getReservationWithServices = async (
    id: number
  ): Promise<IReservation> => {
    return this.get(`reservations/${id}/service`)
  }

  public getReservations = async (): Promise<IReservation[]> => {
    return this.get('reservations')
  }
  public getMoreReservations = async (
    page: number
  ): Promise<IReservation[]> => {
    return this.get(`reservations?size=10&page=${page}`)
  }

  public getCancelledReservations = async (): Promise<IReservation[]> => {
    return this.get('reservations/cancelled')
  }

  public getPastReservations = async (): Promise<IReservation[]> => {
    return this.get('reservations/history')
  }

  public getFaultReservations = async (): Promise<IReservation[]> => {
    return this.get('reservations/faults')
  }

  public postReservation = async (
    availability: IAvailability,
    seats: number,
    prmSeats: number
  ): Promise<void> => {
    const stopId = availability.outStops[0].id
    const seatsFormated = Array(seats)
      .fill({
        exitStop: { id: stopId },
        prm: false
      })
      .concat(
        Array(prmSeats).fill({
          exitStop: { id: stopId },
          prm: true
        })
      )

    await this.post('reservations', {
      availability: {
        id: availability.id
      },
      beginningStop: {
        id: availability.inStop.id
      },
      seats: seatsFormated
    })
  }

  public updateReservation = async (
    reservationId: number,
    availability: IAvailability,
    seats: number,
    prmSeats: number
  ): Promise<void> => {
    const stopId = availability.outStops[0].id
    const seatsFormated = Array(seats)
      .fill({
        exitStop: { id: stopId },
        prm: false
      })
      .concat(
        Array(prmSeats).fill({
          exitStop: { id: stopId },
          prm: true
        })
      )

    await this.put(`reservations/${reservationId}`, {
      availability: {
        id: availability.id
      },
      beginningStop: {
        id: availability.inStop.id
      },
      seats: seatsFormated
    })
  }

  public deleteReservation = async (reservationId: number): Promise<void> => {
    await this.delete(`reservations/${reservationId}`, {
      comment: 'test'
    })
  }
}

export default new ReservationService()
