import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { isChildNode } from '../../helpers'
import { images } from '../../images'
import { Dropdown } from './Dropdown'
import { Item } from './Item'
import StyledDiv from './styles'

interface IProps {
  onChange: (value: string) => void
  placeholder?: string
  className?: string
}

export const TimePicker: React.FC<IProps> = ({ onChange, placeholder, className }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [ isOpen, setIsOpen ] = useState(false)
  const [ selected, setSelected ] = useState<string>('')
  const classNames = `
    select-container
    ${ className || '' }
    ${ selected ? 'filled' : '' }
    ${ isOpen ? 'active' : '' }
  `

  const items = (): ReactNode => {
    const time: string[] = []
    let hour = 0
    let minute = 0

    do {
      if (minute !== 45) {
        minute = minute + 15
      } else {
        hour = hour + 1
        minute = 0
      }

      const hourString = hour < 10 ? `0${ hour }` : `${ hour }`
      const minuteString = minute < 10 ? `0${ minute }` : `${ minute }`
      time.push(`${ hourString }:${ minuteString }`)
    } while (time[time.length - 1] !== '23:45')

    return time.map((t, i) => (
      <Item
        key={ i }
        isSelected={ selected === t }
        onClick={ () => handleChange(t) }
      >
        { t }
      </Item>
    ))
  }

  const handleChange = (option: string) => {
    setSelected(option)
    onChange(option)
    setIsOpen(false)
  }

  const handleDocumentClick = (event: Event) => {
    if ((isOpen && !isChildNode(ref.current, event.target)) || ref.current !== event.target) setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)

    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledDiv
      ref={ ref }
      className={ classNames }
      onClick={ () => setIsOpen(current => !current) }
    >
      <input className="value" placeholder={ placeholder } readOnly value={ selected } />
      <img src={ isOpen ? images['down'] : images['up'] } style={{ width: '20px', height: '20px' }} alt="Open" />
      <Dropdown isHidden={ !isOpen }>{ items() }</Dropdown>
    </StyledDiv>
  )
}
