import styled from 'styled-components'

export default styled.button`
  font-family: ${ ({ theme }) => theme.fontFamily };
  font-weight: bold;
  height: 44px;
  width: 100%;
  border-radius: 44px;
  border-width: 1.5px;
  border: solid 1.5px ${ ({ theme }) => theme.colors.primary };
  color: ${ ({ theme }) => theme.colors.primary };
  font-size: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  /* TODO Finish styles */
  &.filled {
    color: ${ ({ theme }) => theme.colors.white };
    background-color: ${ ({ theme }) => theme.colors.primary };
    transition: ease-in-out all 0.1s;
    border-color: transparent;

    &:hover {
      background-color: ${ ({ theme }) => theme.colors.primary }B3;
    }

    &:active {
      background-color: ${ ({ theme }) => theme.colors.primary }8C;
    }

    &:disabled {
      background-color: ${ ({ theme }) => theme.colors.grey }90;
      border-color: transparent;
    }

    .icon {
      width: 16px;
      height: auto;
      margin-right: 10px;
    }
  }

  &:disabled {
    background-color: ${ ({ theme }) => theme.colors.white }90;
    cursor: not-allowed;
  }
`

