import { combineReducers } from 'redux'
import changePassword from './change-password'
import configuration from './configuration'
import favorites from './favorites'
import newPasswordRequired from './new-password-required'
import newReservation from './new-reservation'
import notification from './notification'
import penalties from './penalties'
import penaltyModal from './penalty-modal'
import recoverPassword from './recover-password'
import reservations from './reservations'
import signIn from './sign-in'
import signUp from './sign-up'
import unsubscribe from './unsubscribe'
import userInfo from './user-info'
import verification from './verification'

const createRootReducer = () => combineReducers({
  reservations,
  newReservation,
  penalties,
  favorites,
  userInfo,
  configuration,
  signUp,
  signIn,
  recoverPassword,
  verification,
  notification,
  changePassword,
  penaltyModal,
  unsubscribe,
  newPasswordRequired
})

export default createRootReducer
