import { Blocked, Favorites, NewPasswordRequired, Penalties, SignIn, Splash } from '../pages'
import { configurationRoutes, configurationUrls } from './configuration'
import { newReservationRoutes, newReservationUrls } from './new-reservation'
import { recoverPasswordRoutes, recoverPasswordUrls } from './recover-password'
import { reservationsRoutes, reservationsUrls } from './reservations'
import { signUpRoutes, signUpUrls } from './sign-up'
import { verificationRoutes, verificationUrls } from './verification'

export const urls = {
  ...newReservationUrls,
  ...reservationsUrls,
  ...configurationUrls,
  ...signUpUrls,
  ...recoverPasswordUrls,
  ...verificationUrls,
  favorites: '/favorites',
  penalties: '/penalties',
  signIn: '/sign-in',
  splash: '/',
  blocked: '/blocked',
  newPasswordRequired: '/new-password-required'
}

export const routes = [
  ...newReservationRoutes,
  ...reservationsRoutes,
  ...configurationRoutes,
  ...signUpRoutes,
  ...recoverPasswordRoutes,
  ...verificationRoutes,
  { content: Favorites, url: urls.favorites, isAuthenticated: true },
  { content: Penalties, url: urls.penalties, isAuthenticated: true },
  { content: SignIn, url: urls.signIn, isAuthenticated: false },
  { content: Splash, url: urls.splash, isAuthenticated: false },
  { content: Blocked, url: urls.blocked, isAuthenticated: false },
  { content: NewPasswordRequired, url: urls.newPasswordRequired, isAuthenticated: false }
]
