import { IBase, IFavoriteResponse, IReservation, ISearchedType, ISearchStop, IService, IStop } from '../../../models'

export enum BusStopsStepTypeKeys {
  RESET_NEW_RESERVATION_FIELDS = 'RESET_NEW_RESERVATION_FIELDS',
  SET_NEW_RESERVATION_FIELDS = 'SET_NEW_RESERVATION_FIELDS',
  SET_FAVORITE_NEW_RESERVATION_FIELDS = 'SET_FAVORITE_NEW_RESERVATION_FIELDS',
  FETCH_SERVICES = 'FETCH_SERVICES',
  FETCHING_SERVICES = 'FETCHING_SERVICES',
  FETCH_SERVICES_SUCCESSFUL = 'FETCH_SERVICES_SUCCESSFUL',
  SET_IS_SEARCHING = 'SET_IS_SEARCHING',
  SEARCH_STOPS = 'SEARCH_STOPS',
  SET_SEARCH_STOPS = 'SET_SEARCH_STOPS',
  SEARCH_STOP_COORDINATES = 'SEARCH_STOP_COORDINATES',
  SET_SEARCHED_RESULT = 'SET_SEARCHED_RESULT',
  SET_SELECT_MARKER_FLAG = 'SET_SELECT_MARKER_FLAG',
  SET_BUS_STOP = 'SET_BUS_STOP',
  FETCH_DESTINATION_BUS_STOPS = 'FETCH_DESTINATION_BUS_STOPS',
  FETCHING_DESTINATION_BUS_STOPS = 'FETCHING_DESTINATION_BUS_STOPS',
  FETCH_DESTINATION_BUS_STOPS_SUCCESSFUL = 'FETCH_DESTINATION_BUS_STOPS_SUCCESSFUL'
}

export interface IResetNewReservationFieldsAction {
  type: typeof BusStopsStepTypeKeys.RESET_NEW_RESERVATION_FIELDS
}

export interface ISetNewReservationFieldsAction {
  type: typeof BusStopsStepTypeKeys.SET_NEW_RESERVATION_FIELDS
  payload: IReservation
}

export interface ISetFavoriteNewReservationFieldsAction {
  type: typeof BusStopsStepTypeKeys.SET_FAVORITE_NEW_RESERVATION_FIELDS
  payload: IFavoriteResponse
}

export interface IFetchServicesAction {
  type: typeof BusStopsStepTypeKeys.FETCH_SERVICES
}

export interface ISetIsFetchingServicesAction {
  type: typeof BusStopsStepTypeKeys.FETCHING_SERVICES
}

export interface IFetchServicesSuccessfulAction {
  type: typeof BusStopsStepTypeKeys.FETCH_SERVICES_SUCCESSFUL
  payload: IService[]
}

export interface ISetIsSeachingAction {
  type: typeof BusStopsStepTypeKeys.SET_IS_SEARCHING
  payload: boolean
}

export interface ISearchStopsAction {
  type: typeof BusStopsStepTypeKeys.SEARCH_STOPS
  payload: string
}

export interface ISetSearchStopsAction {
  type: typeof BusStopsStepTypeKeys.SET_SEARCH_STOPS
  payload: IBase[]
}

export interface ISearchStopCoordinatesAction {
  type: typeof BusStopsStepTypeKeys.SEARCH_STOP_COORDINATES
  payload: ISearchStop
}

export interface ISetSearchedStopResultAction {
  type: typeof BusStopsStepTypeKeys.SET_SEARCHED_RESULT
  payload: { name: string; type: ISearchedType; coordinates: any }
}

export interface ISetSelectMarkerFlagAction {
  type: typeof BusStopsStepTypeKeys.SET_SELECT_MARKER_FLAG
  payload: boolean
}

export interface ISetBusStopAction {
  type: typeof BusStopsStepTypeKeys.SET_BUS_STOP
  payload: { idService: number; stop: IStop }
}

export interface IFetchDestinationBusStopsAction {
  type: typeof BusStopsStepTypeKeys.FETCH_DESTINATION_BUS_STOPS
  payload: { idService: number; stop: IStop }
}

export interface ISetIsFetchingDestinationBusStopsAction {
  type: typeof BusStopsStepTypeKeys.FETCHING_DESTINATION_BUS_STOPS
}

export interface IFetchDestinationBusStopsSuccesfulAction {
  type: typeof BusStopsStepTypeKeys.FETCH_DESTINATION_BUS_STOPS_SUCCESSFUL
  payload: IStop[]
}

export type BusStopsStepActionTypes =
 | IResetNewReservationFieldsAction
 | ISetNewReservationFieldsAction
 | ISetFavoriteNewReservationFieldsAction
 | IFetchServicesAction
 | ISetIsFetchingServicesAction
 | IFetchServicesSuccessfulAction
 | ISetIsSeachingAction
 | ISearchStopsAction
 | ISetSearchStopsAction
 | ISearchStopCoordinatesAction
 | ISetSearchedStopResultAction
 | ISetSelectMarkerFlagAction
 | ISetBusStopAction
 | IFetchDestinationBusStopsAction
 | ISetIsFetchingDestinationBusStopsAction
 | IFetchDestinationBusStopsSuccesfulAction
