import React, { createRef, PureComponent, ReactNode, RefObject } from 'react'
import MarkerClusterer from '@googlemaps/markerclustererplus'
import { flatten, getRegion } from '../../helpers'
import { ICoordinate, IService, IStop } from '../../models'
import { images } from '../../images'
import theme from '../../theme'
import StyledDiv from './styles'

interface IProps {
  path?: any
  services: IService[]
  inStop?: IStop | null
  outStop?: IStop | null
  outBusStops?: IStop[]
  busLocation?: ICoordinate | null
  correction?: number
  zoomLevel?: number
  isClusterMap?: boolean
  controlsEnabled?: boolean
  onMarkerClick?: (serviceId: number, stop: IStop) => void
}

class MapContainer extends PureComponent<IProps> {
  public map!: google.maps.Map
  private mapRef: RefObject<HTMLDivElement>
  private infoWindow!: google.maps.InfoWindow
  private markers!: google.maps.Marker[]
  private clusterer!: MarkerClusterer

  private get noOutline(): boolean {
    const { services } = this.props

    return services.every(s => !s.outline)
  }

  private get region(): any {
    const { services, isClusterMap, inStop, outStop } = this.props

    if (services && services.length && isClusterMap) {
      return getRegion({
        features: this.noOutline
          ? services
              .map(s => s.stops.map(st => st.location))
              .reduce(flatten, [])
          : services.map(s => s.outline)
      })
    } else if (!isClusterMap && inStop && outStop) {
      return getRegion({
        features: [inStop.location, outStop.location].concat()
      })
    }

    return null
  }

  constructor(props: IProps) {
    super(props)
    this.mapRef = createRef<HTMLDivElement>()
  }

  public componentDidMount(): void {
    this.drawMap()
  }

  public componentDidUpdate(prevProps: IProps): void {
    if (prevProps.outBusStops?.length !== this.props.outBusStops) this.updateMarkersIcon()
  }

  public render(): ReactNode {
    return <StyledDiv id="map" className="map" ref={ this.mapRef } />
  }

  private drawMap = async (): Promise<void> => {
    const { controlsEnabled, zoomLevel} = this.props
    //const position: Coordinates = await getPosition().then(response => response.coords)
    const latitude = this.region.latitude
    const longitude = this.region.longitude

    if (this.mapRef && this.mapRef.current) {
      this.map = new google.maps.Map(this.mapRef.current, {
        center: { lat: latitude, lng: longitude },
        controlSize: 26,
        zoom: zoomLevel ?? 14,
        zoomControl: !!controlsEnabled,
        zoomControlOptions: {
          style: 1,
          position: 3
        },
        fullscreenControl: !!controlsEnabled,
        draggable: !!controlsEnabled,
        panControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        clickableIcons: false
      })

      this.initInfoWindow()
      this.drawMarkers()
      this.drawRoute()
      this.drawServiceOutlines()
    }
  }

  private initInfoWindow = () => {
    this.infoWindow = new google.maps.InfoWindow()
  }

  private drawMarkers = (): any => {
    const { isClusterMap, services, inStop, outStop, correction } = this.props
    const latCorrection = correction ?? 0

    if (isClusterMap && services) this.fillClusterMarkers()
    else if (inStop && outStop && !isClusterMap) {
      const beginMarker = new google.maps.Marker({
        draggable: false,
        position: {
          lat: inStop.location.geometry.coordinates[1] - latCorrection,
          lng: inStop.location.geometry.coordinates[0]
        },
        icon: images['stopOrigin']
      })
      const endMarker = new google.maps.Marker({
        draggable: false,
        position: {
          lat: outStop.location.geometry.coordinates[1] - latCorrection,
          lng: outStop.location.geometry.coordinates[0]
        },
        icon: images['stopDestination']
      })

      beginMarker.setMap(this.map)
      endMarker.setMap(this.map)
    }
  }

  private fillClusterMarkers = (): void => {
    const { services, inStop, outStop, correction } = this.props
    const latCorrection = correction ?? 0
    this.markers = services
      .flatMap(service => {
        return service.stops.map(stop => {
          const lat = stop.location.geometry.coordinates[1] - latCorrection
          const lng = stop.location.geometry.coordinates[0]
          const isOriginStop = stop.id === inStop?.id
          const isDestinationStop = stop.id === outStop?.id
          const isStopDisabled = this.isDisabled(stop)
          const stopIcon = isOriginStop
            ? images['stopOrigin']
            : isDestinationStop
              ? images['stopDestination']
              : images['stop']
          const marker = new google.maps.Marker({
            draggable: true,
            position: { lat, lng },
            icon: isStopDisabled ? images['stopDisabled'] : stopIcon
          })
          marker.addListener('click', () => !isStopDisabled ? this.drawMarkerInfo(service.id, stop, marker) : null)
          return marker
        })
      })
      .concat()

    if (this.clusterer) {
      this.clusterer.addMarkers(this.markers)
    } else {
      this.clusterer = new MarkerClusterer(this.map, this.markers, {
        imagePath:
          'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
      })
    }
  }

  private drawMarkerInfo = (serviceId: number, stop: IStop, marker: google.maps.Marker) => {
    const { onMarkerClick } = this.props
    const contentDiv = document.createElement('div')
    contentDiv.classList.add('marker')
    contentDiv.innerHTML = `
      <span>${ stop.code ? stop.code.toString() : '' }</span>
      <span>${ stop.name }</span>
    `
    if (onMarkerClick && typeof onMarkerClick === 'function') {
      contentDiv.onclick = () =>  {
        onMarkerClick(serviceId, stop)
        // this.updateMarkersIcon()
        this.infoWindow.setOptions({  })
        this.infoWindow.close()
      }
    }

    this.infoWindow.close()
    this.infoWindow.setContent(contentDiv)
    this.infoWindow.open(this.map, marker)
  }

  private updateMarkersIcon = (): void => {
    this.clusterer.clearMarkers()
    this.markers = []
    this.fillClusterMarkers()
  }

  private drawRoute = (): void => {
    const { path } = this.props

    if (path) {
      const coordinates: google.maps.LatLngLiteral[] = path.features
        .filter((c: any) => c !== null)
        .flatMap((feature: any) => {
          return feature.geometry.coordinates.map((coord: any) => {
            return { lat: coord[1], lng: coord[0] }
          })
        })
      const busPath = new google.maps.Polyline({
        path: coordinates,
        geodesic: false,
        strokeColor: '#000000',
        strokeOpacity: 1.0,
        strokeWeight: 2.5
      })
      busPath.setMap(this.map)
    }
  }

  private drawServiceOutlines = (): void => {
    const { isClusterMap, services } = this.props
    if (isClusterMap && services) {
      services.filter(s => s.outline).forEach(service => {
        const outlineCoords: google.maps.LatLngLiteral[] = service.outline.geometry.coordinates
          .map((coord: any) => {
            return coord.map((c: any) => ({ lat: c[1], lng: c[0] }))
          })
        const outline = new google.maps.Polygon({
          paths: outlineCoords,
          strokeColor: `${this.serviceColor(service.id)}`,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: `${this.serviceColor(service.id)}30`,
          fillOpacity: 0.35
        })
        outline.setMap(this.map)
      })
    }
  }

  private serviceColor(serviceId: number): string {
    switch (serviceId) {
      case 16658:
        return theme.colors.purple
      case 16455:
        return theme.colors.orangeDark
      case 16502:
        return theme.colors.green
      case 104925:
        return theme.colors.purple
      case 148805:
        return theme.colors.green
      case 147244:
        return theme.colors.orangeDark
      default:
        return theme.colors.greyDark
    }
  }

  private isDisabled = (stop: IStop): boolean => {
    const { outBusStops, inStop } = this.props

    const isSelectable = !!outBusStops && outBusStops.some(dbs => dbs.id === stop.id)
    const isOrigin =
      inStop == null
        ? false
        : inStop && inStop.id === stop.id

    if (isOrigin) return false
    return !!outBusStops && !!outBusStops.length && !isSelectable
  }
}



export default MapContainer
