import moment from 'moment'
import React, { ReactNode, useState, CSSProperties } from 'react'
import { Button, Modal } from '../..'
import { images } from '../../../images'
import { IStop } from '../../../models'
import { LiteralsService } from '../../../services'
import theme from '../../../theme'
import StyledSection from './styles'

interface IProps {
  aprox?: boolean
  reservation?: any
  pickedOriginBusStop?: IStop
  pickedDestinationBusStop?: IStop
}

export const Stops: React.FC<IProps> = ({ aprox, reservation, pickedOriginBusStop, pickedDestinationBusStop }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const toggleModalVisibility = (): void => setIsModalVisible(current => !current)

  const isBeginingStopUnavailable = (): boolean => {
    const isBeginingStopDisabled = pickedOriginBusStop
      ? reservation?.beginningStop.name !== pickedOriginBusStop.name
      : false

    return !!reservation?.alternative && isBeginingStopDisabled
  }

  const isDestinationStopUnavailable = (): boolean => {
    const isDestinationStopDisabled = pickedDestinationBusStop
      ? reservation?.seats[0].exitStop.name !== pickedDestinationBusStop.name
      : false

    return !!reservation?.alternative && isDestinationStopDisabled
  }

  const stopDisabledOrigin = (): ReactNode => {
    if (!isBeginingStopUnavailable()) return null
    return (
      <>
        <img src={ images['stopDisabled'] } alt="Stop disabled" style={ { width: '20px', height: '20px' } } />
        <div className="dashed" />
      </>
    )
  }

  const stopDisabledDestination = (): ReactNode => {
    if (!isDestinationStopUnavailable()) return null
    return (
      <>
        <div className="dashed" />
        <img src={ images['stopDisabled'] } alt="Stop disabled" style={ { width: '20px', height: '20px' } } />
      </>
    )
  }

  const walkingAlternativeInStop = (): ReactNode => {
    if (!isBeginingStopUnavailable() || !pickedOriginBusStop) return null
    return (
      <div className="stop-info">
        <p className="text-top not-available">
          { LiteralsService.get('stopNotAvailable', true) }
        </p>
        <p className="text-bottom not-available">
          { pickedOriginBusStop.name }
        </p>
        <div className="row-details" style={ { marginTop: '5px' } }>
          <img src={ images['pedestrian'] } alt="Pedestrian" style={ { width: '20px', height: '20px' } } />
          <span className="text-top" style={ { marginLeft: 8 } }>
            { LiteralsService.get('walk', true) }
          </span>
        </div>
      </div>
    )
  }

  const walkingAlternativeOutStop = (): ReactNode => {
    if (!isDestinationStopUnavailable() || !pickedDestinationBusStop) return null
    return (
      <div className="stop-info">
        <div className="row-details" style={ { marginBottom: 5 } }>
          <img src={ images['pedestrian'] } alt="Pedestrian" style={ { width: '20px', height: '20px' } } />
          <span className="text-top" style={ { marginLeft: 8 } }>
            { LiteralsService.get('walk', true) }
          </span>
        </div>
        <p className="text-top not-available">
          { LiteralsService.get('stopNotAvailable', true) }
        </p>
        <p className="text-bottom not-available">
          { pickedDestinationBusStop.name }
        </p>
      </div>
    )
  }

  const infoIconBegining = (): ReactNode => {
    if (!isBeginingStopUnavailable()) return null
    return (
      <div className="hour" style={{ alignSelf: 'flex-end' }}>
        <button onClick={ toggleModalVisibility }>
          <img src={ images['info'] } alt="info" style={ { width: '20px', height: '20px' } } />
        </button>
      </div>
    )
  }

 const infoIconStop = (): ReactNode => {
    if (!isDestinationStopUnavailable()) return null
    // tslint:disable: jsx-no-bind
    return (
      <div className="hour" style={{ justifyContent: 'flex-end', alignSelf: 'flex-end'}}>
        <button onClick={ toggleModalVisibility }>
          <img src={ images['info'] } alt="info" style={ { width: '20px', height: '20px' } } />
        </button>
      </div>
    )
  }

  const beginingStopStyles = (): CSSProperties => {
    const justifyContent = isBeginingStopUnavailable() ? 'center' : 'flex-start'
    return {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '5px',
      minHeight: '65px',
      justifyContent
     }
  }

  const destinationStopStyles = (): CSSProperties => {
    const justifyContent = isDestinationStopUnavailable() ? 'center' : 'flex-end'
    return {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '5px',
      minHeight: '65px',
      justifyContent
     }
  }

  const beginingStopHourStyles = (): CSSProperties => {
    const justifyContent = isBeginingStopUnavailable() ? 'center' : 'flex-start'
    return {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '5px',
      minHeight: '65px',
      justifyContent
     }
  }

  const destinationStopHourStyles = (): CSSProperties => {
    const justifyContent = isDestinationStopUnavailable() ? 'center' : 'flex-end'
    return { justifyContent, minHeight: '65px', display: 'flex', alignItems: 'flex-end' }
  }

  const exitTime = (): string => {
    // const minDateTime = moment(reservation.beginningStop.minDateTime)
    // const maxDateTime = moment(reservation.beginningStop.maxDateTime)
    // const timeDifference = moment.duration(maxDateTime.diff(minDateTime)).asMinutes() / 2
    const exitTime = reservation?.beginningStop.dateTime ?? pickedOriginBusStop?.dateTime
    return moment(exitTime).format('HH:mm')
    // return aprox ? exitTime.add(timeDifference, 'm').format('HH:mm') : exitTime.format('HH:mm')
  }

  const arrivalTime = (): string => {
    // const minDateTime = moment(reservation.seats[0].exitStop.minDateTime)
    // const maxDateTime = moment(reservation.seats[0].exitStop.maxDateTime)
    // const timeDifference = moment.duration(maxDateTime.diff(minDateTime)).asMinutes() / 2
    const arrivalTime = reservation?.seats[0].exitStop.dateTime ?? pickedDestinationBusStop?.dateTime
    return moment(arrivalTime).format('HH:mm')
    // return aprox ? arrivalTime.add(timeDifference, 'm').format('HH:mm') : arrivalTime.format('HH:mm')
  }

  return (
    <>
      <StyledSection>
        <div className="row-details">
          <div className="column-details">
            { stopDisabledOrigin() }
            <img src={ images['stopOrigin'] } alt="Stop origin" style={{ width: '20px', height: '20px' }}/>
            <div className="dashed" />
            <img src={ images['stopDestination'] } alt="Stop destination" style={{ width: '20px', height: '20px' }}/>
            { stopDisabledDestination() }
          </div>
          <div className="column-details" style={{ justifyContent: 'space-between', flex: 1 }}>
            { walkingAlternativeInStop() }
            <div style={ beginingStopStyles() }>
              <p className="text-top">
                { LiteralsService.get('exit', true) }
              </p>
              <p className="text-bottom">
                { reservation?.beginningStop.name || pickedOriginBusStop?.name }
              </p>
            </div>
            <div style={ destinationStopStyles() }>
              <p className="text-top">
                { LiteralsService.get('arrival', true) }
              </p>
              <p className="text-bottom">
                { reservation?.seats[0].exitStop.name || pickedDestinationBusStop?.name }
              </p>
            </div>
            { walkingAlternativeOutStop() }
          </div>
          <div className="column-details" style={{ alignItems: 'flex-end', justifyContent: 'space-between' }}>
            { infoIconBegining() }
            <div style={ beginingStopHourStyles() }>
              <span className="hour-text">
                { aprox && `${ LiteralsService.get('aprox', true) } ` }
                { exitTime() }
              </span>
            </div>
            <div style={ destinationStopHourStyles() }>
              <span className="hour-text">
                { aprox && `${ LiteralsService.get('aprox', true) } ` }
                { arrivalTime() }
              </span>
            </div>
            { infoIconStop() }
          </div>
        </div>
      </StyledSection>
      <Modal className="small" isHidden={ !isModalVisible } onOverlayClick={ toggleModalVisibility }>
        <section className="content">
          <p style={ { fontFamily: theme.fontFamily, fontSize: '18px', textAlign: 'justify' } }>
            { LiteralsService.get('stopNotAvailableExplanation', true) }
          </p>
        </section>
        <footer>
          <Button
            title={ LiteralsService.get('close') }
            styles={{ width: '155px' }}
            onPress={ toggleModalVisibility }
            upper
          />
        </footer>
      </Modal>
    </>
  )
}
