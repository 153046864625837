import React from 'react'
import StyledSection from './styles'

interface IProps {
  text: string
}

export const Title: React.FC<IProps> = ({ text }) => {
  return (
    <StyledSection>
      <h2>{ text }</h2>
    </StyledSection>
  )
}

