import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { Button, Page, Spinner, Title } from '../../../components'
import { images } from '../../../images'
import { LiteralsService } from '../../../services'
import { setSignUpTermsAndConditionsAction, signUpAction } from '../../../store/actions'
import { IStoreState } from '../../../store/states'
import StyledArticle from '../styles'

interface IProps extends DispatchProp<AnyAction>, RouteComponentProps {
  termsAndConditions: boolean
  isSigningUp: boolean
}

class SignUpStep3 extends PureComponent<IProps> {
  private get buttonIsDisabled(): boolean {
    const { termsAndConditions, isSigningUp } = this.props
    return !termsAndConditions || isSigningUp
  }

  private get signIpButton(): ReactNode {
    const { isSigningUp } = this.props
    if (isSigningUp) return <Spinner />
    return (
      <Button
        title={ LiteralsService.get('finalize') }
        onPress={ this.validation }
        filled
        upper
        isDisabled={ this.buttonIsDisabled }
      />
    )
  }

  private get termsCheckbox(): ReactNode {
    const { termsAndConditions } = this.props
    const checkbox = termsAndConditions
      ? <img onClick={ this.onTermsAndConditionsChange } src={ images['checkBoxFilled'] } alt="AMB:Bus Metropolità"/>
      : <img onClick={ this.onTermsAndConditionsChange } src={ images['checkBoxEmpty'] } alt="AMB:Bus Metropolità"/>

    return (
      <div className="checkbox">
        { checkbox }
        <span>
          { LiteralsService.get('terms', true) }
          <Link
            className="link"
            to={{ pathname: 'http://www.amb.cat/web/mobilitat/autobus/busademanda/cervello' }}
            target="_blank"
          >
            { LiteralsService.get('termsLink', true) }
          </Link>
        </span>
      </div>
    )
  }

  public render(): ReactNode {
    return (
      <Page>
        <StyledArticle>
          <header>
            <img src={ images['logo'] } alt="AMB:Bus Metropolità"/>
          </header>
          <form onSubmit={ e => e.preventDefault() }>
            <Title text={ LiteralsService.get('termsOfUse', true) } />
            <section>
              { this.termsCheckbox }
            </section>
            <section className="actions">
              { this.signIpButton }
            </section>
          </form>
        </StyledArticle>
      </Page>
    )
  }

  private onTermsAndConditionsChange = (): void => {
    const { dispatch } = this.props
    dispatch(setSignUpTermsAndConditionsAction())
  }

  private validation = (): void => {
    const { dispatch } = this.props
    dispatch(signUpAction())
  }
}

const mapStateToProps = ({ signUp }: IStoreState) => ({
  termsAndConditions: signUp.termsAndConditions,
  isSigningUp: signUp.isSigningUp
})

export default withRouter(connect(mapStateToProps)(SignUpStep3))
