import { compareStops } from '../../helpers'
import { IFavoriteResponse, IReservation, IService, IStop } from '../../models'
import { LiteralsService } from '../../services'
import { INewReservationState } from '../states'
import {
  BusStopsStepTypeKeys,
  ConfirmStepTypeKeys,
  DateStepTypeKeys,
  NewReservationActionTypes,
  RouteOptionStepTypeKeys
} from '../types'

export const initialNewReservationState: INewReservationState = {
  isEdit: false,
  pickedReservationId: null,
  services: [],
  isFetchingServices: false,
  isSearching: false,
  nameStopSearched: '',
  stopsSearched: [],
  searchedType: null,
  coordinates: null,
  pickedService: null,
  pickedOriginBusStop: undefined,
  isFetchingDestinationBusStops: false,
  destinationBusStops: [],
  pickedDestinationBusStop: undefined,
  specificDate: false,
  pickedDate: new Date(),
  pickedSeats: 1,
  pickedPrmSeats: 0,
  isFetchingRouteOptions: false,
  routeOptions: [],
  pickedAvailability: null,
  isConfirmingReservation: false,
  isMarkerSelected: false
}

function updateBusStops(
  state: INewReservationState,
  stop: IStop,
  service: number | null
): INewReservationState {
  if (!state.pickedOriginBusStop) {
    return {
      ...state,
      pickedService: service,
      pickedOriginBusStop: stop,
      coordinates: stop.location
    }
  } else if (
    state.pickedOriginBusStop &&
    !compareStops(state.pickedOriginBusStop, stop) &&
    !state.pickedDestinationBusStop
  ) {
    return {
      ...state,
      pickedDestinationBusStop: stop
    }
  } else if (
    !!state.pickedDestinationBusStop &&
    compareStops(state.pickedDestinationBusStop, stop)
  ) {
    return {
      ...state,
      pickedDestinationBusStop:
        initialNewReservationState.pickedDestinationBusStop
    }
  }

  return {
    ...state,
    pickedService: initialNewReservationState.pickedService,
    pickedOriginBusStop: initialNewReservationState.pickedOriginBusStop,
    pickedDestinationBusStop:
      initialNewReservationState.pickedDestinationBusStop,
    destinationBusStops: initialNewReservationState.destinationBusStops
  }
}

function reservationToReservationState(
  state: INewReservationState,
  reservation: IReservation
): INewReservationState {
  return {
    ...initialNewReservationState,
    services: state.services,
    isEdit: true,
    pickedReservationId: reservation.id,
    pickedService: reservation.service.id,
    pickedOriginBusStop: reservation.beginningStop,
    pickedDestinationBusStop: reservation.seats[0].exitStop,
    pickedSeats: reservation.seats.length
  }
}

function favoriteToReservationState(
  state: INewReservationState,
  favorite: IFavoriteResponse
): INewReservationState {
  return {
    ...initialNewReservationState,
    services: state.services,
    pickedService: favorite.service.id,
    pickedOriginBusStop: {
      ...favorite.inStop.properties,
      location: favorite.inStop
    } as any,
    pickedDestinationBusStop: {
      ...favorite.exitStop.properties,
      location: favorite.exitStop
    } as any
  }
}

function updateServices(
  state: INewReservationState,
  services: IService[]
): INewReservationState {
  return {
    ...state,
    services: services.map((s) => {
      return {
        ...s,
        stops: s.stops.map((st) => {
          if (st.id > 0) return {...st, name: `${st.name} (${LiteralsService.get('finalStopTag')})`}
          return st
        })}
    })
  }
}

const newReservation = (
  state: INewReservationState = initialNewReservationState,
  action: NewReservationActionTypes
): INewReservationState => {
  switch (action.type) {
    case BusStopsStepTypeKeys.FETCHING_SERVICES:
      return { ...state, isFetchingServices: true }
    case BusStopsStepTypeKeys.FETCH_SERVICES_SUCCESSFUL:
      return {
        ...updateServices(state, Array.isArray(action.payload) ? [...action.payload] : [action.payload]),
        isFetchingServices: false
      }
    case BusStopsStepTypeKeys.SET_IS_SEARCHING: {
      return { ...state, isSearching: action.payload }
    }
    case BusStopsStepTypeKeys.SEARCH_STOPS: {
      return { ...state, nameStopSearched: action.payload }
    }
    case BusStopsStepTypeKeys.SET_SEARCH_STOPS: {
      return { ...state, stopsSearched: action.payload }
    }
    case BusStopsStepTypeKeys.SET_SEARCHED_RESULT: {
      return {
        ...state,
        searchedType: action.payload.type,
        nameStopSearched: action.payload.name,
        coordinates: action.payload.coordinates
      }
    }
    case BusStopsStepTypeKeys.SET_BUS_STOP:
      return {
        ...updateBusStops(state, action.payload.stop, action.payload.idService),
        isMarkerSelected: false
      }
    case BusStopsStepTypeKeys.FETCHING_DESTINATION_BUS_STOPS:
      return {
        ...state,
        isFetchingDestinationBusStops: true
      }
    case BusStopsStepTypeKeys.FETCH_DESTINATION_BUS_STOPS_SUCCESSFUL:
      return {
        ...state,
        isFetchingDestinationBusStops: false,
        destinationBusStops: action.payload
      }
    case DateStepTypeKeys.SET_TYPE_SPECIFIC_DATE_FLAG:
      return {
        ...state,
        specificDate: action.payload
      }
    case DateStepTypeKeys.SET_PICKED_DATE:
      return {
        ...state,
        pickedDate: action.payload
      }
    case DateStepTypeKeys.SET_PICKED_SEATS:
      return {
        ...state,
        pickedSeats: action.payload.seats,
        pickedPrmSeats: action.payload.prmSeats
      }
    case RouteOptionStepTypeKeys.FETCHING_ROUTE_OPTIONS:
      return {
        ...state,
        isFetchingRouteOptions: true
      }
    case RouteOptionStepTypeKeys.FETCH_ROUTE_OPTIONS_SUCCESSFUL:
      return {
        ...state,
        isFetchingRouteOptions: false,
        routeOptions: action.payload
      }
    case RouteOptionStepTypeKeys.SET_AVAILABILITY:
      return {
        ...state,
        pickedAvailability: action.payload
      }
    case BusStopsStepTypeKeys.RESET_NEW_RESERVATION_FIELDS:
      return {
        ...initialNewReservationState,
        services: state.services
      }
    case BusStopsStepTypeKeys.SET_NEW_RESERVATION_FIELDS:
      return reservationToReservationState(state, action.payload)
    case BusStopsStepTypeKeys.SET_FAVORITE_NEW_RESERVATION_FIELDS:
      return favoriteToReservationState(state, action.payload)
    case ConfirmStepTypeKeys.CONFIRMING_NEW_RESERVATION:
      return {
        ...state,
        isConfirmingReservation: true
      }
    case ConfirmStepTypeKeys.CONFIRM_NEW_RESERVATION_SUCCESSFUL:
      return {
        ...state,
        isConfirmingReservation: false
      }
    case BusStopsStepTypeKeys.SET_SELECT_MARKER_FLAG:
      return { ...state, isMarkerSelected: action.payload }
    default:
      return state
  }
}

export default newReservation
