import styled from 'styled-components'

const StyledCalendarContainer = styled.section`
  flex: 1;
  overflow: hidden;

  .react-calendar {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 20px 112px;
    border: none;
    font-family: ${ ({ theme }) => theme.fontFamily };
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    @media all and (max-width: 700px){
      padding: 20px 30px;
    }

    .react-calendar__navigation__label__labelText {
      font-family: ${ ({ theme }) => theme.fontFamily };
      text-transform: capitalize;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
    }

    .react-calendar__navigation__arrow {
      font-family: ${ ({ theme }) => theme.fontFamily };
      text-transform: capitalize;
      font-size: 24px;
      letter-spacing: 0;
      line-height: 24px;
    }

    .react-calendar__viewContainer {
      flex: 1;

      .react-calendar__month-view,
      .react-calendar__year-view
      .react-calendar__century-view {
        height: 100%;

        > div {
          align-items: stretch !important;
          height: 100%;
          justify-content: stretch;
        }
      }

      .react-calendar__month-view__weekdays__weekday {
        font-family: ${ ({ theme }) => theme.fontFamily };
        font-size: 14px;
        font-weight: normal;
      }

      .react-calendar__month-view__days {
        /* height: 100%; */

        .react-calendar__month-view__days__day {
          font-family: ${ ({ theme }) => theme.fontFamily };
          font-size: 18px;
        }
      }
    }

    .react-calendar__tile {
      border-radius: 6px;
    }
    .react-calendar__tile--now {
      background: ${ ({ theme }) => theme.colors.primary }66;
    }
    .react-calendar__tile--active {
      background: ${ ({ theme }) => theme.colors.primary };
    }
  }
`

const StyledTimeContainer = styled.section`
  min-height: 80px;
  padding: 0 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${ ({ theme }) => theme.colors.almostWhite };

  @media all and (max-width: 700px){
    display: block;
    flex-direction: row;
    justify-content: center;
    h3 {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .hour {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  
  h3 {
    color: ${ ({ theme }) => theme.colors.primary };
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    margin: 0;
  }

  .time-flag {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    > button {
      font-size: 14px;
      width: 170px;

      &:first-of-type {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:last-of-type {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
`

const StyledSeatsContainer = styled.section`
  min-height: 80px;
  padding: 0 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${ ({ theme }) => theme.colors.almostWhite };
  
  > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    p {
      margin: 0 15px 0 0;
    }
  }

  button {
    width: 180px;
  }
`

export { StyledCalendarContainer, StyledSeatsContainer, StyledTimeContainer }

