import { IAvailability } from '../../../models'

export enum RouteOptionStepTypeKeys {
  FETCH_ROUTE_OPTIONS = 'FETCH_ROUTE_OPTIONS',
  FETCH_EARLIER_ROUTE_OPTIONS = 'FETCH_EARLIER_ROUTE_OPTIONS',
  FETCH_LATER_ROUTE_OPTIONS = 'FETCH_LATER_ROUTE_OPTIONS',
  FETCHING_ROUTE_OPTIONS = 'FETCHING_ROUTE_OPTIONS',
  FETCH_ROUTE_OPTIONS_SUCCESSFUL = 'FETCH_ROUTE_OPTIONS_SUCCESSFUL',
  SET_AVAILABILITY = 'SET_AVAILABILITY'
}

export interface IFetchRouteOptionsAction {
  type: typeof RouteOptionStepTypeKeys.FETCH_ROUTE_OPTIONS
}

export interface IFetchEearlierRouteOptionsAction {
  type: typeof RouteOptionStepTypeKeys.FETCH_EARLIER_ROUTE_OPTIONS
}

export interface IFetchLaterRouteOptionsAction {
  type: typeof RouteOptionStepTypeKeys.FETCH_LATER_ROUTE_OPTIONS
}

export interface ISetIsFetchingRouteOptionsAction {
  type: typeof RouteOptionStepTypeKeys.FETCHING_ROUTE_OPTIONS
}

export interface IFetchRouteOptionsSuccessfulAction {
  type: typeof RouteOptionStepTypeKeys.FETCH_ROUTE_OPTIONS_SUCCESSFUL
  payload: IAvailability[]
}

export interface ISetAvailabilityAction {
  type: typeof RouteOptionStepTypeKeys.SET_AVAILABILITY
  payload: IAvailability
}

export type RouteOptionStepActionTypes =
  | IFetchRouteOptionsAction
  | IFetchEearlierRouteOptionsAction
  | IFetchLaterRouteOptionsAction
  | ISetIsFetchingRouteOptionsAction
  | IFetchRouteOptionsSuccessfulAction
  | ISetAvailabilityAction
