import { DetailsFullscreenMap, PastReservations, ReservationDetails, Reservations } from '../pages'

export const reservationsUrls = {
  reservations: '/reservations',
  pastReservations: '/past-reservations',
  reservationDetails: '/reservation',
  detailsFullscreenMap: '/details-fullscreen-Map'
}

export const reservationsRoutes = [
  { content: Reservations, url: reservationsUrls.reservations, isAuthenticated: true },
  { content: PastReservations, url: reservationsUrls.pastReservations, isAuthenticated: true },
  { content: ReservationDetails, url: reservationsUrls.reservationDetails, isAuthenticated: true },
  { content: DetailsFullscreenMap, url: reservationsUrls.detailsFullscreenMap, isAuthenticated: true }
]
