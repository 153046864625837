import { IPenalty } from '../../models'
import {
  IFetchPenaltiesAction,
  IFetchPenaltiesSuccessfulAction,
  ISetIsFetchingPenaltiesAction,
  PenaltiesTypeKeys
} from '../types'

export function fetchPenaltiesAction(): IFetchPenaltiesAction {
  return { type: PenaltiesTypeKeys.FETCH_PENALTIES }
}

export function setIsFetchingPenaltiesAction(): ISetIsFetchingPenaltiesAction {
  return { type: PenaltiesTypeKeys.FETCHING_PENALTIES }
}

export function fetchPenaltiesSuccessfulAction(penalties: IPenalty[]): IFetchPenaltiesSuccessfulAction {
  return { type: PenaltiesTypeKeys.FETCH_PENALTIES_SUCCESSFUL, payload: penalties }
}
