export enum ChangePasswordTypeKeys {
  SET_CHANGE_PASSWORD = 'SET_CHANGE_PASSWORD',
  SET_CHANGE_NEW_PASSWORD = 'SET_CHANGE_NEW_PASSWORD',
  SET_CHANGE_REPEAT_NEW_PASSWORD = 'SET_CHANGE_REPEAT_NEW_PASSWORD',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CHANGING_PASSWORD = 'CHANGING_PASSWORD',
  CHANGED_PASSWORD_SUCCESSFULL = 'CHANGED_PASSWORD_SUCCESSFULL',
  CHANGED_PASSWORD_FAILED = 'CHANGED_PASSWORD_FAILED'
}

export interface ISetChangePasswordAction {
  type: typeof ChangePasswordTypeKeys.SET_CHANGE_PASSWORD
  payload: string
}

export interface ISetChangeNewPasswordAction {
  type: typeof ChangePasswordTypeKeys.SET_CHANGE_NEW_PASSWORD
  payload: string
}

export interface ISetChangeRepeatNewPasswordAction {
  type: typeof ChangePasswordTypeKeys.SET_CHANGE_REPEAT_NEW_PASSWORD
  payload: string
}

export interface IChangePasswordAction {
  type: typeof ChangePasswordTypeKeys.CHANGE_PASSWORD
}

export interface ISetIsChangingPasswordAction {
  type: typeof ChangePasswordTypeKeys.CHANGING_PASSWORD
}

export interface IChangePasswordSuccessfullAction {
  type: typeof ChangePasswordTypeKeys.CHANGED_PASSWORD_SUCCESSFULL
}

export interface IChangePasswordFailedAction {
  type: typeof ChangePasswordTypeKeys.CHANGED_PASSWORD_FAILED
}

export type ChangePasswordActionTypes =
 | ISetChangePasswordAction
 | ISetChangeNewPasswordAction
 | ISetChangeRepeatNewPasswordAction
 | IChangePasswordAction
 | ISetIsChangingPasswordAction
 | IChangePasswordSuccessfullAction
 | IChangePasswordFailedAction
