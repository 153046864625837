export enum ConfirmStepTypeKeys {
  CONFIRM_NEW_RESERVATION = 'CONFIRM_NEW_RESERVATION',
  CONFIRMING_NEW_RESERVATION = 'CONFIRMING_NEW_RESERVATION',
  CONFIRM_NEW_RESERVATION_SUCCESSFUL = 'CONFIRM_NEW_RESERVATION_SUCCESSFUL'
}

export interface IConfirmNewReservationAction {
  type: typeof ConfirmStepTypeKeys.CONFIRM_NEW_RESERVATION
}

export interface ISetIsConfirmingNewReservationAction {
  type: typeof ConfirmStepTypeKeys.CONFIRMING_NEW_RESERVATION
}

export interface IConfirmNewReservationSuccessfulAction {
  type: typeof ConfirmStepTypeKeys.CONFIRM_NEW_RESERVATION_SUCCESSFUL
}

export type ConfirmStepActionTypes =
  | IConfirmNewReservationAction
  | ISetIsConfirmingNewReservationAction
  | IConfirmNewReservationSuccessfulAction
