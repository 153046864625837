import {
  RecoverPasswordRequestCodeStep,
  RecoverPasswordRestoreStep
} from '../pages'

export const recoverPasswordUrls = {
  recoverPasswordRequestCodeStep: '/recover-password/request-code',
  recoverPasswordRestoreStep: '/recover-password/restore'
}

export const recoverPasswordRoutes = [
  {
    content: RecoverPasswordRequestCodeStep,
    url: recoverPasswordUrls.recoverPasswordRequestCodeStep,
    isAuthenticated: false
  },
  {
    content: RecoverPasswordRestoreStep,
    url: recoverPasswordUrls.recoverPasswordRestoreStep,
    isAuthenticated: false
  }
]
