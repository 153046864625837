import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import * as serviceWorker from './serviceWorker'
import App from './App'
import { ProviderWithRouter } from './components'
import theme from './theme'
import './styles/index.css'


ReactDOM.render(
    <ThemeProvider theme={ theme }>
      <BrowserRouter>
        <ProviderWithRouter>
          <App />
        </ProviderWithRouter>
      </BrowserRouter>
    </ThemeProvider>,
  document.getElementById('nemi-amb-web')
)
serviceWorker.unregister()
