import React, { CSSProperties, ReactNode } from 'react'
import { ImageName, images } from '../../images'
import StyledLi from './styles'

interface IProps {
  label: string
  onClick: () => void
  conatinerStyles?: CSSProperties
  icon?: ImageName
  frontIcon?: ImageName
  code?: string
}

export const MenuItem: React.FC<IProps> = ({ label, onClick, conatinerStyles, icon, frontIcon, code }) => {
  const itemfrontIcon = (): ReactNode => {
    if (!frontIcon) return null
    return <img src={ images[frontIcon] } style={{ width: '20px', height: '20px', marginRight: 4 }} alt={ label } />
  }

  const itemIcon = (): ReactNode => {
    if (!icon) return null
    return  <img src={ images[icon] } style={{ width: '20px', height: '20px' }} alt={ label } />
  }

  const handlePress = () => {
    if (onClick && typeof onClick === 'function') onClick()
  }

  return (
    <StyledLi onClick={ handlePress } style={ conatinerStyles }>
      { itemfrontIcon() }
      <div>
        <p>
          {label}
          { !!code && <span className="code"> ({ code })</span> }
        </p>
      </div>
      { itemIcon() }
    </StyledLi>
  )
}
