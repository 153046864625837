import { all, call, put, takeLatest } from 'redux-saga/effects'
import { LiteralsService, TownService } from '../../services'
import {
  fetchAreasSuccessfulAction,
  setIsFetchingAreasAction,
  setNotificationAction
} from '../actions'
import { ConfigurationTypeKeys } from '../types'

// Worker Saga
function* fetchAreasAsync() {
  try {
    yield put(setIsFetchingAreasAction())
    const areas = yield call(TownService.getTowns)
    yield put(fetchAreasSuccessfulAction(areas))
  } catch (error) {
    yield put(
      setNotificationAction({
        isError: true,
        label: LiteralsService.get('serverError', true)
      })
    )
  }
}

// Watcher Saga:
function* watchFetchAreasAsync() {
  yield takeLatest(ConfigurationTypeKeys.FETCH_AREAS, fetchAreasAsync)
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([watchFetchAreasAsync()])
}
