import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects'
import { urls } from '../../routing'
import { AuthenticationService, UserService } from '../../services'
import {
  checkSessionAction,
  clearSignInFieldsAction,
  setIsSigningInAction,
  setNewPasswordRequiredUserAction,
  setUserInfoAction,
  signInFailedAction,
  signInNewPasswordRequiredAction,
  signInSuccessfulAction
} from '../actions'
import { initialUserInfoState } from '../reducers/user-info'
import { IStoreState } from '../states'
import { SignInTypeKeys } from '../types'

// Worker Saga
function* fetchSignInAsync() {
  try {
    yield put(setIsSigningInAction())
    const { username, password } = yield select(
      (state: IStoreState) => state.signIn
    )
    const user = yield call(AuthenticationService.signIn, { username, password })
    if (user && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      yield put(signInNewPasswordRequiredAction(true))
      yield put(setNewPasswordRequiredUserAction(user))
      yield put(signInSuccessfulAction())
    } else {
      yield put(checkSessionAction())
    }
  } catch (error) {
    yield put(signInFailedAction(error))
  }
}

function* signOutAsync() {
  const routerHistory = yield getContext('routerHistory')

  try {
    yield call(UserService.signOutDevice)
    yield call(AuthenticationService.signOut)
    yield put(clearSignInFieldsAction())
    yield put(setUserInfoAction(initialUserInfoState))
    // yield put(routerHistory.push(urls.signIn))
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.log(error)
  }

  yield call(routerHistory.replace, urls.signIn)
}

function* signOutWihoutUserCredentialsAsync() {
  const routerHistory = yield getContext('routerHistory')

  try {
    yield call(AuthenticationService.signOut)
    yield put(clearSignInFieldsAction())
    yield put(setUserInfoAction(initialUserInfoState))
    yield call(routerHistory.push, urls.signIn)
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.log(error)
  }

  yield call(routerHistory.push, urls.signIn)
}

// Watcher Saga:
function* watchFetchSignInAsync() {
  yield takeLatest(SignInTypeKeys.SIGN_IN, fetchSignInAsync)
}

function* watchSignOutAsync() {
  yield takeLatest(SignInTypeKeys.SIGN_OUT, signOutAsync)
}

function* watchSsignOutAsyncWihoutUserCredentials() {
  yield takeLatest(SignInTypeKeys.SIGN_OUT_WITHOUT_USER_CREDENTIALS, signOutWihoutUserCredentialsAsync)
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([watchFetchSignInAsync(), watchSignOutAsync(), watchSsignOutAsyncWihoutUserCredentials()])
}
