import { IFavorite, IFavoriteResponse, IReservation } from '../../models'
import {
  FavoriteTypeKeys,
  IAddFavoriteAction,
  IAddFavoritesSuccessfulAction,
  IFetchFavoritesAction,
  IFetchFavoritesSuccessfulAction,
  IRemoveFavoriteAction,
  IRemoveFavoriteSuccessfulAction,
  ISetIsAddingFavoriteAction,
  ISetIsFetchingFavoritesAction,
  ISetIsRemovingFavoriteAction,
  ISetSelectedFavoriteModalAction,
  ISetSelectedReservationModalAction
} from '../types'

export function fetchFavoritesAction(): IFetchFavoritesAction {
  return { type: FavoriteTypeKeys.FETCH_FAVORITES }
}

export function isFetchingFavoritesAction(): ISetIsFetchingFavoritesAction {
  return { type: FavoriteTypeKeys.FETCHING_FAVORITES }
}

export function fetchFavoritesSuccessfulAction(favorites: IFavoriteResponse[]): IFetchFavoritesSuccessfulAction {
  return { type: FavoriteTypeKeys.FETCH_FAVORITES_SUCCESSFUL, payload: favorites }
}

export function setSelectedReservationModalAction(
  selected: IReservation | null
): ISetSelectedReservationModalAction {
  return {
    type: FavoriteTypeKeys.SET_SELECTED_RESERVATION_MODAL,
    payload: selected
  }
}

export function setSelectedFavoriteModalAction(
  selected: IFavoriteResponse | null
): ISetSelectedFavoriteModalAction {
  return {
    type: FavoriteTypeKeys.SET_SELECTED_FAVORITE_MODAL,
    payload: selected
  }
}

export function addFavoriteAction(favorite: IFavorite): IAddFavoriteAction {
  return {
    type: FavoriteTypeKeys.ADD_FAVORITE,
    payload: favorite
  }
}

export function isAddingFavoriteAction(): ISetIsAddingFavoriteAction {
  return { type: FavoriteTypeKeys.ADDING_FAVORITE }
}

export function addFavoriteSuccessfulAction(): IAddFavoritesSuccessfulAction {
  return { type: FavoriteTypeKeys.ADD_FAVORITE_SUCCESSFUL }
}

export function removeFavoriteAction(): IRemoveFavoriteAction {
  return { type: FavoriteTypeKeys.REMOVE_FAVORITE }
}

export function isRemovingFavoriteAction(): ISetIsRemovingFavoriteAction {
  return { type: FavoriteTypeKeys.REMOVING_FAVORITE }
}

export function removeFavoriteSuccessfulAction(): IRemoveFavoriteSuccessfulAction {
  return { type: FavoriteTypeKeys.REMOVE_FAVORITE_SUCCESSFUL }
}
