import styled from 'styled-components'

export default styled.section`
  width: 100%;
  height: 186px;
  background-color: ${ ({ theme }) => theme.colors.white };
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: ease-in box-shadow 0.1s;

  &:hover {
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.25);
  }

  .map {
    height: 100%;
    flex-basis: 348px;
    padding: 4px;
    box-sizing: border-box;
    border-radius: 3px;
    overflow: hidden;
  }

  .details {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 30px;
    box-sizing: border-box;

    header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .date {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 15px;
        color: ${ ({ theme }) => theme.colors.grey };
      }

      button.favorite {
        border: none;
        background: transparent;
        cursor: pointer;
      }
    }
  }

  .cancelled-label {
    position: absolute;
    top: 15px;
    left: 4px;
    opacity: 0.9;
    background-color: ${ ({ theme }) => theme.colors.red };
    color: ${ ({ theme }) => theme.colors.white };
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    padding-right: 16px;
    padding-left: 12px;
    height: 28px;
    justify-content: center;
    flex: 1;
  }
`
