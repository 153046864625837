import { ISignInState } from '../states'
import { SignInActionTypes, SignInTypeKeys } from '../types'

export const initialSignInState: ISignInState = {
  username: '',
  password: '',
  isSigningIn: false,
  error: false,
  isNewPasswordRequired: false
}

const signIn = (
  state: ISignInState = initialSignInState,
  action: SignInActionTypes
): ISignInState => {
  switch (action.type) {
    case SignInTypeKeys.SET_SIGN_IN_USERNAME:
      return { ...state, username: action.payload }
    case SignInTypeKeys.SET_SIGN_IN_PASSWORD:
      return { ...state, password: action.payload }
    case SignInTypeKeys.SIGNING_IN:
      return { ...state, isSigningIn: true, error: false }
    case SignInTypeKeys.SIGN_IN_SUCCESSFUL:
      return { ...state, isSigningIn: false, error: false }
    case SignInTypeKeys.SIGN_IN_FAILED:
      return { ...state, isSigningIn: false, error: true }
    case SignInTypeKeys.CLEAR_SIGN_IN_FIELDS:
      return initialSignInState
    case SignInTypeKeys.SIGN_IN_NEW_PASSWORD_REQUIRED:
      return { ...state, isNewPasswordRequired: action.payload }
    case SignInTypeKeys.CLEAR_SIGN_IN_ERROR:
      return { ...state, error: false }
    default:
      return state
  }
}

export default signIn
