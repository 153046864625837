export enum SignInTypeKeys {
  SET_SIGN_IN_USERNAME = 'SET_SIGN_IN_USERNAME',
  SET_SIGN_IN_PASSWORD = 'SET_SIGN_IN_PASSWORD',
  SIGN_IN = 'SIGN_IN',
  SIGNING_IN = 'SIGNING_IN',
  SIGN_IN_SUCCESSFUL = 'SIGN_IN_SUCCESSFUL',
  SIGN_IN_FAILED = 'SIGN_IN_FAILED',
  SIGN_OUT = 'SIGN_OUT',
  SIGN_OUT_WITHOUT_USER_CREDENTIALS = 'SIGN_OUT_WITHOUT_USER_CREDENTIALS',
  CLEAR_SIGN_IN_FIELDS = 'CLEAR_SIGN_IN_FIELDS',
  SIGN_IN_NEW_PASSWORD_REQUIRED = 'SIGN_IN_NEW_PASSWORD_REQUIRED',
  CLEAR_SIGN_IN_ERROR = 'CLEAR_SIGN_IN_ERROR'
}

export interface ISetSignInUsernameAction {
  type: typeof SignInTypeKeys.SET_SIGN_IN_USERNAME
  payload: string
}

export interface ISetSignInPasswordAction {
  type: typeof SignInTypeKeys.SET_SIGN_IN_PASSWORD
  payload: string
}

export interface ISignInAction {
  type: typeof SignInTypeKeys.SIGN_IN
}

export interface ISetIsSigningInAction {
  type: typeof SignInTypeKeys.SIGNING_IN
}

export interface ISignInSuccessfulAction {
  type: typeof SignInTypeKeys.SIGN_IN_SUCCESSFUL
}

export interface ISignInFailedAction {
  type: typeof SignInTypeKeys.SIGN_IN_FAILED
  payload: any
}

export interface ISignOutAction {
  type: typeof SignInTypeKeys.SIGN_OUT
}

export interface ISignOutWithourUserCredentialsAction {
  type: typeof SignInTypeKeys.SIGN_OUT_WITHOUT_USER_CREDENTIALS
}

export interface IClearSignInFieldsAction {
  type: typeof SignInTypeKeys.CLEAR_SIGN_IN_FIELDS
}

export interface ISignInNewPasswordRequiredAction {
  type: typeof SignInTypeKeys.SIGN_IN_NEW_PASSWORD_REQUIRED
  payload: boolean
}

export interface IClearSignInErrorAction {
  type: typeof SignInTypeKeys.CLEAR_SIGN_IN_ERROR
}

export type SignInActionTypes =
  | ISetSignInUsernameAction
  | ISetSignInPasswordAction
  | ISignInAction
  | ISetIsSigningInAction
  | ISignInSuccessfulAction
  | ISignInFailedAction
  | ISignOutAction
  | ISignOutWithourUserCredentialsAction
  | IClearSignInFieldsAction
  | ISignInNewPasswordRequiredAction
  | IClearSignInErrorAction
