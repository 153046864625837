import React, { PureComponent, ReactNode } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Page, Spinner } from '../../components'
import { images } from '../../images'
import { IFavoriteResponse } from '../../models'
import { urls } from '../../routing'
import { LiteralsService } from '../../services'
import {
  fetchFavoritesAction,
  setFavoriteNewReservationFieldsAction,
  setSelectedFavoriteModalAction
} from '../../store/actions'
import { IStoreState } from '../../store/states'
import { Card } from './Card'
import StyledSection from './styles'

interface IProps extends DispatchProp<any>, RouteComponentProps {
  isFetchingFavorites: boolean
  isPenalized: boolean
  favorites: IFavoriteResponse[]
}

class Favorites extends PureComponent<IProps> {
  private get emptyReservations(): ReactNode {
    return (
      <div className="empty-reservations">
        <img src={ images['noFavorites'] } alt={ LiteralsService.get('noReservations') }/>
        <h3>{ LiteralsService.get('noFavorites', true) }</h3>
        <p>{ LiteralsService.get('goToYourListAndMarkFavorites', true) }</p>
      </div>
    )
  }

  private get favoritesContent(): ReactNode {
    const { isFetchingFavorites, favorites, dispatch } = this.props

    if (isFetchingFavorites) {
      return (
        <article className="spinner-container">
          <Spinner />
        </article>
      )
    } else {
      if (!favorites.length) return this.emptyReservations
      return favorites.map((favorite, i) => (
        <Card
          key={ i }
          favorite={ favorite }
          onRepeat={ this.repeatFavorite }
          onClick={ () => dispatch(setSelectedFavoriteModalAction(favorite))  }
        />
      ))
    }
  }

  public componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchFavoritesAction())
  }

  public render(): ReactNode {
    return (
      <Page className="authenticated">
        <StyledSection>
          { this.favoritesContent }
        </StyledSection>
      </Page>
    )
  }

  private repeatFavorite = (favorite: IFavoriteResponse) => {
    const { dispatch, history } = this.props
    dispatch(setFavoriteNewReservationFieldsAction(favorite))
    history.push(urls.newReservationDateStep)
  }
}

const mapStateToProps = ({ favorites }: IStoreState) => ({
  isFetchingFavorites: favorites.isFetchingFavorites,
  favorites: favorites.favorites
})

export default withRouter(connect(mapStateToProps)(Favorites))
