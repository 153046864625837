import React from 'react'
import { Switch } from 'react-router-dom'
import { Route } from './components'
import {
  FavoriteModal,
  Menu,
  NotificationContainer,
  RemoveFavoriteModal
} from './containers'
import { routes } from './routing'

function App() {
  const pages = routes.map((route: any, i: number) => (
    <Route key={i} path={route.url}>
      {route.isAuthenticated ? <Menu /> : null}
      <route.content />
    </Route>
  ))

  return (
    <>
      <div id="quickAccess">
        <strong>Accessos directes:</strong>
        <ul>
          <li>
            <a
              title="V&eacute;s al contingut. (Tecla d'acc&egrave;s: 1)"
              href="#main-content"
              accessKey="1"
            >
              [1] Contingut &raquo;
            </a>
          </li>
          <li>
            <a
              title="V&eacute;s al cercador principal. (Tecla d'acc&egrave;s: 2)"
              href="#quickSearch"
              accessKey="3"
            >
              [3] Cercador
            </a>
          </li>
        </ul>
      </div>
      <div id="wrapper">
        <header className="coorp">
          <div className="module-grid">
            <div
              className="menu"
              itemScope
              itemType="http://schema.org/Organization"
            >
              <h1>
                <a
                  itemProp="url"
                  href="http://www.amb.cat/s/home.html"
                  target="_blank"
                  rel="noreferrer"
                  title="Àrea Metropolitana de Barcelona"
                >
                  <span className="nojs" itemProp="name">
                    Àrea Metropolitana de Barcelona
                  </span>
                </a>
              </h1>
            </div>
            <ul className="list menu inline eines onright">
              <li>
                <a
                  href="#tool-search"
                  role="button"
                  className="icon icon-search"
                  data-menu="tool-search"
                >
                  Buscar
                </a>
              </li>
              <li>
                <a
                  href="#tool-share"
                  role="button"
                  className="icon icon-share"
                  data-menu="tool-share"
                >
                  Compartir
                </a>
              </li>
            </ul>
          </div>
        </header>
        <div className="header-menu menu-tools" id="tool-search">
          <section className="row search" data-menu="tool-search">
            <div className="module-grid">
              <a
                href="#tool-search"
                aria-labelledby="tool-search"
                className="closemenu"
              >
                <span className="icon close">Tancar menu</span>
              </a>
              <form
                id="quickSearch"
                action="https://www.amb.cat/web/cercador"
                method="get"
              >
                <fieldset>
                  <legend>{'Cercador global de l\'AMB'}</legend>
                  <label htmlFor="queryText" className="visuallyhidden">
                    {'Cercador global de l\'AMB'}
                  </label>
                  <input
                    name="query"
                    id="queryText"
                    type="search"
                    placeholder="Cerca"
                  />

                  <input
                    type="hidden"
                    name="p_p_id"
                    value="GCSRESTPortlet_WAR_RESTportlet_INSTANCE_f5zOH5pHoY1D"
                  />
                  <input type="hidden" name="p_p_lifecycle" value="1" />
                  <input type="hidden" name="p_p_state" value="normal" />
                  <input type="hidden" name="p_p_mode" value="view" />
                  <input type="hidden" name="p_p_col_id" value="column-2" />
                  <input type="hidden" name="p_p_col_count" value="2" />
                  <input
                    type="hidden"
                    name="defaultSearchConfiguration"
                    value="true"
                  />
                  <label htmlFor="amb_global_search" className="visuallyhidden">
                    Cerca
                  </label>
                  <input type="submit" value="Cerca" id="amb_global_search" />
                </fieldset>
              </form>
            </div>
          </section>
        </div>
        <div className="header-menu menu-tools" id="tool-share">
          <section className="row compartir" data-menu="tool-share">
            <div className="module-grid">
              <a
                href="#tool-share"
                aria-labelledby="tool-share"
                className="closemenu"
              >
                <span className="icon close">Tancar menu</span>
              </a>
              <div className="list view-grid columned columned-4">
                <div className="block">
                  <ul className="list block">
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="//www.facebook.com/share.php?u=http://www.amb.cat/guia-estils"
                        className="icon xarxes fb"
                        data-ga="click"
                        data-ga-action="Xarxa social"
                        title="Compartir a facebook"
                      >
                        Facebook
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="block">
                  <ul className="list block">
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href=""
                        className="icon xarxes tw"
                        data-ga="click"
                        data-ga-action="Xarxa social"
                        title="Compartir a twitter"
                      >
                        Twitter
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="block">
                  <ul className="list block">
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/cws/share?url=http://www.amb.cat/guia-estils"
                        className="icon xarxes ld"
                        data-ga="click"
                        data-ga-action="Xarxa social"
                        title="Compartir a LinkedIn"
                      >
                        LinkedIn
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="block hide-ios">
                  <ul className="list block">
                    <li className="">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://api.whatsapp.com/send?text=http://www.amb.cat/guia-estils"
                        data-action="share/whatsapp/share"
                        className="icon xarxes wa"
                        data-ga="click"
                        data-ga-action="Xarxa social"
                        title="Compartir a WhatsApp"
                      >
                        WhatsApp
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="app__content">
          <Switch>
            { pages }
          </Switch>
          <NotificationContainer />
          <FavoriteModal />
          <RemoveFavoriteModal />
        </section>
        <footer>
          <nav className="coorp amb">
            <ul className="list inline onleft">
              <li>
                <h6 className="logo">
                  <a
                    title="Àrea Metropolitana de Barcelona"
                    target="blank_"
                    href="http://www.amb.cat/s/home.html"
                  >
                    Àrea Metropolitana de Barcelona
                  </a>
                </h6>
              </li>
              <li>
                <span className="logo feder"></span>
              </li>
            </ul>
            <ul className="list inline onright">
              <li>
                <a
                  title="Com arribar a la seu d'AMB"
                  href="http://www.amb.cat/s/web/amb/com-arribar.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  {'Com arribar a la seu d\'AMB'}
                </a>
              </li>
              <li>
                <a
                  title="Contacte"
                  target="_blank"
                  rel="noreferrer"
                  href="http://www.amb.cat/web/amb/seu-electronica/suggeriments-i-queixes"
                >
                  Contacte
                </a>
              </li>
              <li>
                <a
                  title="Accessibilitat"
                  target="_blank"
                  rel="noreferrer"
                  href="http://www.amb.cat/s/web/amb/accessibilitat.html"
                >
                  Accessibilitat
                </a>
              </li>
              <li>
                <a
                  title="Avís legal"
                  target="_blank"
                  rel="noreferrer"
                  href="http://www.amb.cat/s/web/amb/avis-legal.html"
                >
                  Avís legal
                </a>
              </li>
              <li className="block">
                <a
                  href="http://www.amb.cat/s/web/amb/politica-de-privacitat.html"
                  target="_blank"
                  rel="noreferrer"
                  title="Política de privacitat"
                >
                  Política de privacitat
                </a>
              </li>
              <li>
                <a
                  title="Premis i mencions"
                  target="_blank"
                  rel="noreferrer"
                  href="http://www.amb.cat/web/amb/premis-i-mencions"
                >
                  Premis i mencions
                </a>
              </li>
              <li>
                <a
                  title="© Àrea Metropolitana de Barcelona"
                  href="http://www.amb.cat/s/home.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  © Àrea Metropolitana de Barcelona
                </a>
              </li>
            </ul>
          </nav>
        </footer>
      </div>
    </>
  )
}

export default App
