import moment from 'moment'
import { all, call, getContext, put, takeLatest } from 'redux-saga/effects'
import { IReservation } from '../../models'
import { urls } from '../../routing'
import {
  FeedbackService,
  LiteralsService,
  ReservationService
} from '../../services'
import {
  deleteReservationSuccessfulAction,
  fetchMoreReservationsSuccessfulAction,
  fetchPastReservationsSuccessfulAction,
  fetchReservationDetailsSuccessfulAction,
  fetchReservationsSuccessfulAction,
  setIsDeletingReservationAction,
  setIsFetchingPastReservationsAction,
  setIsFetchingReservationDetailsAction,
  setIsFetchingReservationsAction,
  setIsMoreFetchingReservationsAction,
  setNotificationAction
} from '../actions'
import { ReservationsTypeKeys } from '../types'

// Worker Saga
function* fetchReservationsAsync() {
  let orderedReservations = []
  let reservations = []
  let cancelledReservations = []

  yield put(setIsFetchingReservationsAction())
  try {
    reservations = yield call(ReservationService.getReservations)
  } catch (error) {
    yield put(
      setNotificationAction({
        isError: true,
        label: LiteralsService.get('serverError', true)
      })
    )
  }

  try {
    // Get cancelled and add flag to show flag
    cancelledReservations = yield call(
      ReservationService.getCancelledReservations
    )
  } catch (error) {
    yield put(
      setNotificationAction({
        isError: true,
        label: LiteralsService.get('serverError', true)
      })
    )
  }

  const cancelledReservationsWithFlag = cancelledReservations.map(
    (r: IReservation) => ({
      ...r,
      cancelled: true
    })
  )

  // Order reservations by date after concat both
  const bothReservationsArray = reservations.concat(
    cancelledReservationsWithFlag
  )

  orderedReservations = bothReservationsArray.sort(
    (a: IReservation, b: IReservation) =>
      moment(a.beginningStop.dateTime).diff(moment(b.beginningStop.dateTime))
  )

  yield put(fetchReservationsSuccessfulAction(orderedReservations))
}

function* fetchMoreReservationsAsync(action: any) {
  let reservations = []
  yield put(setIsMoreFetchingReservationsAction())
  try {
    reservations = yield call(ReservationService.getMoreReservations, action.payload)
  } catch (error) {
    yield put(
      setNotificationAction({
        isError: true,
        label: LiteralsService.get('serverError', true)
      })
    )
  }
  yield put(fetchMoreReservationsSuccessfulAction(reservations))
}

function* fetchPastReservationsAsync() {
  let pastReservations = []
  try {
    yield put(setIsFetchingPastReservationsAction())
    pastReservations = yield call(ReservationService.getPastReservations)
  } catch (error) {
    yield put(
      setNotificationAction({
        isError: true,
        label: LiteralsService.get('serverError', true)
      })
    )
  }
  yield put(fetchPastReservationsSuccessfulAction(pastReservations))
}

function* fetchReservationDetailsAsync(action: any) {
  let reservation
  let feedback
  try {
    yield put(setIsFetchingReservationDetailsAction())
    reservation = yield call(
      !action.payload.cancelled
        ? ReservationService.getReservation
        : ReservationService.getCancelledReservation,
      action.payload.id
    )

    try {
      feedback = yield call(FeedbackService.getReservationFeedback, action.payload.id)
    } catch (error) {
      if (!(error && error.message && error.message.includes(404))) {
        yield put(
          setNotificationAction({
            isError: true,
            label: LiteralsService.get('serverError', true)
          })
        )
      }
    }
    if (action.payload.cancelled) reservation = { ...reservation, cancelled: true }
  } catch (error) {
    yield put(
      setNotificationAction({
        isError: true,
        label: LiteralsService.get('serverError', true)
      })
    )
  }
  yield put(fetchReservationDetailsSuccessfulAction({...reservation, feedback }))
}

function* deleteReservationAsync(action: any) {
  const routerHistory = yield getContext('routerHistory')

  try {
    yield put(setIsDeletingReservationAction())
    yield call(ReservationService.deleteReservation, action.payload)
    yield call(routerHistory.push, urls.reservations)
    yield put(
      setNotificationAction({
        isError: false,
        label: LiteralsService.get('reservationDeleted', true)
      })
    )
  } catch (error) {
    yield put(
      setNotificationAction({
        isError: true,
        label: LiteralsService.get(
          error && error.status && error.status.includes(412)
            ? 'preconditionFailed'
            : 'reservationNotDeleted',
          true
        )
      })
    )
  }
  yield put(deleteReservationSuccessfulAction())
}

// Watcher Saga:
function* watchFetchReservationsAsync() {
  yield takeLatest(ReservationsTypeKeys.FETCH_RESERVATIONS, fetchReservationsAsync)
}

function* watchFetchMoreReservationsAsync() {
  yield takeLatest(ReservationsTypeKeys.FETCH_MORE_RESERVATIONS, fetchMoreReservationsAsync)
}

function* watchFetchPastReservationsAsync() {
  yield takeLatest(ReservationsTypeKeys.FETCH_PAST_RESERVATIONS, fetchPastReservationsAsync)
}

function* watchFetchReservationDetailsAsync() {
  yield takeLatest(ReservationsTypeKeys.FETCH_RESERVATION_DETAILS, fetchReservationDetailsAsync)
}

function* watchDeleteReservationAsync() {
  yield takeLatest(ReservationsTypeKeys.DELETE_RESERVATION, deleteReservationAsync)
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    watchFetchReservationsAsync(),
    watchFetchMoreReservationsAsync(),
    watchFetchPastReservationsAsync(),
    watchFetchReservationDetailsAsync(),
    watchDeleteReservationAsync()
  ])
}
