import { ICoordinate } from '../models'

export async function getPosition(): Promise<Position> {
  const geo = navigator.geolocation
  return new Promise((res, rej) => {
    geo.getCurrentPosition(res, rej, {
      enableHighAccuracy: true
    })
  })
}

export const getRegion = (path: any) => {
  if (!path) return
  const features = path.features.filter((c: any) => c !== null)
  const points = features
    .filter(
      (f: any) =>
        f.geometry &&
        (f.geometry.type === 'Point' || f.geometry.type === 'MultiPoint')
    )
    .map((feature: any) =>
      makeCoordinates(feature).map((coords: any) =>
        makeOverlay(coords, feature)
      )
    )
    .reduce(flatten, [])
    .map((overlay: any) => ({ ...overlay, type: 'point' }))

  const lines = features
    .filter(
      (f: any) =>
        f.geometry &&
        (f.geometry.type === 'LineString' ||
          f.geometry.type === 'MultiLineString')
    )
    .map((feature: any) =>
      makeCoordinates(feature).map((coords: any) =>
        makeOverlay(coords, feature)
      )
    )
    .reduce(flatten, [])
    .map((overlay: any) => ({ ...overlay, type: 'polyline' }))

  const multipolygons = features
    .filter((f: any) => f.geometry && f.geometry.type === 'MultiPolygon')
    .map((feature: any) =>
      makeCoordinates(feature).map((coords: any) =>
        makeOverlay(coords, feature)
      )
    )
    .reduce(flatten, [])

  const polygons = features
    .filter((f: any) => f.geometry && f.geometry.type === 'Polygon')
    .map((feature: any) => makeOverlay(makeCoordinates(feature), feature))
    .reduce(flatten, [])
    .concat(multipolygons)
    .map((overlay: any) => ({ ...overlay, type: 'polygon' }))

  const result = points.concat(lines).concat(polygons)

  const coordinates = result.map((f: any) => f.coordinates).reduce(flatten, [])

  return getRegionForCoordinates(coordinates)
}

export const getRegionForCoordinates = (
  points: any,
  distance = 0.005
) => {
  // points should be an array of { latitude: X, longitude: Y }
  let minX: number
  let maxX: number
  let minY: number
  let maxY: number

  // init first point
  ((point: any) => {
    minX = point.latitude
    maxX = point.latitude
    minY = point.longitude
    maxY = point.longitude
  })(points[0])

  // calculate rect
  points.forEach((point: any) => {
    minX = Math.min(minX, point.latitude)
    maxX = Math.max(maxX, point.latitude)
    minY = Math.min(minY, point.longitude)
    maxY = Math.max(maxY, point.longitude)
  })

  const midX = (minX + maxX) / 2
  const midY = (minY + maxY) / 2
  const deltaX = maxX - minX
  const deltaY = maxY - minY

  return {
    latitude: midX,
    longitude: midY,
    latitudeDelta: deltaX + distance,
    longitudeDelta: deltaY + distance
  }
}

export const flatten = (prev: any, curr: any) => prev.concat(curr)

const makeOverlay = (coordinates: any, feature: any) => {
  const overlay: any = {
    feature,
    id: feature.id ? feature.id : Math.random()
  }
  if (
    feature.geometry.type === 'Polygon' ||
    feature.geometry.type === 'MultiPolygon'
  ) {
    overlay.coordinates = coordinates[0]
    if (coordinates.length > 1) {
      overlay.holes = coordinates.slice(1)
    }
  } else {
    overlay.coordinates = coordinates
  }
  return overlay
}

const makePoint = (c: any): ICoordinate => ({
  latitude: c[1],
  longitude: c[0]
})

export const makePointOnMap = (
  c: any,
  correction = 0
): ICoordinate => ({
  latitude: c[1] - correction,
  longitude: c[0]
})

const makeLine = (l: any) => l.map(makePoint)

export const makeCoordinates = (feature: any) => {
  const g = feature.geometry
  let coordinates

  switch (g.type) {
    case 'Point':
      coordinates = [makePoint(g.coordinates)]
      break
    case 'MultiPoint':
      coordinates = g.coordinates.map(makePoint)
      break
    case 'LineString':
      coordinates = [makeLine(g.coordinates)]
      break
    case 'MultiLineString':
    case 'Polygon':
      coordinates = g.coordinates.map(makeLine)
      break
    // case 'Polygon':
    //   coordinates = [makePoint(g.coordinates)]
    //   break
    case 'MultiPolygon':
      coordinates = g.coordinates.map(
        (p: { map: (arg0: (l: any) => any) => void }) => p.map(makeLine)
      )
      break
    default:
      coordinates = []
      break
  }

  return coordinates
}

export const unifyPoints = ({
  path,
  inStop,
  outStop
}: {
  path?: any
  inStop: any
  outStop: any[] | any
}) => {
  const pathFeatures = (path && path.features) || []

  const features = pathFeatures
    .concat(stopsGenerator(inStop))
    .concat(stopsGenerator(outStop))

  return {
    features
  }
}

export const stopsGenerator = (outStops: any[] | any) => {
  if (!Array.isArray(outStops)) outStops = [outStops]

  return outStops.map((o: any) =>
    'geometry' in o
      ? o
      : 'location' in o
      ? checkLocationProperty(o)
      : checkLocationProperty(o.exitStop)
  )
}

const checkLocationProperty = (stop: any) => {
  return 'location' in stop ? stop.location : stop
}

export const formatPointToLocation = (point: ICoordinate) => {
  return {
      geometry: {
      coordinates: [ point.latitude, point.longitude ]
    }
  }
}
