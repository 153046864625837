import { IChangePasswordState } from '../states'
import { ChangePasswordActionTypes, ChangePasswordTypeKeys } from '../types'

export const initialChangePasswordState: IChangePasswordState = {
  password: '',
  newPassword: '',
  repeatNewPassword: '',
  isChangingPassword: false
}

const changePassword = (
  state: IChangePasswordState = initialChangePasswordState,
  action: ChangePasswordActionTypes
): IChangePasswordState => {
  switch (action.type) {
    case ChangePasswordTypeKeys.SET_CHANGE_PASSWORD:
      return { ...state, password: action.payload }
    case ChangePasswordTypeKeys.SET_CHANGE_NEW_PASSWORD:
      return { ...state, newPassword: action.payload }
    case ChangePasswordTypeKeys.SET_CHANGE_REPEAT_NEW_PASSWORD:
      return { ...state, repeatNewPassword: action.payload }
    case ChangePasswordTypeKeys.CHANGING_PASSWORD:
      return { ...state, isChangingPassword: true }
    case ChangePasswordTypeKeys.CHANGED_PASSWORD_SUCCESSFULL:
      return { ...initialChangePasswordState }
    case ChangePasswordTypeKeys.CHANGED_PASSWORD_FAILED:
      return { ...state, isChangingPassword: false }
    default:
      return state
  }
}

export default changePassword
