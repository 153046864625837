import { IRecoverPasswordState } from '../states'
import { RecoverPasswordActionTypes, RecoverPasswordTypeKeys } from '../types'

export const initialRecoverPasswordState: IRecoverPasswordState = {
  email: '',
  isLoading: false,
  error: '',
  verificationCode: '',
  newPassword: '',
  repeatNewPassword: ''
}

const recoverPassword = (
  state: IRecoverPasswordState = initialRecoverPasswordState,
  action: RecoverPasswordActionTypes
): IRecoverPasswordState => {
  switch (action.type) {
    case RecoverPasswordTypeKeys.RESET_RECOVER_PASSWORD_FIELDS:
      return { ...initialRecoverPasswordState }
    case RecoverPasswordTypeKeys.SET_RECOVER_PASSWORD_EMAIL:
      return { ...state, email: action.payload }
    case RecoverPasswordTypeKeys.REQUESTING_RECOVER_CODE:
      return { ...state, isLoading: true }
    case RecoverPasswordTypeKeys.REQUEST_RECOVER_CODE_SUCCESSFUL:
      return { ...state, isLoading: false }
    case RecoverPasswordTypeKeys.REQUEST_RECOVER_CODE_FAILED:
      return { ...state, isLoading: false, error: action.payload }
    case RecoverPasswordTypeKeys.SET_RECOVER_PASSWORD_VERIFICATION_CODE:
      return { ...state, verificationCode: action.payload }
    case RecoverPasswordTypeKeys.SET_RECOVER_PASSWORD_NEW_PASSWORD:
      return { ...state, newPassword: action.payload }
    case RecoverPasswordTypeKeys.SET_RECOVER_PASSWORD_REPEAT_NEW_PASSWORD:
      return { ...state, repeatNewPassword: action.payload }
    case RecoverPasswordTypeKeys.RECOVERING_PASSWORD:
      return { ...state, isLoading: true }
    case RecoverPasswordTypeKeys.RECOVER_PASSWORD_SUCCESSFUL:
      return { ...state, isLoading: false }
    case RecoverPasswordTypeKeys.RECOVER_PASSWORD_FAILED:
      return { ...state, isLoading: false, error: action.payload }
    default:
      return state
  }
}

export default recoverPassword
