import {
  AreaSelection,
  ChangeLanguage,
  ChangePassword,
  ConfigurationDisplay,
  Information,
  NotificationSettings,
  Unsubscribe,
  UserInfo
} from '../pages'

export const configurationUrls = {
  configurationDisplay: '/configuration',
  userInfo: '/user-info',
  areaSelection: '/area-selection',
  information: '/information',
  changeLanguage: '/change-language',
  notificationSettings: '/notification-settings',
  changePassword: '/change-password',
  unsubscribe: '/unsubscribe'
}

export const configurationRoutes = [
  { content: ConfigurationDisplay, url: configurationUrls.configurationDisplay, isAuthenticated: true },
  { content: UserInfo, url: configurationUrls.userInfo, isAuthenticated: true },
  { content: AreaSelection, url: configurationUrls.areaSelection, isAuthenticated: true },
  { content: Information, url: configurationUrls.information, isAuthenticated: true },
  { content: ChangeLanguage, url:configurationUrls.changeLanguage, isAuthenticated: true },
  { content: NotificationSettings, url: configurationUrls.notificationSettings, isAuthenticated: true },
  { content: ChangePassword, url:configurationUrls.changePassword, isAuthenticated: true },
  { content: Unsubscribe, url: configurationUrls.unsubscribe, isAuthenticated: true }
]
