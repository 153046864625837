import {
  ChangePasswordTypeKeys,
  IChangePasswordAction,
  IChangePasswordFailedAction,
  IChangePasswordSuccessfullAction,
  ISetChangeNewPasswordAction,
  ISetChangePasswordAction,
  ISetChangeRepeatNewPasswordAction,
  ISetIsChangingPasswordAction
} from '../types'

export function setChangePasswordAction(password: string): ISetChangePasswordAction {
  return { type: ChangePasswordTypeKeys.SET_CHANGE_PASSWORD, payload: password }
}

export function setChangeNewPasswordAction(newPassword: string): ISetChangeNewPasswordAction {
  return { type: ChangePasswordTypeKeys.SET_CHANGE_NEW_PASSWORD, payload: newPassword }
}

export function setChangeRepeatNewPasswordAction(repeatNewPassword: string): ISetChangeRepeatNewPasswordAction {
  return { type: ChangePasswordTypeKeys.SET_CHANGE_REPEAT_NEW_PASSWORD, payload: repeatNewPassword }
}

export function changePasswordAction(): IChangePasswordAction {
  return { type: ChangePasswordTypeKeys.CHANGE_PASSWORD }
}

export function setIsChangingPasswordAction(): ISetIsChangingPasswordAction {
  return { type: ChangePasswordTypeKeys.CHANGING_PASSWORD }
}

export function changedPasswordSuccessfullAction(): IChangePasswordSuccessfullAction {
  return { type: ChangePasswordTypeKeys.CHANGED_PASSWORD_SUCCESSFULL }
}

export function changedPasswordFailedAction(): IChangePasswordFailedAction {
  return { type: ChangePasswordTypeKeys.CHANGED_PASSWORD_FAILED }
}
