// import Geolocation from '@react-native-community/geolocation'
import moment from 'moment'
import { IReservation, IStop } from '../models'
import { LiteralsService } from '../services'

const lowercaseRegExp = /[a-z]/
const uppercaseRegExp = /[A-Z]/
const digitsRegExp = /[0-9]/
const specialCharactersRegExp = /[\^$*.[]{}()?-"!@#%&\/\\,><':;|_~`]/
const otherCharsRegExp = /[=+]/
const lengthRegExp = /^.{8,}$/
const validDniNifLetters = 'TRWAGMYFPDXBNJZSQVHLCKET'
const nifRegExp = new RegExp(/^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/)
const dniRegExp = new RegExp(/^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/)

export function capitalize(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export function dateAsCalendar(date: Date | string) {
  const dateFormated = moment(date)
  const today = moment()
  const yesterday = moment().subtract(1, 'day')
  const tomorrow = moment().add(1, 'day')
  let stringFormat = '[-] HH:mm'

  if (moment(date).isSame(today, 'day')) {
    stringFormat = `[${LiteralsService.get('today', true)}]`
  } else if (moment(date).isSame(yesterday, 'day')) {
    stringFormat = `[${LiteralsService.get('yesterday', true)}]`
  } else if (moment(date).isSame(tomorrow, 'day')) {
    stringFormat = `[${LiteralsService.get('tomorrow', true)}]`
  } else {
    stringFormat = 'DD/MM/YYYY'
  }

  return dateFormated.format(stringFormat + ' [-] HH:mm')
}

export function penaltyAsCalendar(date: Date | string): string {
  const dateFormated = moment(date)
  const today = moment()
  const tomorrow = moment().add(1, 'day')
  let response = ''

  if (moment(date).isSame(today, 'day')) {
    response = LiteralsService.get('today', true)
  } else if (moment(date).isSame(tomorrow, 'day')) {
    response = LiteralsService.get('tomorrow', true)
  } else {
    response = `${LiteralsService.get('expiresIn', true)} ${dateFormated.diff(
      today,
      'days'
    )} ${LiteralsService.get('days', true)}`
  }

  return response
}

export function hasTelephonePrefix(telephone: string): boolean {
  const regExp = new RegExp('^\\+[0-9]{2,2}')
  return regExp.test(telephone)
}

export function sanitizeTelephone(telephone: string): string {
  return telephone && !hasTelephonePrefix(telephone)
    ? `+34${telephone}`
    : telephone || ''
}

export function isEmailValid(email: string): boolean {
  const regExp = new RegExp('[a-zA-Z0-9]+@[a-zA-Z]+[.][a-zA-Z]{2,3}')
  return regExp.test(email)
}

export function isCodiceValid(codice: string): boolean {
  // eslint-disable-next-line max-len
  const regExp = new RegExp('^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})|([0-9]{11})$')
  return regExp.test(codice)
}

export function isPasswordValid(password: string): boolean {
  let isValid = true

  if (!lowercaseRegExp.test(password)) isValid = false
  // if (!uppercaseRegExp.test(password)) isValid = false
  if (!digitsRegExp.test(password)) isValid = false
  // if (!specialCharactersRegExp.test(password)) isValid = false
  if (!lengthRegExp.test(password)) isValid = false
  if (!Array.from(password).every(isCharValid)) isValid = false

  return isValid
}

function isCharValid(character: string): boolean {
  return (
    lowercaseRegExp.test(character) ||
    uppercaseRegExp.test(character) ||
    digitsRegExp.test(character) ||
    specialCharactersRegExp.test(character) ||
    otherCharsRegExp.test(character)
  )
}

export function isTextValid(text: string): boolean {
  const regExp = new RegExp(
    '^[a-zA-ZáàäéèëíìïóòöúùüÁÀÄÉÈËIÍÌÏÓÒÖÚÙÜñçÑÇ ]{3,}$'
  )
  return regExp.test(text)
}

export function isTelephoneValid(telephone: string): boolean {
  const regExp = new RegExp('^(\\+?[0-9]{1,3})?[0-9]{9}$')
  return regExp.test(telephone)
}

export function isDniValid(dni: string): boolean {
  if (!nifRegExp.test(dni) && !dniRegExp.test(dni)) return false

  const nie = dni
    .replace(/^[X]/, '0')
    .replace(/^[Y]/, '1')
    .replace(/^[Z]/, '2')
  const letter = dni.substr(-1)
  const letterIndex = parseInt(nie.substr(0, 8), 10) % 23

  return validDniNifLetters.charAt(letterIndex) === letter
}

export function isVerificationCodeValid(code: string): boolean {
  const regExp = new RegExp('^[0-9]{6}$')
  return regExp.test(code)
}

export function compareStops(first: IStop, second: IStop): boolean {
  return first.id === second.id && first.name === second.name
}

export function repeatStringForService(
  stringFormat: string,
  destinationStopId: number,
  totalRepeat: number
): string {
  let resultString = stringFormat
  for (let index = 0; index < totalRepeat; index++) {
    resultString += `${destinationStopId},`
  }

  return resultString.slice(0, resultString.length - 1)
}

export function addDelayToReservation(
  reservation: IReservation,
  delay: number
): IReservation {
  return {
    ...reservation,
    beginningStop: {
      ...reservation.beginningStop,
      dateTime: moment(reservation?.beginningStop.dateTime ?? false)
        .add(delay, 'minutes')
        .toISOString()
    },
    seats: reservation.seats.map(s => ({
      ...s,
      exitStop: {
        ...s.exitStop,
        dateTime: moment(s.exitStop.dateTime)
          .add(delay, 'minutes')
          .toISOString()
      }
    }))
  }
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
