import {
  ConfirmStepTypeKeys,
  IConfirmNewReservationAction,
  IConfirmNewReservationSuccessfulAction,
  ISetIsConfirmingNewReservationAction
} from '../../types'

export function confirmNewReservationAction(): IConfirmNewReservationAction {
  return { type: ConfirmStepTypeKeys.CONFIRM_NEW_RESERVATION }
}

export function setIsConfirmingNewReservationAction(): ISetIsConfirmingNewReservationAction {
  return { type: ConfirmStepTypeKeys.CONFIRMING_NEW_RESERVATION }
}

export function confirmNewReservationSuccessfulAction(): IConfirmNewReservationSuccessfulAction {
  return { type: ConfirmStepTypeKeys.CONFIRM_NEW_RESERVATION_SUCCESSFUL }
}
