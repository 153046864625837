import styled from 'styled-components'

export default styled.div`
  font-family: ${ ({ theme }) => theme.fontFamily };
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
  box-sizing: border-box;
  transition: all ease-in 0.2s;

  label {
    position: absolute;
    left: scale(20);
    background-color: transparent;
    padding: 0;
    font-size: 20px;
    color: ${ ({ theme }) => theme.colors.grey };
    top: 8px;
    z-index: 0;
    transition: ease-in-out all 0.2s;
  }

  input {
    font-family: ${ ({ theme }) => theme.fontFamily };
    padding: 9px 0;
    width: 100%;
    font-size: 18px;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid ${ ({ theme }) => theme.colors.grey };
    outline: none;
    transition: all ease-in-out 0.1s;
  }

  &.filled {
    label {
      color: ${ ({ theme }) => theme.colors.black };
      top: -12px;
      z-index: 1;
      font-size: 14px;
    }
  }

  &.invalid {
    label {
      color: ${ ({ theme }) => theme.colors.red };
    }
    input {
      color: ${ ({ theme }) => theme.colors.red };
      border-bottom-color: ${ ({ theme }) => theme.colors.red };
    }
  }
`
