import styled from 'styled-components'

export default styled.div`
  .marker {
    font-family: ${ ({ theme }) => theme.fontFamily };
    font-size: 18px;
    letter-spacing: 0;
    line-height: 20px;
    color: ${ ({ theme }) => theme.colors.greyDark };
    padding: 0 12px 0 8px;
    user-select: none;
  }

  .gm-style .gm-style-iw-c {
    border-radius: 22px;
    cursor: pointer;

    > button {
      top: 8px !important;
    }
  }
`
