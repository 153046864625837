import React from 'react'
import { images } from '../../images'
import StyledSection from './styles'

interface IProps {
  amount: number
  label: string
  onChange: (amount: number) => void
}

export const QuantityInput: React.FC<IProps> = ({ amount, label, onChange }) => {
  const isLessDisabled = amount === 0

  return (
    <StyledSection>
      <h4>{ label }</h4>
      <div>
        <button onClick={ () => onChange(amount - 1) } disabled={ isLessDisabled }>
          <img src={ images['less'] } alt="Less"/>
        </button>
        <span>{ amount }</span>
        <button onClick={ () => onChange(amount + 1) }>
          <img src={ images['more'] } alt="More"/>
        </button>
      </div>
    </StyledSection>
  )
}
