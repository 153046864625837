import styled from 'styled-components'

export default styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 7px 0;
  padding: 0 50px;
  cursor: pointer;

  > img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .line-info {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    p {
      margin: 0 !important;
    }
  }
`
