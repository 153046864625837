import {
  NewReservationBusStopsStep,
  NewReservationConfirmationStep,
  NewReservationDateStep,
  NewReservationRouteOptionsStep
} from '../pages'

export const newReservationUrls = {
  newReservationBusStopsStep: '/new-reservation/bus-stops',
  newReservationDateStep: '/new-reservation/date',
  newReservationRouteOptionsStep: '/new-reservation/route-options',
  newReservationConfirmationStep: '/new-reservation/confirmation'
}

export const newReservationRoutes = [
  { content: NewReservationBusStopsStep, url: newReservationUrls.newReservationBusStopsStep, isAuthenticated: true },
  { content: NewReservationDateStep, url: newReservationUrls.newReservationDateStep, isAuthenticated: true },
  {
    content: NewReservationRouteOptionsStep,
    url: newReservationUrls.newReservationRouteOptionsStep,
    isAuthenticated: true
  },
  {
    content: NewReservationConfirmationStep,
    url: newReservationUrls.newReservationConfirmationStep,
    isAuthenticated: true
  }
]
