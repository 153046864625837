
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import loggerMiddleware from 'redux-logger'
import rootReducer from './reducers'
import rootSaga from './sagas'

export default (initialState: any, context = {}) => {
  const sagaMiddleware = createSagaMiddleware({ context })

  const enhancers = process.env.NODE_ENV === 'production'
  ? [ applyMiddleware(sagaMiddleware) ]
  : [ applyMiddleware(sagaMiddleware, loggerMiddleware) ]

  const store = createStore(rootReducer(), initialState, composeWithDevTools(...enhancers))
  sagaMiddleware.run(rootSaga)
  return store
}
