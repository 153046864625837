import styled from 'styled-components'

export default styled.section`
  background-color: ${ ({ theme }) => theme.colors.primary };
  min-height: 80px;
  flex: 0;
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 60px;
  box-sizing: border-box;

  @media all and (max-width: 400px) {
    padding: 0 10px;
  }

  @media all and (max-width: 700px) {
    .logo {
      display: none !important;
    }
  }
  
  .logo {
    flex: 1;
    img {
      height: 24px;
      width: auto;
      position: relative;
      top: 2px;
      padding-right: 10px;
    }
  }

  > .navigation {
    flex-basis: 800px;
    height: 100%;
    min-height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    a {
      padding: 0 20px;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: bold;
      line-height: initial !important;
      color: ${ ({ theme }) => theme.colors.white };
      text-decoration: none;
      border: 3px solid transparent;
      box-sizing: border-box;
      height: 100%;
      min-height: 80px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      transition: ease all 0.1s;

      &.active {
        border-bottom-color: ${ ({ theme }) => theme.colors.white };
        background-color: ${ ({ theme }) => theme.colors.white }4D;
      }

      &:hover {
        background-color: ${ ({ theme }) => theme.colors.white }33;
      }
    }
  }

  @media all and (max-width: 500px) {
    .account {
      p{
        span{
          display: none !important;
        }
      }
    }
  }

  .account {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      p {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: ${ ({ theme }) => theme.colors.white };
        font-family: ${ ({ theme }) => theme.fontFamily };
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
        margin: 0 !important;

        img {
          width: 44px;
          height: auto;
          margin-right: 12px;
        }
      }
    }
  }
`
