import styled from 'styled-components'

export default styled.section`
  .map-container {
    position: relative;
    width: 100%;
    height: 200px;
  }

  .details {
    flex: 1;
    padding: 20px;
    
    .message {
      margin-top: 5px;
      font-style: italic;
      color: #88001d;
    }

    .detail-row {
      border: 1px solid ${ ({ theme }) => theme.colors.greyLight };
      padding: 20px 38px;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      &:first-of-type {
        margin-top: 0;
      }

      span {
        margin-right: 10px;
      }
    }
  }

  .cancelled-banner {
    height: 85px;
    background-color: ${ ({ theme }) => theme.colors.red }95;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 8px 20px;
    
    p{
      margin-bottom: 0px;
    }
  }

  button.favorite {
    border: none;
    background: transparent;
    cursor: pointer;
    margin-left: auto;
  }
`
